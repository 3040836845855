import { Theme } from 'types';

import analytics from './analytics';
import button from './button';
import checkbox from './checkbox';
import colors from './colors';
import dimensions from './dimensions';
import effects from './effects';
import fonts from './fonts';
import input from './input';
import misc from './misc';
import switchProps from './switch';

const defaultTheme: Theme = {
  dimensions,
  colors,
  fonts,
  misc,
  button,
  input,
  effects,
  switch: switchProps,
  analytics,
  checkbox,
};

export default defaultTheme;
