import {
  OPEN_REG,
  REG_CLOSE_REF,
  REG_OPEN_REF,
  REG_OWNER_INFO_RES,
  REG_TOGGLE_ACCEPT_NO_PARTNER,
  REG_TOGGLE_ACCEPT_PRIVACY,
  REG_TOGGLE_ACCEPT_REF,
  REG_UPDATE_SEARCH_STR,
} from 'redux/types';

const initialState = {
  provider: null,
  socId: null,
  avatar: null,
  refOpen: false,
  ref: null,
  refSearch: null,
  ownerInfo: null,
  ownerInfoLoading: false,
  acceptRefTerms: false,
  acceptPrivacyTerms: false,
  acceptNoPartner: true,
};

export default function reg(state = initialState, action) {
  switch (action.type) {
    case OPEN_REG:
      return { ...state, ...action.payload };

    case REG_OPEN_REF:
      return { ...state, refOpen: true, acceptNoPartner: false };

    case REG_CLOSE_REF:
      return {
        ...state,
        refOpen: false,
        ref: null,
        refSearch: null,
        acceptRefTerms: false,
        ownerInfo: null,
        ownerInfoLoading: false,
        acceptNoPartner: true,
      };

    case REG_UPDATE_SEARCH_STR:
      return { ...state, refSearch: action.str };

    case REG_OWNER_INFO_RES:
      return {
        ...state,
        ownerInfo: action.res,
        ref: action.ref,
        refSearch: action.ref,
        acceptRegTerms: false,
      };

    case REG_TOGGLE_ACCEPT_REF:
      return {
        ...state,
        acceptRefTerms: !state.acceptRefTerms,
      };

    case REG_TOGGLE_ACCEPT_PRIVACY:
      return {
        ...state,
        acceptPrivacyTerms: !state.acceptPrivacyTerms,
      };

    case REG_TOGGLE_ACCEPT_NO_PARTNER:
      return {
        ...state,
        acceptNoPartner: !state.acceptNoPartner,
      };

    default:
      return state;
  }
}
