import colors from './colors';

const effects = {
  shadow: `0px 4px 16px #C0CBE0`,
  hover: {
    cursor: 'pointer',
    transition: '0.3s',
    ':hover': { color: colors.primary },
  },
};

export default effects;
