import { connect } from 'react-redux';

import { openPdfModal } from '../../pdf-modal/actions';
import * as regActions from '../actions';
import Registration from '../component';

const mapStateToProps = state => ({
  state: state.reg,
  authLoading: state.loaders.auth,
});

export default connect(mapStateToProps, { ...regActions, openPdfModal })(Registration);
