import colorVars from './colors';

const colors = {
  default: {
    noState: {
      background: colorVars.muted,
      backgroundChecked: colorVars.primary,
      dot: colorVars.primaryLight,
      dotChecked: colorVars.primaryLight,
    },
  },
  warning: {
    noState: {
      background: colorVars.muted,
      backgroundChecked: colorVars.warning,
      dot: colorVars.primaryLight,
      dotChecked: colorVars.warningDark,
    },
  },
  contrast: {
    noState: {
      background: colorVars.primaryLight,
      backgroundChecked: colorVars.primaryLight,
      dot: colorVars.muted,
      dotChecked: colorVars.primary,
    },
  },
};

export default { colors };
