import fonts from './fonts';

const sizes = {
  s: {
    minWidth: 100,
    padding: '4px 15px',
    iconMargin: 5,
    iconSize: 's',
    minHeight: 25,
    borderRadius: 4,
    titleCss: { ...fonts.m, lineHeight: '15px', fontWeight: 600 },
  },
  m: {
    minWidth: 135,
    padding: '6.5px 15px',
    iconMargin: 5,
    iconSize: 's',
    minHeight: 30,
    borderRadius: 4,
    titleCss: { ...fonts.m, lineHeight: '15px', fontWeight: 600 },
  },
  l: {
    minWidth: 160,
    padding: '13.5px 20px 14.5px 20px',
    iconMargin: 10,
    iconSize: 'm',
    minHeight: 35,
    borderRadius: 4,
    titleCss: { ...fonts.l, lineHeight: '15px', fontWeight: 400 },
  },
};

const colors = {
  default: {
    noState: {
      text: 'primaryLight',
      border: 'primary',
      background: 'primary',
      icon: 'primaryLight',
    },
    hover: {
      text: 'primaryLight',
      border: 'secondaryDark',
      background: 'secondaryDark',
      icon: 'primaryLight',
    },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  dark: {
    noState: {
      text: 'primaryLight',
      border: 'primary',
      background: 'primary',
      icon: 'primaryLight',
    },
    hover: {
      text: 'primaryLight',
      border: 'secondaryDark',
      background: 'secondaryDark',
      icon: 'primaryLight',
    },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  light: {
    noState: {
      text: 'secondary',
      border: 'secondary',
      background: 'primaryLight',
      icon: 'secondary',
    },
    hover: { text: 'primary', border: 'primary', background: 'primaryLight', icon: 'primary' },
    disabled: { text: 'muted', border: 'muted', background: 'primaryLight', icon: 'muted' },
  },
  lightContrast: {
    noState: {
      text: 'secondary',
      border: 'primaryLight',
      background: 'primaryLight',
      icon: 'secondary',
    },
    hover: { text: 'primary', border: 'primaryLight', background: 'primaryLight', icon: 'primary' },
    disabled: { text: 'muted', border: 'primaryLight', background: 'primaryLight', icon: 'muted' },
  },
  darkContrast: {
    noState: {
      text: 'primaryLight',
      border: 'primary',
      background: 'primary',
      icon: 'primaryLight',
    },
    hover: {
      text: 'primaryLight',
      border: 'secondaryDark',
      background: 'secondaryDark',
      icon: 'primaryLight',
    },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  danger: {
    noState: {
      text: 'primaryLight',
      border: 'danger',
      background: 'danger',
      icon: 'primaryLight',
    },
    hover: { text: 'primaryLight', border: 'primary', background: 'primary', icon: 'primaryLight' },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  warning: {
    noState: {
      text: 'primaryLight',
      border: 'warning',
      background: 'warning',
      icon: 'primaryLight',
    },
    hover: { text: 'primaryLight', border: 'primary', background: 'primary', icon: 'primaryLight' },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  success: {
    noState: {
      text: 'primaryLight',
      border: 'success',
      background: 'success',
      icon: 'primaryLight',
    },
    hover: { text: 'primaryLight', border: 'primary', background: 'primary', icon: 'primaryLight' },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
};

export default { sizes, colors };
