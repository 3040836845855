import styled from '@emotion/styled';
import { Layout } from '../layout';
import Icon from '../icon';
import { LabelElementProps, FakeCheckboxProps, CheckProps } from './types';

const LabelElement = styled('label')<LabelElementProps>(({ theme, checked }) => ({
  cursor: 'pointer',
  '>div': {
    borderColor: checked ? theme.colors.primary : theme.colors.secondary,
    transition: '.2s',
    i: { color: checked ? theme.colors.primary : theme.colors.secondary, transition: '.2s' },
  },
  ':hover:not([disabled])': {
    '>span': { color: theme.colors.primary, 'span,i': { color: theme.colors.primary } },
    '>div': {
      borderColor: theme.colors.primary,
      i: { color: theme.colors.primary },
    },
  },
  '&[disabled]': {
    pointerEvents: 'none',
    '>span': { color: theme.colors.muted, 'span,i': { color: theme.colors.muted } },
    '>div': {
      borderColor: theme.colors.muted,
      i: { color: theme.colors.muted },
    },
  },
  display: 'flex',
  alignItems: 'center',
}));

const FakeCheckbox = styled(Layout)<FakeCheckboxProps>(({ size }) => ({
  position: 'relative',
  width: size,
  height: size,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: 1,
  alignItems: 'center',
  justifyContent: 'center',
}));

const Check = styled(Icon)<CheckProps>(({ size }) => ({
  position: 'absolute',
  fontSize: size,
}));

export default { LabelElement, FakeCheckbox, Check };
