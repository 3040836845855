import commonColors from '../../common/colors';

const colors = {
  danger: '#DC5858', // с этими
  success: '#63C67A', // все
  warning: '#F9ED32', // понятно
  dangerLight: '#FDF6F6', // с этими
  successLight: '#F7FCF8', // все
  warningLight: '#FEFBF2', // понятно
  dangerDark: '#721C24', // с этими
  successDark: '#155724', // все
  warningDark: '#856404', // понятно

  primary: '#9707E3',
  primaryLighter: 'unset',
  primaryLight: '#ffffff',
  secondaryDark: '#000000',
  secondaryDarker: '#444752',
  secondary: '#707D89',
  secondaryLighter: '#EDEDED',
  secondaryLight: '#F5F6F8',
  muted: '#EDEDED',
  accent: 'unset',

  ...commonColors,
};

export default colors;
