import { REG_OWNER_INFO_REQ, REG_OWNER_INFO_RES, SET_AUTH_LOADER } from '../types';

const initialState = {
  promoLoading: false,
  settingsLoading: false,
  auth: false,
};

export default function loaders(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_LOADER:
      return { ...state, auth: action.payload };

    case REG_OWNER_INFO_REQ:
      return { ...state, auth: true };

    case REG_OWNER_INFO_RES:
      return { ...state, auth: false };

    case 'SETTINGS_REQ':
      return {
        ...state,
        settingsLoading: true,
      };

    case 'SETTINGS_RES':
      return {
        ...state,
        settingsLoading: false,
      };

    default:
      return state;
  }
}
