import 'firebase/auth';
import 'firebase/database';

import firebase from 'firebase/compat/app';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import { getURLParameter, sendMessageExt } from 'redux/actions';
import {
  CLOSE_PHONE_AUTH,
  OPEN_PHONE_AUTH,
  RESET_AUTHUI,
  SET_HANDLERS,
  SET_PHONE_AUTH_ERROR,
  SET_PHONE_AUTH_STAGE,
  SET_PHONE_CODE,
  SET_PHONE_NUMBER,
} from 'redux/types';
import store from 'store';

import { handleNewUser } from '../../registration/actions';

export const onMount =
  ({ handlers }) =>
  dispatch => {
    const payload = { ...handlers };

    if (window.hasOwnProperty('BroadcastChannel')) {
      payload.bc = new BroadcastChannel('ssmm');
      payload.bc.onmessage = handleMessage;
    } else {
      window.addEventListener('message', handleMessage);
    }

    dispatch({ type: SET_HANDLERS, payload });
  };

const handleMessage = event => {
  const { onMessage } = store.getState().authUi.handlers;

  const { origin, data } = event;

  if (origin !== window.origin || !data) return;

  const { status, provider, ref, socId, avatar } = data;

  if (status === 'auth/user-not-found') {
    store.dispatch(handleNewUser({ ref, provider, socId, avatar }));
  } else {
    onMessage(data);
  }
};

export const onUnmount = () => (dispatch, getState) => {
  const { bc } = getState().authUi.handlers;

  if (bc) {
    bc.close();
  } else {
    window.removeEventListener('message', handleMessage);
  }

  dispatch({ type: RESET_AUTHUI });
};
/*
export const loginViaPhone = () => (dispatch, getState) => {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
      size: 'invisible',
      callback: () => dispatch(loginViaPhone()),
    });
  }

  dispatch({ type: SET_PHONE_AUTH_ERROR, payload: undefined });

  const appVerifier = window.recaptchaVerifier;

  const { phoneNumber } = getState().auth;

  if (!isValidPhoneNumber(phoneNumber)) {
    dispatch({ type: SET_PHONE_AUTH_ERROR, payload: 'Проверьте формат номера' });

    return;
  }

  firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then(confirmationResult => {
      window.confirmationResult = confirmationResult;

      dispatch({ type: SET_PHONE_AUTH_STAGE, payload: 'code' });
    })
    .catch(error => {
      dispatch({ type: SET_PHONE_AUTH_ERROR, payload: error.message });

      window.recaptchaVerifier.render().then(widgetId => {
        window.grecaptcha.reset(widgetId);
      });
    });
};
*/
export const submitPhoneCode = () => (dispatch, getState) => {
  const { onCred } = store.getState().authUi.handlers;

  dispatch({ type: SET_PHONE_AUTH_ERROR, payload: undefined });

  const { phoneCode } = getState().auth;

  const cred = firebase.auth.PhoneAuthProvider.credential(
    window.confirmationResult.verificationId,
    phoneCode
  );

  onCred(cred).catch(error => dispatch({ type: SET_PHONE_AUTH_ERROR, payload: error }));
};

export const loginViaVk = source => async () => {
  if (source === 'ext') {
    try {
      await sendMessageExt({ type: 'auth-login-request' });
    } catch (e) {
      console.info(e.message);

      const { message, response } = e;
      const { ref, socId } = response;

      if (message === 'auth/user-not-found') {
        store.dispatch(handleNewUser({ ref, socId, provider: 'vk' }));
      }
    }
  } else {
    // временно. потом нужно будет удалить отправку рефа при ЛОГИНЕ
    let url = `/auth.html?provider=vk`;

    const ref = getURLParameter('refId') || localStorage.getItem('refId');

    if (ref) {
      url += `&ref=${ref}`;
    }

    window.open(url, 'auth-vk-boss', 'height=432,width=656');
  }
};

export const loginViaInst = () => () => {
  // временно. потом нужно будет удалить отправку рефа при ЛОГИНЕ
  let url = `/auth.html?provider=inst`;

  const ref = getURLParameter('ref') || localStorage.getItem('ref') || null;

  if (ref) {
    url += `&ref=${ref}`;
  }

  window.open(url, 'auth-vk-boss', 'height=432,width=656');
};

/*
export const loginViaFb = () => () => {
  var provider = new firebase.auth.FacebookAuthProvider();

  provider.setCustomParameters({
    action: 'login',
  });

  firebase
    .auth()
    .signInWithPopup(provider)
    .then(function(result) {
      console.info('result', result);
      // This gives you a Facebook Access Token.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var user = result.user;
    });
};

export const connectFbAccount = () => () => {
  var provider = new firebase.auth.FacebookAuthProvider();

  firebase
    .auth()
    .currentUser.linkWithPopup(provider)
    .then(function(result) {
      console.info('result', result);
    });
};
*/
export const openPhoneAuth = () => ({ type: OPEN_PHONE_AUTH });

export const closePhoneAuth = () => ({ type: CLOSE_PHONE_AUTH });

export const handlePhoneNumber = phoneNumber => ({ type: SET_PHONE_NUMBER, payload: phoneNumber });

export const handlePhoneCode = phoneCode => ({ type: SET_PHONE_CODE, payload: phoneCode });
