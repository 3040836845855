import React from 'react';
import styled from '@emotion/styled';
import { Column, Row } from '../layout';
import { Text } from '../text';

const ContainerElement = styled(Column)(({ theme }) => ({
  background: theme.colors.primaryLight,
  borderRadius: theme.misc.borderRadius,
  boxShadow: '0px 0px 5px rgb(0,0,0,0.1)',
  '>div': { cursor: 'pointer' },
  '>div:hover': {
    background: theme.colors.primaryLighter,
    'i,span': { color: theme.colors.primary },
  },
}));

const OptionElement = styled(Row)(({ theme }) => ({
  minHeight: theme.input.sizes.m.height,
  padding: `7px ${theme.dimensions.m}px 8px ${theme.dimensions.m}px`,
}));

const Options = React.memo(
  ({ optionsRef, options, tags, input, onOptionClick, optionLimit, popoverMaxHeight }) => {
    const availableOptions = options
      .filter(opt => {
        if (tags.includes(opt)) return false;
        if (!opt.toLowerCase().includes(input.toLowerCase())) return false;
        return true;
      })
      .sort((a, b) => a.localeCompare(b));

    const tagCount = availableOptions.length;
    let content;

    if (tagCount === 0) {
      content = (
        <OptionElement css={{ pointerEvents: 'none' }}>
          <Text color="secondaryDarker">
            Введите тэг и нажмие <Text color="secondaryDark">enter</Text>
          </Text>
        </OptionElement>
      );
    } else if (tagCount > optionLimit) {
      availableOptions.length = optionLimit;

      content = (
        <>
          {availableOptions.map(option => (
            <OptionElement key={option} onClick={() => onOptionClick(option)}>
              <Text>{option}</Text>
            </OptionElement>
          ))}
          <OptionElement css={{ pointerEvents: 'none' }}>
            <Text color="secondary">
              Не отображается тэгов: {tagCount - optionLimit}. Начните вводить текст для поиска.
            </Text>
          </OptionElement>
        </>
      );
    } else {
      content = availableOptions.map(option => (
        <OptionElement key={option} onClick={() => onOptionClick(option)}>
          <Text>{option}</Text>
        </OptionElement>
      ));
    }

    return (
      <ContainerElement ref={optionsRef} scroll css={{ maxHeight: popoverMaxHeight }}>
        {content}
      </ContainerElement>
    );
  }
);

export default Options;
