import React from 'react';
import styled from '@emotion/styled';
import { useTheme, Global } from '@emotion/react';

import { Slider as AntdSlider } from 'antd';
import { Layout } from '../layout';
import { Text } from '../text';
import { commonParser } from '../helpers';

const Wrap = styled(Layout)(({ theme, ...rest }) => ({
  marginBottom: theme.dimensions.xs,
  ...commonParser({ theme, ...rest }),
}));

const SliderElement = styled(AntdSlider, {
  shouldForwardProp: prop => prop !== 'css',
})(({ theme }) => ({
  width: 'calc(100% - 12px)',
  margin: `0 6px`,
  '.ant-slider-rail': { background: theme.colors.secondaryLighter },
  '.ant-slider-track': { background: `${theme.colors.primary}80` },
  '.ant-slider-handle': {
    background: theme.colors.primary,
    borderColor: theme.colors.primary,
    ':focus': { boxShadow: `0 0 0 5px ${theme.colors.primary}33` },
  },
}));

const Slider = React.memo(({ label, containerId, tipFormatter, ...rest }) => {
  const theme = useTheme();

  let labelContent = null;

  if (label) {
    if (typeof label === 'string') {
      labelContent = <Text css={theme.input.label.css}>{label}</Text>;
    } else {
      labelContent = label;
    }
  }

  return (
    <Wrap direction="column" {...rest}>
      {label}

      <SliderElement
        {...rest}
        tipFormatter={val => (
          <Text size={14} color="primarylight">
            {tipFormatter(val)}
          </Text>
        )}
        getTooltipPopupContainer={() =>
          containerId ? document.getElementById(containerId) : document.body
        }
      />

      {containerId && (
        <Global
          styles={{
            '.ant-slider-tooltip .ant-tooltip-inner': {
              minWidth: 'unset',
              minHeight: 'unset',
              padding: `${theme.dimensions.xs}px ${theme.dimensions.s}px`,
              display: 'flex',
              alignItems: 'center',
            },
          }}
        />
      )}
    </Wrap>
  );
});

Slider.defaultProps = { tipFormatter: val => val };

export default Slider;
