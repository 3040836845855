import styled from '@emotion/styled';
import { Icon } from '@ssmm/ui';
import React from 'react';

import { colors } from '.';

const ButtonBase = styled('button')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `5px 35px`,
  borderRadius: 4,
  cursor: 'pointer',
  fontFamily: 'Open Sans',
  fontWeight: 600,
  fontSize: 16,
  lineHeight: '22px',
  border: '1px solid transparent',
  borderWidth: 1,
  outline: 0,
  transition: 'all .2s',
  i: { fontSize: 25, marginLeft: 10 },
  minHeight: 45,
});

const ButtonLight = styled(ButtonBase)({
  borderColor: colors.black,
  color: colors.grayDark,
  background: '#fff',
  i: { color: colors.grayDark },
  ':hover': { color: '#fff', background: colors.black, i: { color: '#fff' } },
});

const ButtonBlue = styled(ButtonBase)({
  borderColor: colors.blue,
  color: '#fff',
  background: colors.blue,
  i: { color: '#fff' },
  ':hover': { borderColor: colors.black, background: colors.black },
});

const ButtonBlack = styled(ButtonBase)({
  borderColor: colors.black,
  color: '#fff',
  background: colors.black,
  i: { color: '#fff' },
  ':hover': { borderColor: colors.blue, background: colors.blue },
});

const ButtonGreen = styled(ButtonBase)({
  borderColor: colors.green,
  color: '#fff',
  background: colors.green,
  i: { color: '#fff' },
  ':hover': { borderColor: colors.black, background: colors.black },
});

const mapping = {
  light: ButtonLight,
  blue: ButtonBlue,
  black: ButtonBlack,
  green: ButtonGreen,
};

const Button = ({ children, icon, type = 'light', ...rest }) => {
  const Component = mapping[type];

  return (
    <Component {...rest}>
      {children}
      {icon && <Icon svg={icon} />}
    </Component>
  );
};

export default Button;
