import config from 'config';

export const createClickAndDestroyLink =
  (url, newTab = true) =>
  () => {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.rel = 'noopener noreferrer';
    a.href = url;
    if (newTab) a.target = '_blank';
    a.click();
    a.remove();
  };

export const sendMessageExt = data => {
  try {
    const browser = window.chrome || window.browser;

    const { runtime } = browser;

    const { sendMessage } = runtime;

    return new Promise((resolve, reject) => {
      sendMessage(config.ext.id, data, res => {
        if (res?.error) {
          const e = new Error(res.error);
          e.response = res;
          reject(e);
        } else {
          resolve(res);
        }
      });
    });
  } catch (e) {
    console.info(e);

    throw new Error('Браузер не поддерживает отправку сообщений');
  }
};

export const getURLParameter = name => {
  const params = new URL(window.location.href).searchParams;

  return params.get(name);
};

export const getProfileSocUrl = (profileId, soc, isGroup) => {
  switch (soc) {
    case 'inst':
      return `https://www.instagram.com/${profileId}`;

    case 'vk':
      return isGroup ? `https://vk.com/club${profileId}` : `https://vk.com/id${profileId}`;

    default:
      return '';
  }
};
