import React, { useRef, useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Row, Layout } from '../layout';
import { Text } from '../text';
import Tag from './tag';
import { commonParser } from '../helpers';
import { Dropdown } from 'antd';
import Options from './options';

const Wrap = styled(Layout)(({ theme, ...rest }) => ({
  width: '100%',
  flexDirection: 'column',
  ...commonParser({ theme, ...rest }),
}));

const Placeholder = styled(Text)({
  position: 'absolute',
  top: 6,
});

const ContainerElement = styled(Row)(({ theme, disabled }) => {
  const styleObj = {
    minHeight: theme.input.sizes.m.height,
    padding: `3.5px ${theme.dimensions.m}px 0 ${theme.dimensions.m}px`,
    flexWrap: 'wrap',
    border: `1px solid ${theme.colors.secondary}`,
    borderRadius: 4,
    cursor: 'text',
    background: theme.colors.primaryLight,
    position: 'relative',
    '>div:not([contenteditable])': {
      cursor: 'default',
      marginRight: theme.dimensions.xs,
      marginBottom: 3.5,
    },
  };

  if (disabled) {
    styleObj.background = theme.colors.muted;
    styleObj.opacity = 0.5;
    styleObj.pointerEvents = 'none';
  }

  return styleObj;
});

const InputDiv = styled(Layout)(({ theme }) => ({
  padding: `3px 2px 0 2px`,
  marginBottom: 4,
  minWidth: 1,
  maxWidth: '100%',
  border: 'none',
  outline: 'none',
  color: theme.fonts.m.color,
  fontFamily: theme.fonts.mainFont,
  lineHeight: theme.fonts.m.lineHeight,
  fontSize: theme.fonts.m.fontSize,
}));

const getTags = children =>
  (children &&
    [...children]
      .map(child => {
        // тут мы ищем текст тэга
        // проблема в том, что тэг может быть обернут во что-то (поповер, например)
        // и нужно перебирать всех детей чтобы дойти до текста
        let currentChild = child;

        while (currentChild) {
          const nextChild = currentChild.props?.children || null;

          if (typeof nextChild === 'string') {
            return nextChild;
          }

          currentChild = nextChild;
        }

        return null;
      })
      .filter(t => !!t)) ||
  [];

const Container = React.memo(
  ({
    children,
    onAdd,
    disabled,
    label,
    options,
    optionLimit,
    popoverMaxHeight,
    placeholder,
    ...rest
  }) => {
    const [optionsVisible, setOptionsVisible] = useState(false);
    const [tags, setTags] = useState([]);
    const [input, setInput] = useState('');

    const editable = useRef();
    const wrapRef = useRef();
    const optionsRef = useRef();

    useEffect(() => {
      setTags(getTags(children));
    }, [children]);

    const handleClick = e => {
      const { path } = e;

      if (!path.includes(wrapRef.current)) {
        setOptionsVisible(false);
        return;
      }

      editable.current.focus();

      if (options) {
        setOptionsVisible(true);
      }
    };

    useEffect(() => {
      document.body.addEventListener('click', handleClick);

      return () => document.body.removeEventListener('click', handleClick);
    }, []);

    const handleBlur = e => {
      editable.current.innerHTML = '';
      setInput('');
    };

    const handleNewTag = newTag => {
      if (newTag && onAdd && !tags.includes(newTag)) onAdd(newTag);

      editable.current.innerHTML = '';
      editable.current.blur();
    };

    const handleKeyDown = e => {
      const { keyCode, shiftKey } = e;

      if (keyCode !== 13) return;

      if (shiftKey) {
        e.stopPropagation();
        e.preventDefault();

        return;
      }

      handleNewTag(input);
      setOptionsVisible(false);
    };

    const handleKeyUp = () => {
      setInput(editable.current.innerHTML);
    };

    const handleOptionClick = tag => {
      handleNewTag(tag);
    };

    const handlePaste = e => {
      e.preventDefault();
      const textData = e.clipboardData.getData('text');

      if (!textData) {
        return;
      }

      document.execCommand('insertHTML', false, textData);
      setInput(textData);
    };

    const content = (
      <ContainerElement disabled={disabled}>
        {children}

        {!tags.length && !input && <Placeholder color="secondary">{placeholder}</Placeholder>}

        <InputDiv
          disabled={disabled}
          ref={editable}
          contentEditable={!disabled}
          dangerouslySetInnerHTML={{ __html: '' }}
          onPaste={handlePaste}
          onDrop={handlePaste}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        />
      </ContainerElement>
    );

    return (
      <Wrap ref={wrapRef} {...rest}>
        {label && (
          <Row align="center" css={theme => ({ marginBottom: theme.input.label.css.marginBottom })}>
            {typeof label === 'string' ? (
              <Text css={theme => ({ ...theme.input.label.css, marginBottom: 0 })}>{label}</Text>
            ) : (
              label
            )}
          </Row>
        )}

        {options ? (
          <Dropdown
            overlay={
              <Options
                optionsRef={optionsRef}
                options={options}
                tags={tags}
                input={input}
                onOptionClick={handleOptionClick}
                optionLimit={optionLimit}
                popoverMaxHeight={popoverMaxHeight}
              />
            }
            trigger={['']}
            getPopupContainer={triggerNode => triggerNode}
            visible={optionsVisible}
          >
            {content}
          </Dropdown>
        ) : (
          content
        )}
      </Wrap>
    );
  }
);

Container.defaultProps = {
  disabled: false,
  optionLimit: 30,
  popoverMaxHeight: 300,
  placeholder: 'Введите тэг',
};

export default { Tag, Container };
