import React from 'react';
import styled from '@emotion/styled';
import { Text } from '../text';
import { Pagination as AntdPagination } from 'antd';
import { commonParser } from '../helpers';

const StyledAntdPagination = styled(AntdPagination)(({ theme, ...rest }) => ({
  display: 'flex',
  width: '100%',
  '.ant-pagination-item, .ant-pagination-prev a, .ant-pagination-next a': {
    borderRadius: 2,
    borderColor: theme.colors.secondary,
  },
  '.ant-pagination-item, .ant-pagination-prev': {
    marginRight: theme.dimensions.s,
  },
  '& > li:nth-child(2)': {
    marginLeft: theme.dimensions.s,
  },
  '.ant-pagination-prev': {
    marginRight: 'auto',
    a: { color: theme.colors.secondaryDarker },
    ':hover:not(.ant-pagination-disabled) a': {
      borderColor: theme.colors.primary,
      color: theme.colors.primary,
    },
  },
  '.ant-pagination-next': {
    marginLeft: 'auto',
    a: { color: theme.colors.secondaryDarker },
    ':hover:not(.ant-pagination-disabled) a': {
      borderColor: theme.colors.primary,
      color: theme.colors.primary,
    },
  },
  '.ant-pagination-disabled a': {
    color: theme.colors.secondary,
    background: theme.colors.secondaryLighter,
  },
  '.ant-pagination-item-active': {
    span: { color: theme.colors.primary },
    backgroundColor: theme.colors.primaryLighter,
    borderColor: theme.colors.primary,
  },
  '.ant-pagination-item:hover': {
    span: { color: theme.colors.primary },
    borderColor: theme.colors.primary,
  },
  '&.ant-pagination-disabled': {
    '.ant-pagination-item, .ant-pagination-next, .ant-pagination-prev, .ant-pagination-next a, .ant-pagination-prev a':
      {
        pointerEvents: 'none',
        color: theme.colors.secondary,
        background: theme.colors.secondaryLighter,
      },
  },
  '.ant-pagination-jump-prev .ant-pagination-item-container, .ant-pagination-jump-next .ant-pagination-item-container':
    {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      i: { color: theme.colors.primary },
    },
  ...commonParser({ theme, ...rest }),
}));

const Pagination = React.memo(props => (
  <StyledAntdPagination
    {...props}
    locale={{
      prev_page: 'Предыдущая страница',
      next_page: 'Следующая страница',
      prev_5: '5 страниц назад',
      next_5: '5 страниц вперед',
      prev_3: '3 страницы назад',
      next_3: '3 страницы вперед',
    }}
    itemRender={(page, type, element) => {
      switch (type) {
        case 'page':
          return <Text size={14}>{page}</Text>;

        case 'next':
        case 'prev':
        default:
          return element;
      }
    }}
  />
));

Pagination.defaultProps = { hideOnSinglePage: true };

export default Pagination;
