import colors from './colors';

const fonts = {
  mainFont: 'Source Sans Pro, Sans-Serif',
  mainHeadingFont: 'Source Sans Pro, Sans-Serif',
  linkColor: colors.primary,
  linkColorHover: colors.primary,
  h1: {
    fontSize: 24,
    lineHeight: 1.16,
    color: colors.secondaryDark,
    fontWeight: 600,
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  h2: {
    fontSize: 18,
    lineHeight: 1.22,
    color: colors.secondaryDark,
    fontWeight: 600,
    letterSpacing: '0.01em',
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  h3: {
    fontSize: 16,
    lineHeight: 1.375,
    color: colors.secondaryDark,
    fontWeight: 600,
    letterSpacing: '0.02em',
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  h4: {
    fontSize: 14,
    lineHeight: 1.42,
    color: colors.secondaryDark,
    fontWeight: 600,
    letterSpacing: '0.01em',
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  xs: {
    fontSize: 8,
    lineHeight: 1.25,
    color: colors.secondaryDarker,
    fontWeight: 600,
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  s: {
    fontSize: 10,
    lineHeight: 1.25,
    color: colors.secondaryDarker,
    fontWeight: 600,
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  m: {
    fontSize: 12,
    lineHeight: 1.42,
    color: colors.secondaryDarker,
    fontWeight: 400,
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  l: {
    fontSize: 14,
    lineHeight: 1.42,
    color: colors.secondaryDark,
    fontWeight: 600,
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  xl: {
    fontSize: 18,
    lineHeight: 1.22,
    color: colors.secondaryDark,
    fontWeight: 600,
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
};

export default fonts;
