import { Layout, Spin } from '@ssmm/ui';
import React from 'react';

const FullScreenSpinner = React.memo(() => (
  <Spin>
    <Layout css={{ width: '100vw', height: '100vh' }} />
  </Spin>
));

export default FullScreenSpinner;
