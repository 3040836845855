import React from 'react';
import { Text } from '../text';
import { useTheme } from '@emotion/react';
import Icon from '../icon';
import { Popover } from 'antd';
import styled from '@emotion/styled';
import { Layout } from '../layout';
import { commonParser } from '../helpers';

const Wrap = styled(Layout)(({ theme, ...rest }) => ({
  '& .ant-popover-inner-content': {
    padding: theme.dimensions.s,
  },
  ...commonParser({ theme, ...rest }),
}));

const Info = React.memo(({ size, content, trigger, width, color, ...rest }) => {
  const theme = useTheme();

  return (
    <Popover
      overlayStyle={{ maxWidth: width, width }}
      content={
        typeof content === 'string' ? <Text color={theme.fonts.m.color}>{content}</Text> : content
      }
      trigger={trigger}
      getPopupContainer={triggerNode => triggerNode}
    >
      <Wrap {...rest}>
        <Icon
          type="infoCircle"
          color={color || theme.misc.infoIconColor}
          size={size}
          css={theme.effects.hover}
        />
      </Wrap>
    </Popover>
  );
});

Info.defaultProps = {
  trigger: 'hover',
  width: 300,
};

export default Info;
