const commonIcons = {
  close: `<svg width="1em" height="1em" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.58579 11.0001L3.22156 4.63589L4.63577 3.22168L11 9.58591L17.3641 3.22177L18.7784 4.63599L12.4142 11.0001L18.7779 17.3638L17.3637 18.778L11 12.4143L4.63622 18.7781L3.222 17.3639L9.58579 11.0001Z"/>
  </svg>`,
  rubleCircle: `<svg width="1em" height="1em" viewBox="0 0 22 22" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 1.57153C16.2072 1.57153 20.4286 5.79285 20.4286 11.0001C20.4286 16.2074 16.2072 20.4287 11 20.4287C5.79273 20.4287 1.57141 16.2074 1.57141 11.0001C1.57141 5.79285 5.79273 1.57153 11 1.57153ZM11 3.14296C6.6606 3.14296 3.14284 6.66072 3.14284 11.0001C3.14284 15.3395 6.6606 18.8572 11 18.8572C15.3394 18.8572 18.8571 15.3395 18.8571 11.0001C18.8571 6.66072 15.3394 3.14296 11 3.14296ZM12.3146 6.67868C13.9038 6.67868 15.1921 7.967 15.1921 9.55623C15.1921 11.1455 13.9038 12.4338 12.3146 12.4338L10.3832 12.4332V13.5356L11.6693 13.5358C12.0243 13.5358 12.3121 13.8236 12.3121 14.1787C12.3121 14.5337 12.0243 14.8215 11.6693 14.8215L10.3832 14.821L10.3836 15.4338C10.3836 15.8057 10.082 16.1072 9.71008 16.1072H9.59988C9.22793 16.1072 8.92641 15.8057 8.92641 15.4338L8.9257 14.821L8.2407 14.8215C7.88566 14.8215 7.59784 14.5337 7.59784 14.1787C7.59784 13.8236 7.88566 13.5358 8.2407 13.5358L8.9257 13.5356V12.4332L8.27131 12.4338C7.89936 12.4338 7.59784 12.1323 7.59784 11.7603V11.6379C7.59784 11.2659 7.89936 10.9644 8.27131 10.9644L8.9257 10.964L8.92641 7.35215C8.92641 6.9802 9.22793 6.67868 9.59988 6.67868H12.3146ZM12.3427 8.02562H10.3836V10.9644H12.3427C13.1543 10.9644 13.8121 10.3065 13.8121 9.495C13.8121 8.68348 13.1543 8.02562 12.3427 8.02562Z"/>
  </svg>`,
  down: `<svg width="1em" height="1em" viewBox="0 0 13 8" stroke="currentColor" fill='none' xmlns="http://www.w3.org/2000/svg">
  <path d="M1 1L6.5 7L12 1"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>`,
  infoCircle: `<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.9999 1.57129C16.2044 1.57129 20.4284 5.79529 20.4284 10.9999C20.4284 16.2044 16.2044 20.4284 10.9999 20.4284C5.79529 20.4284 1.57129 16.2044 1.57129 10.9999C1.57129 5.79529 5.79529 1.57129 10.9999 1.57129ZM10.9999 3.14272C6.66315 3.14272 3.14272 6.66315 3.14272 10.9999C3.14272 15.3366 6.66315 18.857 10.9999 18.857C15.3366 18.857 18.857 15.3366 18.857 10.9999C18.857 6.66315 15.3366 3.14272 10.9999 3.14272ZM10.9999 14.2216C11.6074 14.2216 12.0999 14.7141 12.0999 15.3216C12.0999 15.9291 11.6074 16.4216 10.9999 16.4216C10.3923 16.4216 9.89986 15.9291 9.89986 15.3216C9.89986 14.7141 10.3923 14.2216 10.9999 14.2216ZM10.948 5.492C12.5593 5.492 13.8644 6.81053 13.8644 8.43843C13.8644 9.08665 13.602 9.67593 13.1864 10.0958L12.5302 10.7735C12.1399 11.1732 11.8585 11.52 11.7397 12.1257C11.729 12.1803 11.7211 12.2614 11.7162 12.3692C11.6982 12.7573 11.3783 13.0627 10.9897 13.0627H10.9825C10.5796 13.0627 10.253 12.7361 10.253 12.3332L10.253 12.3313C10.2535 12.1269 10.2599 11.9931 10.2721 11.9297C10.3849 11.3427 10.671 10.8159 11.072 10.4052L11.976 9.47705C12.2348 9.21673 12.4062 8.84356 12.4062 8.43843C12.4062 7.62816 11.75 6.96522 10.948 6.96522C10.4384 6.96522 9.98763 7.23289 9.72671 7.63595C9.6751 7.71568 9.61018 7.84275 9.53196 8.01718C9.37785 8.3608 8.9875 8.53064 8.63104 8.40916L8.6031 8.39963C8.2393 8.27565 8.04489 7.88022 8.16887 7.51642C8.17341 7.50312 8.17834 7.48996 8.18365 7.47695C8.23559 7.35024 8.28548 7.23824 8.3333 7.14096C8.83411 6.12219 9.77705 5.492 10.948 5.492Z"/>
  </svg>`,
};

export default commonIcons;
