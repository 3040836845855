import React, { useState } from 'react';
import styled from '@emotion/styled/macro';
import { Global, useTheme } from '@emotion/react';
import { Text } from '../text';
import { Popover as AntdPopover } from 'antd';
import { commonParser } from '../helpers';
import { PopoverProps } from 'antd/es/popover';
import { CommonStyleProps } from '../types';

export interface ComponentProps extends PopoverProps, Omit<CommonStyleProps, 'align'> {
  //  width: CSSObject['width'];
  width: string | number;
}

interface AntdComponentProps extends Omit<ComponentProps, 'width'> {
  customContent: boolean;
  afterVisibleChange?: (state: boolean) => void;
}

const StyledAntdPopover = styled(AntdPopover)<AntdComponentProps>(
  ({ theme, customContent, align, ...rest }) => ({
    ...commonParser({ theme, ...rest }),
  })
);

const Popover = React.memo<ComponentProps>(
  ({ content, trigger = 'hover', width = 300, getPopupContainer, ...rest }) => {
    const [selector, setSelector] = useState('');
    const theme = useTheme();
    const customContent: boolean = typeof content !== 'string';

    const handleTriggerNode = (node: HTMLElement) => {
      let mountNode: HTMLElement;

      if (getPopupContainer) {
        mountNode = getPopupContainer(node);
      } else {
        mountNode = node;
      }

      let newSelector = `${mountNode.tagName}`;

      mountNode.classList.forEach(cls => {
        if (cls && !cls.includes('open')) {
          newSelector += `.${cls}`;
        }
      });

      setSelector(newSelector);

      return mountNode;
    };

    return (
      <>
        {selector && (
          <Global
            styles={{
              [`${selector}`]: {
                '.ant-popover-content': { position: 'unset' },
                '.ant-popover-arrow': { zIndex: 1 },
                '.ant-popover-inner': { overflow: 'hidden' },
                '.ant-popover-inner-content': {
                  padding: customContent ? 0 : `${theme.dimensions.s}px ${theme.dimensions.m}px`,
                  color: theme.colors.secondary,
                  fontStyle: 'normal',
                },
              },
            }}
          />
        )}

        <StyledAntdPopover
          overlayStyle={{ maxWidth: width }}
          content={!customContent ? <Text>{content}</Text> : content}
          trigger={trigger}
          getPopupContainer={handleTriggerNode}
          customContent={customContent}
          {...rest}
        />
      </>
    );
  }
);

export default Popover;
