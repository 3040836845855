import { message as antdMessage } from 'antd';

const message = {};

Object.keys(antdMessage).forEach(key => {
  message[key] = props => {
    if (typeof props === 'string') return antdMessage[key](props);

    return antdMessage[key](props);
  };
});

export default message;
