import { AUTH_LOGIN, AUTH_LOGOUT } from 'redux/types';

const initialState = {
  state: 'unknown',
  profile: null,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN:
      return { ...initialState, state: 'loggedIn' };

    case AUTH_LOGOUT:
      return { ...initialState, state: 'loggedOut' };

    default:
      return state;
  }
}
