import colorVars from './colors';

const label = {
  css: { fontWeight: 600, marginBottom: 3, display: 'block', color: colorVars.secondaryDarker },
};

const errorText = {
  css: {
    justifyContent: 'flex-end',
    flexBasis: 15,
  },
};

const colors = {
  noState: {
    text: colorVars.secondaryDarker,
    icon: colorVars.secondary,
    border: colorVars.secondary,
    background: colorVars.primaryLight,
    placeholder: colorVars.secondary,
  },
  hover: {
    text: colorVars.secondaryDarker,
    icon: colorVars.secondary,
    border: colorVars.primary,
    background: colorVars.primaryLight,
    placeholder: colorVars.secondary,
  },
  error: {
    text: colorVars.secondaryDarker,
    icon: colorVars.secondary,
    border: colorVars.danger,
    background: colorVars.primaryLight,
    placeholder: colorVars.secondary,
  },
  disabled: {
    text: colorVars.secondary,
    icon: colorVars.secondary,
    border: colorVars.secondary,
    background: colorVars.secondaryLight,
    placeholder: colorVars.secondary,
  },
};

const sizes = {
  m: { height: 48 },
  l: { height: 40 },
  xl: { height: 55 },
};

export default { label, colors, errorText, sizes };
