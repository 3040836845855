const colors = {
  ssmmPrimary: '#2787F5',
  inbossPrimary: '#DD2A7B',
  inst: '#CF01C4',
  vk: '#2787F5',

  lightBlue: '#58D0FF',
  yellow: '#FFC935',
  purpleLight: '#F9ECFF',
  purple: '#BF5BFF',
  purpleDark: '#6F249D',
  green: '#63C67A',
};

export default colors;
