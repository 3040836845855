import { styled } from '@ssmm/ui';
import React from 'react';

const ListElement = styled('ul')(({ theme }) => ({
  ...theme.fonts.m,
  color: theme.colors.secondary,
  fontWeight: 600,
  margin: 0,
  marginBottom: 0,
  paddingLeft: theme.dimensions.xxl,
  li: {
    listStyle: 'none',
    position: 'relative',
    '&:not(:last-of-type)': { marginBottom: theme.dimensions.s },
    ':before': {
      position: 'absolute',
      display: 'block',
      content: "''",
      background: theme.colors.secondaryDark,
      borderRadius: '50%',
      width: theme.dimensions.xs,
      height: theme.dimensions.xs,
      left: -theme.dimensions.m,
      top: `calc(50% - ${theme.dimensions.xs / 2}px)`,
    },
  },
}));

const List = React.memo(({ children, ...rest }) => <ListElement {...rest}>{children}</ListElement>);

export default List;
