import { ssmm, ThemeProvider } from '@ssmm/theme';
import { CacheProvider } from '@ssmm/ui';
import { setAuthListener } from 'components/authorization/actions';
import FullScreenSpinner from 'components/ui/full-screen-spinner';
import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import ym from 'react-yandex-metrika';
import store from 'store';
import vkBoss from 'theme';

import Footer from './components/Footer/container';
// import Page404 from './components/Page404/Page404';
import LK from './components/LK/container';
import Fail from './components/Payment/Fail';
import Success from './components/Payment/Success';
import PdfModal from './components/pdf-modal/container';
import RegSuccess from './components/RegSuccess/container';
import YandexMetrica from './components/YandexMetrica';
import history from './history';
import { saveRefCodeToSessionStorage } from './lib/analytics';

const AuthExt = React.lazy(() => import('components/auth-ext/container'));
const Landing = React.lazy(() => import('components/landing/container'));

const Routes = React.memo(() => {
  useEffect(() => {
    ym('hit', history.location.pathname + history.location.search);

    history.listen(location => {
      ym('hit', location.pathname + location.search);
    });
  }, []);

  useEffect(() => {
    saveRefCodeToSessionStorage();

    store.dispatch(setAuthListener());
  }, []);

  return (
    <>
      <ThemeProvider theme={ssmm}>
        <YandexMetrica />

        <Suspense fallback={<FullScreenSpinner />}>
          <Router history={history}>
            <Route path="/extinstall">
              <ThemeProvider theme={vkBoss}>
                <LK installPage />
              </ThemeProvider>
            </Route>

            <Switch>
              <Route exact path="/" component={Landing} />

              <Route exact path="/lk">
                <ThemeProvider theme={vkBoss}>
                  <LK />
                </ThemeProvider>
              </Route>

              <Route>
                <CacheProvider scopeId="root">
                  <main className="App">
                    <Switch>
                      <Route exact path="/auth-ext" component={AuthExt} />

                      <Route>
                        <div className="container">
                          <Switch>
                            <Route exact path="/registration/:id?" component={RegSuccess} />
                            <Route exact path="/payment/success" component={Success} />
                            <Route exact path="/payment/fail" component={Fail} />
                            {/* <Route component={Page404} /> */}
                          </Switch>
                          <Footer />
                        </div>
                      </Route>
                    </Switch>
                  </main>
                </CacheProvider>
              </Route>
            </Switch>
          </Router>
        </Suspense>

        <CacheProvider scopeId="root">
          <PdfModal />
        </CacheProvider>
      </ThemeProvider>
    </>
  );
});

export default Routes;
