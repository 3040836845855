import React from 'react';
import { Text } from '../text';
import styled from '@emotion/styled';
import { Layout } from '../layout';
import Icon from '../icon';
import effects from '../effects';
import { commonParser } from '../helpers';

const TagElement = styled(Layout)(({ theme, color, ...rest }) => ({
  alignItems: 'center',
  borderRadius: 4,
  background: theme.colors[color] || color,
  padding: `3px ${theme.dimensions.m}px`,
  maxWidth: '100%',
  ...commonParser({ theme, ...rest }),
}));

const Tag = React.memo(({ children, color, onDelete, ...rest }) => (
  <TagElement color={color} {...rest}>
    {typeof children === 'string' ? <Text color="primaryLight">{children}</Text> : children}

    {onDelete && (
      <Icon
        type="closeOutline"
        set="boss"
        color="primaryLight"
        onClick={e => {
          e.stopPropagation();
          onDelete();
        }}
        css={theme => ({
          ...effects.hover,
          marginLeft: theme.dimensions.s,
        })}
      />
    )}
  </TagElement>
));

Tag.defaultProps = { color: 'secondary' };

export default Tag;
