import 'firebase/compat/auth';
import 'firebase/compat/database';

import firebase from 'firebase/compat/app';
import { userSubscribe, userUnsubscribe } from 'redux/actions/user';
import { AUTH_LOGIN, AUTH_LOGOUT, SET_AUTH_LOADER } from 'redux/types';

export const setAuthListener = () => dispatch => {
  firebase.auth().onAuthStateChanged(user => {
    if (user) {
      const { uid } = user;

      dispatch({
        type: AUTH_LOGIN,
      });

      dispatch(userSubscribe(uid));
    } else {
      dispatch({
        type: AUTH_LOGOUT,
      });

      /* dispatch({
        type: AUTH_LOGIN,
      });

      dispatch(userSubscribe('YZXmd0b6roNGEvLb1BYivV5Grst1')); */
    }
  });
};

export const handleMessage = data => dispatch => {
  const { status, token } = data;

  if (status === 'auth/success') {
    firebase.auth().signInWithCustomToken(token);
  } else if (status) {
    dispatch({
      type: AUTH_LOGOUT,
    });

    console.info(`Ошибка авторизации: ${status}`);
  }
};

export const handleCred = cred => () => firebase.auth().signInWithCredential(cred);

export const logout = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_AUTH_LOADER,
      payload: true,
    });

    const { user } = getState();

    if (user) {
      await dispatch(userUnsubscribe());
    }

    await firebase.auth().signOut();
  } catch (e) {
    console.info(e);
  } finally {
    dispatch({
      type: SET_AUTH_LOADER,
      payload: false,
    });
  }
};
