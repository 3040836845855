import commonColors from '../../common/colors';

const colors = {
  danger: 'unset',
  success: 'unset',
  warning: 'unset',
  dangerLight: 'unset',
  successLight: 'unset',
  warningLight: 'unset',
  dangerDark: 'unset',
  successDark: 'unset',
  warningDark: 'unset',

  primary: 'unset',
  primaryLighter: 'unset',
  primaryLight: 'unset',
  secondaryDark: 'unset',
  secondaryDarker: 'unset',
  secondary: 'unset',
  secondaryLighter: 'unset',
  secondaryLight: 'unset',
  muted: 'unset',
  accent: 'unset',

  ...commonColors,
};

export default colors;
