import { CacheProvider as EmotionCacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
// import extraScopePlugin from 'stylis-plugin-extra-scope';
import extraScopePlugin from './plugin';
import { createElement, useMemo } from 'react';

const CacheProvider = props => {
  const { scopeId } = props;

  const memoizedCache = useMemo(
    () =>
      createCache({
        key: 'ui',
        stylisPlugins: [extraScopePlugin(`#${scopeId}`)],
      }),
    [scopeId]
  );

  return createElement(EmotionCacheProvider, { value: memoizedCache, ...props });
};

export default CacheProvider;
