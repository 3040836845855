import { CLOSE_PDF_MODAL, OPEN_PDF_MODAL } from 'redux/types';

const initialState = {
  pdfModalOpen: false,
  pdfModalTitle: '',
  pdfModalUrl: '',
};

export default function pdfModal(state = initialState, action) {
  switch (action.type) {
    case OPEN_PDF_MODAL:
      return {
        ...state,
        pdfModalOpen: true,
        pdfModalTitle: action.title,
        pdfModalUrl: action.url,
      };

    case CLOSE_PDF_MODAL:
      return {
        ...state,
        pdfModalOpen: false,
        pdfModalTitle: null,
        pdfModalUrl: null,
      };

    default:
      return state;
  }
}
