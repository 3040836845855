import commonColors from '../../common/colors';

const colors = {
  danger: '#DC5858', // с этими
  success: '#63C67A', // все
  warning: '#F7B500', // понятно
  dangerLight: '#FDF6F6', // с этими
  successLight: '#F7FCF8', // все
  warningLight: '#FEFBF2', // понятно
  dangerDark: '#721C24', // с этими
  successDark: '#155724', // все
  warningDark: '#856404', // понятно

  primary: '#2787F5',
  primaryLighter: '#EAF3FE',
  primaryLight: '#ffffff',
  secondaryDark: '#000000',
  secondaryDarker: '#262626',
  secondary: '#9EB1C3',
  secondaryLighter: '#DAE2EA',
  secondaryLight: '#F5F6F8',
  muted: '#DAE2EA',
  accent: '#F6F9FD',

  ...commonColors,
};

export default colors;
