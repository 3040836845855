import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { Column } from '../layout';
import Panel, { ComponentProps as PanelProps, Key } from './panel';
import { commonParser } from '../helpers';
import { CommonStyleProps } from '../types';

interface ComponentProps extends CommonStyleProps {
  children: React.ReactElement<PanelProps> | React.ReactElement<PanelProps>[];
  activeKey?: Key | Key[];
  multiple?: boolean;
  onChange?: (state: string[], event: React.MouseEvent) => void;
}

const Container = styled(Column)(({ theme, ...rest }) => ({
  '>div:nth-of-type(n+2)': { marginTop: theme.dimensions.xs },
  ...commonParser({ theme, ...rest }),
}));

const Accordion = React.memo<ComponentProps>(
  ({ children, activeKey: activeKeyProp, onChange, multiple = true, ...rest }) => {
    const [activeKey, setActiveKey] = useState(activeKeyProp);

    useEffect(() => {
      setActiveKey(activeKeyProp);
    }, [activeKeyProp]);

    const handleClick = (key: Key, event: React.MouseEvent) => {
      let newState;

      if (multiple && typeof activeKey === 'object') {
        let newActiveKey = activeKey.slice();

        if (newActiveKey.includes(key)) {
          newActiveKey = newActiveKey.filter(k => k !== key);
        } else {
          newActiveKey.push(key);
        }

        newState = newActiveKey;
      } else if (activeKey === key) {
        newState = undefined;
      } else {
        newState = key;
      }

      if (onChange) {
        onChange(newState, event);
      } else {
        setActiveKey(newState);
      }
    };

    return (
      <Container {...rest}>
        {React.Children.map(children, (child: React.ReactElement<PanelProps>) =>
          React.cloneElement(child, {
            open:
              multiple && typeof activeKey === 'object'
                ? activeKey.includes(child.key)
                : activeKey === child.key,
            onClick: (e: React.MouseEvent) => handleClick(child.key, e),
          })
        )}
      </Container>
    );
  }
);

export default { AccordionContainer: Accordion, AccordionPanel: Panel };
