import styled from '@emotion/styled';
import React from 'react';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  paddingRight: 15,
  paddingLeft: 15,
  background: '#FAFBFC',
  '@media (min-width:768px)': { paddingRight: 20, paddingLeft: 20 },
  '@media (min-width:992px)': { paddingRight: 25, paddingLeft: 25 },
  '@media (min-width:1200px)': {
    paddingRight: 45,
    paddingLeft: 45,
    background: 'none',
    '>div, >header, >footer, >section': { maxWidth: 1200 },
  },
  '>div, >header, >footer, >section': { width: '100%', zIndex: 1 },
});

const SectionElement = styled('section')({
  display: 'flex',
  position: 'relative',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',
});

const Stripe = styled('div')(({ pos }) => ({
  display: 'flex',
  position: 'absolute',
  height: '100%',
  width: 1,
  top: 0,
  left: `${(pos - 1) * (100 / 6)}%`,
  background: '#EDEDED',
  transform: 'scaleX(1.01)',
  zIndex: -1,
}));

const Stripes = () => (
  <>
    <Stripe pos={1} />
    <Stripe pos={2} />
    <Stripe pos={3} />
    <Stripe pos={4} />
    <Stripe pos={5} />
    <Stripe pos={6} />
    <Stripe pos={7} />
  </>
);

const Section = ({ children, ...rest }) => (
  <SectionElement {...rest}>
    {children}
    <Stripes />
  </SectionElement>
);

export { Container, Section, Stripes };
