import styled from '@emotion/styled';
import { Layout } from '../../layout';

const FakeRadio = styled(Layout)({
  position: 'relative',
  width: 18,
  height: 18,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
});

const Checked = styled(Layout)(({ theme }) => ({
  width: 10,
  height: 10,
  background: theme.colors.primary,
  borderRadius: '50%',
}));

export default { FakeRadio, Checked };
