import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { CSSObject } from '@emotion/styled/types';
import Icon from '../icon';
import { commonParser } from '../helpers';
import { SsmmIcon, BossIcon } from '../icon/types';
import { Size, IconSet } from '@ssmm/theme/dist/types/common';
import { ButtonSize, ButtonType } from '@ssmm/theme/dist/types/button';
import { CommonStyleProps } from '../types';

export interface ComponentProps extends CommonStyleProps {
  icon?: SsmmIcon | BossIcon;
  iconSet?: IconSet;
  iconSize?: Size;
  size?: ButtonSize;
  type?: ButtonType;
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
}

interface ElementProps {
  buttonType: ButtonType;
  size: ButtonSize;
  noText: boolean;
}

interface TitleProps {
  css: CSSObject;
}

const ButtonElement = styled('button')<ElementProps>(
  ({ theme, size, buttonType, noText, ...rest }) => ({
    display: 'flex',
    flexShrink: 0,
    flexGrow: 0,
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: noText ? theme.button.sizes[size].minHeight : theme.button.sizes[size].minWidth,
    minHeight: theme.button.sizes[size].minHeight,
    padding: noText ? 3 : theme.button.sizes[size].padding,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: theme.button.sizes[size].borderRadius,
    outline: 0,
    transition: '0.2s',
    cursor: 'pointer',
    boxSizing: 'border-box',
    borderColor: theme.colors[theme.button.colors[buttonType].noState.border],
    backgroundColor: theme.colors[theme.button.colors[buttonType].noState.background],
    '&>span': {
      display: 'flex',
      color: theme.colors[theme.button.colors[buttonType].noState.text],
    },
    '&>i': {
      marginRight: noText ? 0 : theme.button.sizes[size].iconMargin,
      color: theme.colors[theme.button.colors[buttonType].noState.icon],
    },
    '&:hover': {
      borderColor: theme.colors[theme.button.colors[buttonType].hover.border],
      backgroundColor: theme.colors[theme.button.colors[buttonType].hover.background],
      '&>span': {
        color: theme.colors[theme.button.colors[buttonType].hover.text],
      },
      '&>i': {
        color: theme.colors[theme.button.colors[buttonType].hover.icon],
      },
    },
    '&:disabled': {
      borderColor: theme.colors[theme.button.colors[buttonType].disabled.border],
      backgroundColor: theme.colors[theme.button.colors[buttonType].disabled.background],
      pointerEvents: 'none',
      '&>span': {
        color: theme.colors[theme.button.colors[buttonType].disabled.text],
      },
      '&>i': {
        color: theme.colors[theme.button.colors[buttonType].disabled.icon],
      },
    },
    ...commonParser({ theme, ...rest }),
  })
);

const TitleElement = styled('span')<TitleProps>(({ css }) => css);

const Button = React.memo<ComponentProps>(
  ({ icon, iconSize, iconSet, children, size = 'm', type = 'default', disabled, ...rest }) => {
    const theme = useTheme();

    let noText = false;
    const content = [];

    if (icon) {
      content.push(
        <Icon
          key="icon"
          type={icon}
          set={iconSet || theme.misc.iconSet}
          size={iconSize || theme.button.sizes[size].iconSize}
          color={theme.button.colors[type].noState.icon}
        />
      );
    }

    if (children !== undefined) {
      content.push(
        <TitleElement key="text" css={theme.button.sizes[size].titleCss}>
          {children}
        </TitleElement>
      );
    } else {
      noText = true;
    }

    return (
      <ButtonElement size={size} buttonType={type} disabled={disabled} noText={noText} {...rest}>
        {content}
      </ButtonElement>
    );
  }
);

export default Button;
