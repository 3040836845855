import commonColors from '../../common/colors';

const colors = {
  danger: 'unset',
  success: 'unset',
  warning: 'unset',
  dangerLight: 'unset',
  successLight: 'unset',
  warningLight: 'unset',
  dangerDark: 'unset',
  successDark: 'unset',
  warningDark: 'unset',

  primary: 'unset',
  primaryLighter: 'unset',
  primaryLight: 'unset',
  secondaryDark: 'unset',
  secondaryDarker: 'unset',
  secondary: 'unset',
  secondaryLighter: 'unset',
  secondaryLight: 'unset',
  muted: 'unset',
  accent: 'unset',

  // на всякий случай для button-old
  black: '#000000',
  white: '#ffffff',
  transparent: 'transparent',
  color5: '#9EB1C3',
  color6: '#2787F5',
  color7: '#C7DAF1',
  color8: '#88BAF9',
  color9: '#63C67A',
  color10: '#A0DFAF',
  color11: 'unset',
  color12: '#1B2431',

  ...commonColors,
};

export default colors;
