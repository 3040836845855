import commonColors from '../../common/colors';

const colors = {
  danger: '#DC5858', // с этими
  success: '#63C67A', // все
  warning: '#F7B500', // понятно
  dangerLight: '#FDF6F6', // с этими
  successLight: '#F7FCF8', // все
  warningLight: '#FEFBF2', // понятно
  dangerDark: '#721C24', // с этими
  successDark: '#155724', // все
  warningDark: '#856404', // понятно

  primary: '#DD2A7B', // это синий - все кнопки и иконки
  primaryLighter: '#FDF4F8',
  primaryLight: '#ffffff',
  secondaryDark: '#000000', // это темный - шапки заголовки
  secondaryDarker: '#262626', // это не знаю что
  secondary: '#9EB1C3', // это серый - текст
  secondaryLighter: '#DAE2EA', // это светло-серый
  secondaryLight: '#F5F6F8', // это очень светлый серый - bg/tr
  muted: '#DAE2EA', // это светло-серый - disabled
  accent: '#F6F9FD', // это hover таблицы

  ...commonColors,
};

export default colors;
