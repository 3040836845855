import {
  Button,
  Column,
  HeadingH2,
  Icon,
  Info,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Text,
} from '@ssmm/ui';
import { func, number, object } from 'prop-types';
import React, { useState } from 'react';

import YellowTitle from '../../components/yellow-title';
import NewUserPromo from '../../promo/new-user';
import BonusList from './bonus-list';

const { RadioGroup, RadioButton } = Radio;

const bonusSum = {
  m3: 300,
  m6: 1000,
  m12: 1700,
};

const NewUser = React.memo(({ onClose, tariffs, chargeUserOld, newUserOfferAvailable }) => {
  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('m3');

  const price = tariffs.fullPackage.price[period];

  const handlePurchaseClick = async () => {
    setLoading(true);

    await chargeUserOld({
      description: tariffs.fullPackage.title,
      amount: price,
      data: {
        rate: 'fullPackage',
        period,
        purchase: 'tariff_activation',
      },
    });

    setLoading(false);
  };

  const discount = {
    m3: tariffs.fullPackage.price.m1 * 3 - tariffs.fullPackage.price.m3,
    m6: tariffs.fullPackage.price.m1 * 6 - tariffs.fullPackage.price.m6,
    m12: tariffs.fullPackage.price.m1 * 12 - tariffs.fullPackage.price.m12,
  };

  const selectedPeriodDiscount = discount[period];

  return (
    <Modal
      title={null}
      width={975}
      onClose={onClose}
      getContainer={() => document.getElementById('root')}
    >
      <Spin spinning={loading} mask={false} align="stretch" scroll>
        <Layout direction="column" basis="50%">
          <Row
            justify="space-between"
            align="center"
            padding={['m', 'l']}
            css={theme => ({ borderBottom: `1px solid ${theme.colors.secondaryLighter}` })}
          >
            <HeadingH2>Специальное предложение для новичков</HeadingH2>
          </Row>

          <Column padding={['m', 'm', 'l', 'm']} grow={1}>
            <NewUserPromo timeLeft={newUserOfferAvailable} />
          </Column>
        </Layout>

        <Layout
          basis="50%"
          direction="column"
          css={theme => ({ borderLeft: `1px solid ${theme.colors.secondaryLighter}` })}
        >
          <Column
            padding="l"
            css={theme => ({ borderBottom: `1px solid ${theme.colors.secondaryLighter}` })}
          >
            <RadioGroup
              value={period}
              onChange={setPeriod}
              size="xl"
              width="100%"
              marginBottom="l"
              css={{ label: { width: '33.3%', position: 'relative' } }}
            >
              <RadioButton value="m3">
                <Column align="center" justify="center">
                  <Text size="h3">3 месяца</Text>
                  <Text
                    weight={600}
                    css={theme => ({ color: `${theme.colors.success} !important` })}
                  >
                    Выгода {discount.m3 + bonusSum.m3}
                    <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
                  </Text>
                </Column>
              </RadioButton>
              <RadioButton value="m6">
                <Column align="center" justify="center">
                  <Text size="h3">6 месяцев</Text>
                  <Text
                    weight={600}
                    css={theme => ({ color: `${theme.colors.success} !important` })}
                  >
                    Выгода {discount.m6 + bonusSum.m6}
                    <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
                  </Text>
                </Column>
              </RadioButton>
              <RadioButton value="m12">
                <Column align="center" justify="center">
                  <Text size="h3">На год</Text>
                  <Text
                    weight={600}
                    css={theme => ({ color: `${theme.colors.success} !important` })}
                  >
                    Выгода {discount.m12 + bonusSum.m12}
                    <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
                  </Text>
                </Column>
              </RadioButton>
            </RadioGroup>

            <Column>
              <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
                <Layout align="center">
                  <Icon type="commentIndicatorFilled" color="primary" size={22} marginRight="m" />
                  <Text size="l">Секретарь</Text>
                </Layout>
                <Info content="Секретарь" />
              </Row>

              <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
                <Layout align="center">
                  <Icon type="giftFilled" color="primary" size={22} marginRight="m" />
                  <Text size="l">Поздравления</Text>
                </Layout>
                <Info content="Поздравления" />
              </Row>

              <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
                <Layout align="center">
                  <Icon type="likeFilled" color="primary" size={22} marginRight="m" />
                  <Text size="l">Привлечение внимания</Text>
                </Layout>
                <Info content="Привлечение внимания" />
              </Row>

              <Row justify="space-between" align="center" marginBottom="l" css={{ minHeight: 32 }}>
                <Layout align="center">
                  <Icon type="likeCommentFilled" color="primary" size={22} marginRight="m" />
                  <Text size="l">Сбор ЦА</Text>
                </Layout>
                <Info content="Сбор ЦА" />
              </Row>

              <YellowTitle marginBottom="l">+ бонус</YellowTitle>

              <BonusList period={period} discount={selectedPeriodDiscount} />

              <Row
                padding="s"
                align="center"
                justify="space-between"
                css={theme => ({ border: `1px dashed ${theme.colors.secondary}` })}
              >
                <Text size="l">К оплате:</Text>

                <Select value={period} disabled width={105}>
                  <Select.Option key="m3" value="m3">
                    3 месяца
                  </Select.Option>
                  <Select.Option key="m6" value="m6">
                    6 месяцев
                  </Select.Option>
                  <Select.Option key="m12" value="m12">
                    Год
                  </Select.Option>
                </Select>

                <Layout align="center">
                  {selectedPeriodDiscount > 0 && (
                    <>
                      <Icon type="rubleReturn" size={22} color="success" marginRight="xs" />
                      <Text size="l" color="success" marginRight="m">
                        Выгода: {selectedPeriodDiscount + (bonusSum[period] || 0)}{' '}
                        <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
                      </Text>
                    </>
                  )}
                  <Text size="h1">
                    {price}
                    <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
                  </Text>
                </Layout>
              </Row>
            </Column>
          </Column>

          <Column padding={['m', 'm', 'l', 'm']}>
            <Button width="100%" type="dark" onClick={handlePurchaseClick}>
              Перейти к оплате
            </Button>
          </Column>
        </Layout>
      </Spin>
    </Modal>
  );
});

NewUser.propTypes = {
  onClose: func.isRequired,
  tariffs: object.isRequired,
  chargeUserOld: func.isRequired,
  newUserOfferAvailable: number.isRequired,
};

export default NewUser;
