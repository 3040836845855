import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Switch as AntdSwitch } from 'antd';
import ssmm from './ssmm';
import boss from './boss';
import { commonParser } from '../helpers';
import { CommonStylePropsKeys } from '../types';

const styles = {
  ssmm,
  boss,
};

const SwitchElement = styled(AntdSwitch, {
  shouldForwardProp: prop => !CommonStylePropsKeys.includes(prop),
})(({ theme, type, color, ...rest }) => ({
  ...styles[type]({ theme, color }),
  ...commonParser({ theme, ...rest }),
}));

const Switch = React.memo(({ type: typeProp, ...rest }) => {
  const theme = useTheme();
  const type = typeProp || theme.misc.switchType;

  return <SwitchElement type={type} {...rest} />;
});

Switch.defaultProps = {
  color: 'default',
};

export default Switch;
