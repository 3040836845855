import { Button, Icon, Layout, Row, styled, Text } from '@ssmm/ui';
import moment from 'moment';
import { func, number } from 'prop-types';
import React from 'react';

export const Block = styled(Row)(({ theme }) => ({
  border: `1px solid #4B4B4B`,
  borderRadius: theme.misc.borderRadius,
  justifyContent: 'space-between',
  alignItems: 'stretch',
  padding: 15,
  flexDirection: 'column',
}));

const Ai = React.memo(({ price, active, onPurchaseClick }) => (
  <Block background={active ? 'primaryLight' : undefined}>
    <Row marginBottom="s" justify="space-between">
      <Layout direction="column" marginRight="s" marginBottom="xs">
        <Row marginBottom="s" align="center" shrink={1}>
          <Icon type="commentIndicatorFilled" color="primary" size={22} marginRight="m" />
          <Text size="l">Секретарь</Text>
        </Row>
        <Row align="center">
          <Icon type="giftFilled" color="primary" size={22} marginRight="m" />
          <Text size="l">Поздравления</Text>
        </Row>
      </Layout>

      <Layout align="flex-end">
        <Text size="h1" color="primary" line={24}>
          {price}
          <Text size="h1" color="primary" line={24} font="SFUIText-Regular">
            <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
          </Text>
        </Text>
        <Text color="primary">&nbsp;/&nbsp;месяц</Text>
      </Layout>
    </Row>
    <Row align="center" justify="space-between">
      <Text size="h2" css={{ textTransform: 'uppercase' }} color="secondary">
        Пакет №1
      </Text>

      {active ? (
        <Layout justify="flex-end" align="center" shrink={1}>
          <Text weight={400} color="success" marginRight="m" size={14} font="Open Sans">
            {`до ${moment(active).format('DD.MM.YYYY')}`}
          </Text>
          <Button onClick={onPurchaseClick} type="success">
            Продлить
          </Button>
        </Layout>
      ) : (
        <Button onClick={onPurchaseClick} type="dark">
          Купить
        </Button>
      )}
    </Row>
  </Block>
));

Ai.propTypes = {
  onPurchaseClick: func.isRequired,
  price: number.isRequired,
  active: number.isRequired,
};

export default Ai;
