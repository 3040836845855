import { vkBoss } from '@ssmm/theme';

const fonts = {
  mainFont: 'Open Sans',
  mainHeadingFont: 'Open Sans',
  linkColor: vkBoss.colors.primary,
  linkColorHover: vkBoss.colors.primary,
  h1: {
    fontSize: 24,
    lineHeight: 1.16,
    color: vkBoss.colors.secondaryDark,
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
  h2: {
    fontSize: 18,
    lineHeight: '30px',
    color: '#9EB1C3',
    fontWeight: 600,
    letterSpacing: '0.01em',
    fontFamily: 'Open Sans',
  },
  h3: {
    fontSize: 16,
    lineHeight: 1.375,
    color: vkBoss.colors.secondaryDark,
    fontWeight: 600,
    letterSpacing: '0.02em',
    fontFamily: 'Open Sans',
  },
  h4: {
    fontSize: 14,
    lineHeight: 1.42,
    color: vkBoss.colors.secondaryDark,
    fontWeight: 600,
    letterSpacing: '0.01em',
    fontFamily: 'Open Sans',
  },
  xs: {
    fontSize: 8,
    lineHeight: 1.25,
    color: vkBoss.colors.secondaryDarker,
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
  s: {
    fontSize: 10,
    lineHeight: 1.25,
    color: vkBoss.colors.secondaryDarker,
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
  m: {
    fontSize: 12,
    lineHeight: 1.42,
    color: vkBoss.colors.secondaryDarker,
    fontWeight: 400,
    fontFamily: 'Open Sans',
  },
  l: {
    fontSize: 16,
    lineHeight: '22px',
    color: '#000',
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
  xl: {
    fontSize: 18,
    lineHeight: 1.22,
    color: vkBoss.colors.secondaryDark,
    fontWeight: 600,
    fontFamily: 'Open Sans',
  },
};

const theme = {
  ...vkBoss,
  fonts,
  button: {
    sizes: {
      s: {
        minWidth: 0,
        padding: '10px 25px',
        iconMargin: 6,
        iconSize: '17px',
        minHeight: 45,
        borderRadius: 4,
        titleCss: { ...fonts.l, lineHeight: '22px', fontWeight: 600 },
      },
      m: {
        minWidth: 0,
        padding: '10px 25px',
        iconMargin: 6,
        iconSize: '17px',
        minHeight: 45,
        borderRadius: 4,
        titleCss: { ...fonts.l, lineHeight: '22px', fontWeight: 600 },
      },
      l: {
        minWidth: 0,
        padding: '7.5px 20px 8.5px 20px',
        iconMargin: 6,
        iconSize: '17px',
        minHeight: 40,
        borderRadius: '20px',
        titleCss: { ...fonts.l, lineHeight: '15px', fontWeight: 600 },
      },
    },
    colors: {
      default: {
        noState: {
          text: 'primaryLight',
          border: 'primary',
          background: 'primary',
          icon: 'primaryLight',
        },
        hover: {
          text: 'primaryLight',
          border: 'secondaryDark',
          background: 'secondaryDark',
          icon: 'primaryLight',
        },
        disabled: {
          text: 'primaryLight',
          border: 'muted',
          background: 'muted',
          icon: 'primaryLight',
        },
      },
      light: {
        noState: {
          text: 'secondaryDark',
          border: 'secondary',
          background: 'primaryLight',
          icon: 'secondary',
        },
        hover: { text: 'primary', border: 'primary', background: 'primaryLight', icon: 'primary' },
        disabled: {
          text: 'secondary',
          border: 'secondary',
          background: 'primaryLight',
          icon: 'secondary',
        },
      },
      dark: {
        noState: {
          text: 'primaryLight',
          border: 'secondaryDark',
          background: 'secondaryDark',
          icon: 'primaryLight',
        },
        hover: {
          text: 'primaryLight',
          border: 'primary',
          background: 'primary',
          icon: 'primaryLight',
        },
        disabled: {
          text: 'primaryLight',
          border: 'muted',
          background: 'muted',
          icon: 'primaryLight',
        },
      },
      lightContrast: {
        noState: {
          text: 'secondaryDark',
          border: 'primaryLight',
          background: 'primaryLight',
          icon: 'secondaryDark',
        },
        hover: {
          text: 'primary',
          border: 'primaryLight',
          background: 'primaryLight',
          icon: 'primary',
        },
        disabled: {
          text: 'muted',
          border: 'primaryLight',
          background: 'primaryLight',
          icon: 'muted',
        },
      },
      darkContrast: {
        noState: {
          text: 'primaryLight',
          border: 'secondaryDark',
          background: 'secondaryDark',
          icon: 'primaryLight',
        },
        hover: {
          text: 'primaryLight',
          border: 'primary',
          background: 'primary',
          icon: 'primaryLight',
        },
        disabled: {
          text: 'primaryLight',
          border: 'muted',
          background: 'muted',
          icon: 'primaryLight',
        },
      },
      danger: {
        noState: {
          text: 'primaryLight',
          border: 'danger',
          background: 'danger',
          icon: 'primaryLight',
        },
        hover: {
          text: 'primaryLight',
          border: 'primary',
          background: 'primary',
          icon: 'primaryLight',
        },
        disabled: {
          text: 'primaryLight',
          border: 'muted',
          background: 'muted',
          icon: 'primaryLight',
        },
      },
      warning: {
        noState: {
          text: 'primaryLight',
          border: 'warning',
          background: 'warning',
          icon: 'primaryLight',
        },
        hover: {
          text: 'primaryLight',
          border: 'primary',
          background: 'primary',
          icon: 'primaryLight',
        },
        disabled: {
          text: 'primaryLight',
          border: 'muted',
          background: 'muted',
          icon: 'primaryLight',
        },
      },
      success: {
        noState: {
          text: 'primaryLight',
          border: 'success',
          background: 'success',
          icon: 'primaryLight',
        },
        hover: {
          text: 'primaryLight',
          border: 'primary',
          background: 'primary',
          icon: 'primaryLight',
        },
        disabled: {
          text: 'primaryLight',
          border: 'muted',
          background: 'muted',
          icon: 'primaryLight',
        },
      },
    },
  },
};

export default theme;
