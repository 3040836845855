import {
  LANDING_CLOSE_TARIFFS,
  LANDING_CLOSE_VIDEO,
  LANDING_CLOSE_VIDEO_GALLERY,
  LANDING_OPEN_TARIFFS,
  LANDING_OPEN_VIDEO,
  LANDING_OPEN_VIDEO_GALLERY,
  LANDING_SET_CASES,
  LANDING_SET_MODULE_ID,
  LANDING_SET_REVIEW,
  LANDING_SET_REVIEWS,
} from 'redux/types';

const initialState = {
  videoGalleryOpen: false,
  currentModuleId: 'congrats',
  videoOpen: false,
  videoSourceId: null,
  currentReviewIndex: 0,
  tariffsOpen: false,
  cases: null,
  reviews: null,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LANDING_OPEN_VIDEO_GALLERY:
      return { ...state, videoGalleryOpen: true };

    case LANDING_CLOSE_VIDEO_GALLERY:
      return { ...state, videoGalleryOpen: false };

    case LANDING_OPEN_VIDEO:
      return { ...state, videoOpen: true, videoSourceId: payload };

    case LANDING_CLOSE_VIDEO:
      return { ...state, videoOpen: false, videoSourceId: null };

    case LANDING_SET_MODULE_ID:
      return { ...state, currentModuleId: payload };

    case LANDING_SET_REVIEW:
      return { ...state, currentReviewIndex: payload };

    case LANDING_OPEN_TARIFFS:
      return { ...state, tariffsOpen: true };

    case LANDING_CLOSE_TARIFFS:
      return { ...state, tariffsOpen: false };

    case LANDING_SET_CASES:
      return { ...state, cases: payload };

    case LANDING_SET_REVIEWS:
      return { ...state, reviews: payload };

    default:
      return state;
  }
}
