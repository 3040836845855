import { Button, Column } from '@ssmm/ui';
import { bool, func, string } from 'prop-types';
import React, { useEffect } from 'react';

// import PhoneAuth from './phone-auth';
import Registration from '../../registration/container';

const AuthUi = ({ loginViaVk, onMessage, onCred, onMount, onUnmount, newUser, source }) => {
  useEffect(() => {
    onMount({ handlers: { onMessage, onCred } });

    return onUnmount;
  }, [onMessage, onCred, onMount, onUnmount]);

  return newUser ? (
    <Registration source={source} />
  ) : (
    <Column align="center">
      <Button
        onClick={() => loginViaVk(source)}
        icon="vk"
        iconSet="ssmm"
        size="l"
        marginBottom="m"
        width={258}
        css={{
          borderColor: '#108EE9',
          backgroundColor: '#108EE9',
          '>span': { fontSize: 15, marginLeft: 10 },
        }}
      >
        Авторизация через ВК
      </Button>
    </Column>
  );
};

AuthUi.propTypes = {
  loginViaVk: func.isRequired,
  handlePhoneNumber: func.isRequired,
  handlePhoneCode: func.isRequired,
  submitPhoneCode: func.isRequired,
  phoneAuthOpen: bool.isRequired,
  phoneAuthStage: string.isRequired,
  phoneAuthError: string,
  phoneNumber: string,
  phoneCode: string,
  onCred: func.isRequired,
  source: string.isRequired,
};

export default AuthUi;
