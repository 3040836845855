import React from 'react';
import { useTheme } from '@emotion/react';
import ssmm from './components/ssmm';
import boss from './components/boss';

const components = { ssmm, boss };

const TabComponent = React.memo(({ type, tab, current, onChange, disabled: disabledProp }) => {
  const theme = useTheme();
  const { Tab } = components[type || theme.misc.tabsType];

  const { id, title, disabled } = tab;

  const active = id === current;
  const content = typeof title === 'string' ? <span>{title}</span> : title;

  return (
    <Tab active={active} disabled={disabled || disabledProp} onClick={e => onChange(id, e)}>
      {content}
    </Tab>
  );
});

export default TabComponent;
