import styled from '@emotion/styled';

const buttonSizes = {
  s: { height: 'l', padding: 'xl', paddingSpan: '0.5px' },
  m: { height: 'xl', padding: 'xl', paddingSpan: '1px' },
  l: { height: 'xxl', padding: 'xl', paddingSpan: '2px' },
};

const buttonColors = {
  default: {
    noState: {
      text: 'color12',
      border: 'color5',
      background: 'transparent',
      icon: 'color5',
    },
    hover: { text: 'color5', border: 'color7', background: 'transparent', icon: 'color7' },
    active: { text: 'color5', border: 'color7', background: 'transparent', icon: 'color7' },
  },
  blue: {
    noState: { text: 'white', border: 'color6', background: 'color6', icon: 'white' },
    hover: { text: 'white', border: 'color8', background: 'color8', icon: 'white' },
    active: { text: 'white', border: 'color8', background: 'color8', icon: 'white' },
  },
  green: {
    noState: { text: 'white', border: 'color9', background: 'color9', icon: 'white' },
    hover: { text: 'white', border: 'color10', background: 'color10', icon: 'white' },
    active: {
      text: 'white',
      border: 'color10',
      background: 'color10',
      icon: 'white',
    },
  },
};

const parseProps = ({ theme, ...styleProps }) => {
  const styleObj = {};

  Object.keys(styleProps).forEach(prop => {
    const value = styleProps[prop];

    switch (prop) {
      case 'justify':
        styleObj.justifyContent = value;
        break;

      case 'align':
        styleObj.alignItems = value;
        break;

      case 'margin':
        if (typeof value === 'string') {
          styleObj[prop] = theme.dimensions[value];
        } else if (typeof value === 'object') {
          styleObj[prop] = value
            .map(side => (side && `${theme.dimensions[side]}px`) || 0)
            .join(' ');
        }
        break;

      default:
        break;
    }
  });

  return styleObj;
};

const Button = styled('button')(({ theme, size, color, disabled, ...rest }) => ({
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  fontFamily: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  fontSize: `${theme.dimensions.s}px`,
  width: '100%',
  height: `${theme.dimensions[buttonSizes[size].height]}px`,
  lineHeight: 0, // `${theme.dimensions[buttonSizes[size].height] - 2}px`,
  padding: `0 ${theme.dimensions[buttonSizes[size].padding]}px`,
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: `${theme.dimensions[buttonSizes[size].height] / 2}px`,
  outline: 0,
  transition: '0.2s',
  cursor: 'pointer',
  boxSizing: 'border-box',
  whiteSpace: 'nowrap',
  borderColor: theme.colors[buttonColors[color].noState.border],
  backgroundColor: theme.colors[buttonColors[color].noState.background],
  '& > span': {
    paddingBottom: buttonSizes[size].paddingSpan,
    color: theme.colors[buttonColors[color].noState.text],
  },
  '& i': {
    marginRight: theme.dimensions.m,
    color: theme.colors[buttonColors[color].noState.icon],
  },
  '&:hover': {
    color: theme.colors[buttonColors[color].hover.text],
    borderColor: theme.colors[buttonColors[color].hover.border],
    backgroundColor: theme.colors[buttonColors[color].hover.background],
    '& i': { color: theme.colors[buttonColors[color].hover.icon] },
    '& > span': {
      color: theme.colors[buttonColors[color].hover.text],
    },
  },
  '&:active': {
    color: theme.colors[buttonColors[color].active.text],
    borderColor: theme.colors[buttonColors[color].active.border],
    backgroundColor: theme.colors[buttonColors[color].active.background],
    '& i': { color: theme.colors[buttonColors[color].active.icon] },
    '& > span': {
      color: theme.colors[buttonColors[color].active.text],
    },
  },
  ...(disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}),
  ...parseProps({ theme, ...rest }),
}));

Button.defaultProps = {
  size: 'm',
  color: 'default',
  disabled: false,
  justify: 'center',
  align: 'center',
};

export default Button;
