import React from 'react';
import { useTheme } from '@emotion/react';
import Tab from './tab';
import ssmm from './components/ssmm';
import boss from './components/boss';

const components = { ssmm, boss };

const Tabs = React.memo(({ type, tabs, current, onChange, disabled, ...rest }) => {
  const theme = useTheme();
  const { Container } = components[type || theme.misc.tabsType];

  const handleClick = (newTab, event) => {
    if (newTab === current) return;

    onChange(newTab, event);
  };

  return (
    <Container {...rest}>
      {tabs.map(tab => (
        <Tab key={tab.id} tab={tab} current={current} disabled={disabled} onChange={handleClick} />
      ))}
    </Container>
  );
});

Tabs.defaultProps = { tabs: [] };

export default Tabs;
