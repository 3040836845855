import styled from '@emotion/styled';
import { Modal as DefaultModal } from '@ssmm/ui';

import { colors } from '.';

const Modal = styled(DefaultModal)({
  '.ant-modal-body div': { display: 'flex' },
  '.ant-modal-body > div:first-of-type': {
    background: '#fff',
    padding: '20px 30px',
    borderBottom: `1px solid ${colors.grayLight}`,
    h2: {
      color: '#000',
      fontFamily: 'Open Sans',
      fontSize: 20,
      lineHeight: '27px',
      fontWeight: 600,
    },
  },
  '.ant-modal-close-x > div': { width: 67, height: 67 },
  '.ant-modal-close-x i': { fontSize: 20, color: colors.black, ':hover': { color: colors.blue } },
});

export default Modal;
