import Misc from '../../types/misc';
import colors from './colors';
import dimensions from './dimensions';

const misc: Misc = {
  borderRadius: 4,
  iconSet: 'ssmm',
  iconDefaultSize: dimensions.m,
  closeIcon: 'close',
  iconColor: 'secondary',
  infoIconColor: 'secondary',
  modalType: 'boss',
  switchType: 'boss',
  radioType: 'boss',
  tabsType: 'boss',
  scroll: {
    overflow: 'hidden auto',
    '::-webkit-scrollbar': { width: 9 },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 9,
      boxShadow: `inset 0 0 5px 5px`,
      color: colors.secondaryLighter,
      border: `solid 3px transparent`,
    },
    '::-webkit-scrollbar-track': { margin: dimensions.s },
  },
};

export default misc;
