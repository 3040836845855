import { Button, Icon, Info, Layout, Link, Row, Text } from '@ssmm/ui';
import { bool, func, number } from 'prop-types';
import React from 'react';

const PartnerBusinessVip = React.memo(({ active, price, onDetailsClick, onPurchaseClick }) => (
  <Row align="center" justify="space-between">
    <Layout align="center" marginRight="l">
      <Icon size={22} type="vipFilled" color="warning" marginRight="m" />
      <Text size="l" marginRight="s">
        Партнерский бизнес{' '}
        <Text size="l" color="warning">
          VIP
        </Text>
      </Text>
      <Info content="Партнёрский кабинет VIP имеет максимальные возможности и привилегии. Создавая команду, Вы сможете в кротчайшие сроки выйти на большие доходы и стать полноценным Партнёром компании." />
    </Layout>
    <Layout align="center" css={{ minHeight: 45 }}>
      {active ? (
        <Text size="l" weight={400} color="success">
          Активен
        </Text>
      ) : (
        <>
          <Layout width={200} marginRight="xxl" align="flex-end">
            <Text size="h1" color="secondaryDark" line={24}>
              {price}
              <Text size="h1" color="secondaryDark" line={24} font="SFUIText-Regular">
                <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
              </Text>
            </Text>
            <Text size="l" weight={400}>
              &nbsp;/&nbsp;единоразово
            </Text>
          </Layout>

          <Link onClick={onDetailsClick} marginRight="l">
            Подробнее
          </Link>
          <Button onClick={onPurchaseClick} width={104} type="dark">
            Купить
          </Button>
        </>
      )}
    </Layout>
  </Row>
));

PartnerBusinessVip.propTypes = {
  active: bool.isRequired,
  price: number.isRequired,
  onDetailsClick: func.isRequired,
  onPurchaseClick: func.isRequired,
};

export default PartnerBusinessVip;
