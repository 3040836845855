const docs = {
  privacyPolicy: { ref: 'docu/PrivacyPolicyVkBoss.pdf', title: 'Политика конфиденциальности' },
  partnerProgramAgreement: {
    ref: 'docu/PartnerProgramAgreementVkBoss.pdf',
    title: 'Договор на партнерскую программу',
  },
  offer: { ref: 'docu/Offer.pdf', title: 'Оферта' },
  howToMakeMoney: { ref: 'docu/HowToMakeMoney.pdf', title: 'Как с нами зарабатывать?' },
};

export default docs;
