import React from 'react';
import styled from '@emotion/styled';
import { Column, Row } from '../layout';
import Button from '../button';
import Icon from '../icon';
import { Text } from '../text';
import message from '../message';
import { Upload } from 'antd';
import { commonParser } from '../helpers';

const { Dragger } = Upload;

const Wrap = styled(Row)(({ theme, ...rest }) => ({
  '&>span': { width: '100%' },
  '.ant-upload-btn': {
    padding: 0,
  },
  '.ant-upload-drag': {
    borderRadius: 4,
    background: 'none',
    borderColor: theme.colors.secondary,
    ':hover': { borderColor: theme.colors.primary },
  },
  '.ant-upload-disabled.ant-upload-drag': {
    borderColor: theme.colors.muted,
    ':hover': { borderColor: theme.colors.muted },
  },
  ...commonParser({ theme, ...rest }),
}));

const DnDUploader = React.memo(({ extList, typeList, onUpload, multiple, disabled, ...rest }) => {
  const verifyFile = file => {
    if (!typeList.includes(file.type)) {
      message.warning(`Выберите файл в поддерживаемом формате: ${extList.join(' ')}`);
      return false;
    }

    return true;
  };

  const props = {
    accept: extList.join(', '),
    beforeUpload: file => {
      if (typeList.length && !verifyFile(file)) {
        return false;
      }

      onUpload(file);

      return false;
    },
    fileList: null,
    showUploadList: false,
    disabled,
    multiple,
    ...rest,
  };

  return (
    <Wrap {...rest}>
      <Dragger {...props}>
        <Column align="center" padding="l" css={{ minHeight: 140 }}>
          <Icon type="upload" size="l" color={disabled ? 'muted' : 'default'} />
          <Text
            align="center"
            size="s"
            color={disabled ? 'muted' : 'secondaryDarker'}
            css={theme => ({ marginBottom: theme.dimensions.xs })}
          >
            {`Перетяни ${multiple ? 'файлы' : 'файл'} в эту область`}
            <br />
            или
          </Text>
          <Button type="light" disabled={disabled}>
            Загрузить
          </Button>
          <Text size="s" color={disabled ? 'muted' : 'default'}>{`Доступные форматы: ${extList.join(
            ' '
          )}`}</Text>
        </Column>
      </Dragger>
    </Wrap>
  );
});

DnDUploader.defaultProps = {
  extList: [],
  typeList: [],
  multiple: false,
};

export default DnDUploader;
