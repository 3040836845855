import commonColors from '../../common/colors';

const colors = {
  danger: '#DC5858', // с этими
  success: '#63C67A', // все
  warning: '#F7B500', // понятно
  dangerLight: '#FDF6F6', // с этими
  successLight: '#F7FCF8', // все
  warningLight: '#FEFBF2', // понятно
  dangerDark: '#721C24', // с этими
  successDark: '#155724', // все
  warningDark: '#856404', // понятно

  primary: '#2862CB', // это синий - все кнопки и иконки
  primaryLighter: '#f7fafe',
  primaryLight: '#ffffff',
  secondaryDark: '#353E4A', // это темный - шапки заголовки
  secondaryDarker: '#535B65', // это не знаю что
  secondary: '#7E8A95', // это серый - текст
  secondaryLighter: 'rgba(126, 138, 149, 0.25)', // это светло-серый
  secondaryLight: '#FAFAFA', // это очень светлый серый - bg/tr
  muted: '#BFC4CA', // это светло-серый - disabled
  accent: '#F6F9FD', // это hover таблицы

  ...commonColors,
};

export default colors;
