/** @jsxImportSource @emotion/react */
import { useTheme } from '@ssmm/theme';
import { Button, Column, Icon, Layout, Text } from '@ssmm/ui';
import moment from 'moment';
import mdf from 'moment-duration-format';
import { func, number } from 'prop-types';
import React from 'react';

import pic2 from '../../images/exclamation-message.svg';
import pic1 from '../../images/new-user.png';
import Title from './title';

mdf(moment);
moment.locale('ru');

const NewUserPromo = React.memo(({ timeLeft, onDetailsClick }) => {
  const theme = useTheme();

  return (
    <Column
      justify="space-between"
      align="center"
      grow={1}
      shrink={1}
      padding={['l', 'l', 'xl', 'l']}
      css={{
        filter: `drop-shadow(0px 3px 9px rgba(0, 0, 0, 0.15))`,
        borderRadius: theme.misc.borderRadius,
        zIndex: 2,
        backgroundImage: `url(${pic1})`,
        backgroundSize: 'cover',
        backgroundPosition: 'top center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Title />

      <Column align="center">
        <Icon type="vkboss" marginBottom="s" size={98} color="primaryLight" />

        <Layout
          direction="column"
          marginBottom="l"
          padding={['s', 'xxxl']}
          align="center"
          justify="center"
          css={{
            border: `1px solid ${theme.colors.primaryLight}`,
            borderRadius: 20,
          }}
        >
          <Layout align="center" justify="center">
            <img src={pic2} css={{ marginRight: theme.dimensions.l }} />
            <Text color="primaryLight" size={35} line={44} weight={600}>
              {moment.duration(timeLeft, 'milliseconds').format('hh:mm:ss', { trim: true })}
            </Text>
          </Layout>
          <Text color="primaryLight">Ограниченное предложение</Text>
        </Layout>

        {onDetailsClick && (
          <Button
            width="100%"
            type="light"
            css={{ maxWidth: 364, span: { color: theme.colors.primary } }}
            onClick={onDetailsClick}
          >
            Смотреть подробнее
          </Button>
        )}
      </Column>
    </Column>
  );
});

NewUserPromo.propTypes = {
  timeLeft: number.isRequired,
  onDetailsClick: func,
};

export default NewUserPromo;
