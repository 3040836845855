import { connect } from 'react-redux';

import * as authActions from '../actions';
import Authorization from '../component';

const mapStateToProps = state => ({
  newUser: state.authUi.newUser,
  authLoading: state.loaders.auth,
});

export default connect(mapStateToProps, authActions)(Authorization);
