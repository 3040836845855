import './index.css';

import { func } from 'prop-types';
import React from 'react';

const Footer = React.memo(({ pdfModalOpen }) => (
  <footer className="footer">
    <span className="span-link" onClick={() => pdfModalOpen('privacyPolicy')}>
      Политика конфиденциальности
    </span>{' '}
    |{' '}
    <span className="span-link" onClick={() => pdfModalOpen('offer')}>
      Оферта
    </span>{' '}
    |{' '}
    <a
      target="_blank"
      rel="noopener noreferrer"
      title="Техническая поддержка"
      href="//vk.com/vsemsmm1"
    >
      Техническая&nbsp;поддержка
    </a>
  </footer>
));

Footer.propTypes = {
  pdfModalOpen: func.isRequired,
};

export default Footer;
