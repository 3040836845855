import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';

import { message } from '@ssmm/ui';
import pic from 'components/LK/images/logo-ssmm.svg';
import firebase from 'firebase/compat/app';
import { createClickAndDestroyLink } from 'redux/actions';
import store from 'store';

import { loadSettings } from '../../../actions/dbActions';
import { openPdfModal } from '../../pdf-modal/actions';

export const onLoad = () => (dispatch, getState) => {
  if (!getState().settings.tariffSettings || !getState().settings.globalSettings) {
    dispatch(loadSettings());
  }

  dispatch(ifSpecialOfferAvailable());
};

export const togglePurchase = () => ({ type: 'PURCHASE_TOGGLE' });

export const ifSpecialOfferAvailable = () => (dispatch, getState) => {
  /* const { firstRunDate } = getState().user;
  const { forceSpecialOffer } = getState().settings.globalSettings;

  const timePassed = Date.now() - firstRunDate;
  const specialOfferAvailable = forceSpecialOffer || timePassed < 432000000; */

  dispatch({ type: 'PURCHASE_SET_SPECIAL_OFFER_AVAILABLE', specialOfferAvailable: false });
};

export const calclulateIfNewUserOfferAvailable = () => (dispatch, getState) => {
  const { user } = getState();

  if (!user) return 0;

  const date = user.firstRunDate || user.dateCreate;

  const timePassed = Date.now() - date;
  let newUserOfferAvailable = 432000000 - timePassed;

  if (newUserOfferAvailable < 0) newUserOfferAvailable = 0;

  return newUserOfferAvailable;
};

export { createClickAndDestroyLink, openPdfModal };

export const getParentInfo = async () => {
  const { auth_id_owner, partner_owner_id } = store.getState().user;

  if (auth_id_owner === 'supersmm') {
    const obj = {
      name: 'Super-SMM',
      link: 'https://super-smm.com',
      avatar: pic,
    };

    return obj;
  }

  const parentProfile = await firebase
    .database()
    .ref(`users/${auth_id_owner}/PROFILE`)
    .once('value')
    .then(result => result.val());

  const obj = {
    name: parentProfile.name || partner_owner_id,
    avatar: parentProfile.photo,
    link: `https://vk.com/id${partner_owner_id}`,
    vkId: partner_owner_id,
  };

  return obj;
};

export const getProfile = async () => {
  const { uid } = store.getState().user;

  const profile = await firebase
    .database()
    .ref(`users/${uid}/PROFILE`)
    .once('value')
    .then(snapshot => snapshot.val());

  return profile;
};

export const getUserTariffSettings = () => {
  const { user } = store.getState();

  return user;
};

export const getPartnerProgramInfo = async () => {
  const { uid } = store.getState().user;

  const [total_spent, hasRecruits] = await Promise.all([
    firebase
      .database()
      .ref(`BALANCE/${uid}/total_spent`)
      .once('value')
      .then(result => result.val() || 0),
    firebase
      .database()
      .ref(`BALANCE/${uid}/RECRUITS/line_1`)
      .limitToFirst(1)
      .once('value')
      .then(result => result.exists()),
  ]);

  return { total_spent, hasRecruits };
};

export const getPartnerInfo = async refId => {
  const fetchUserByRefId = firebase.functions().httpsCallable('fetchUserByRefId');

  const result = await fetchUserByRefId(+refId);

  const { data } = result;

  if (!data?.hasOwnProperty('name')) {
    throw new Error('Пользователь не найден');
  }

  const user = {
    name: data.name || refId,
    avatar: data.avatar,
    link: `https://vk.com/id${refId}`,
    vkId: refId,
  };

  return user;
};

export const chargeUser =
  ({ items, autoContinue, newMentor, cb }) =>
  async dispatch => {
    const hide = message.loading('Обработка заказа...', 0);

    try {
      const { orderId } = await createOrder(items);

      dispatch(
        createClickAndDestroyLink(`https://super-smm.com/payment-yandex?orderId=${orderId}`)
      );

      if (newMentor) {
        await changeMentor(newMentor);
      }

      /* if (
      typeof autoContinue === 'boolean' &&
      this.state.userProfile.vkBossAutoContinue !== autoContinue
    ) {
      this.updateUserProfile({ vkBossAutoContinue: autoContinue });
    } */

      if (cb) cb();
    } catch (e) {
      console.info(e);

      message.error(e.message);
    } finally {
      hide();
    }
  };

export const chargeUserOld =
  ({ fromBalance, description, amount, data, autoContinue, baseUrl }) =>
  async dispatch => {
    const hide = message.loading('Обработка заказа...', 0);
    const { uid } = store.getState().user;

    try {
      if (fromBalance) {
        const { balanceTotal } = store.getState().user.balance;

        if (balanceTotal < amount) {
          throw new Error('Недостаточно средств на счете');
        }

        await purchaseFromBalance(data);

        message.success('Оплата произведена успешно');
      } else {
        const url = new URL(baseUrl || `https://super-smm.com/paymentVkBossYandexRedirect`);

        url.searchParams.append('uid', uid);
        url.searchParams.append('description', encodeURIComponent(description));
        url.searchParams.append('amount', amount);

        Object.keys(data).forEach(k => url.searchParams.append(k, data[k]));

        dispatch(createClickAndDestroyLink(url.toString()));
      }

      /* if (
      typeof autoContinue === 'boolean' &&
      this.state.userProfile.vkBossAutoContinue !== autoContinue
    ) {
      this.updateUserProfile({ vkBossAutoContinue: autoContinue });
    } */
    } catch (e) {
      console.info(e);

      message.error(e.message);
    } finally {
      hide();
    }
  };

const changeMentor = async refId => {
  try {
    const ticket = {
      category_id: 'support',
      id: null,
      author_authId: null,
      title: 'Заявка на смену ментора',
      description: `Новый реферальный код: ${refId}`,
      status_id: 'new',
      watch_count: 0,
      pinned: false,
      source: 'extension',
      access_level_read: 4,
      fileList: [],
    };

    await submitNewTicket(ticket);

    /* const changePartner = this.ff.httpsCallable('changePartner');
    await changePartner({ partner: +refId }); */
  } catch (e) {
    console.info(e.message);
  }
};

const submitNewTicket = async ticket => {
  const { uid } = store.getState().user;

  ticket.description = newLineReplace(ticket.description);

  ticket.id = await firebase
    .firestore()
    .collection('forumTickets')
    .orderBy('id', 'desc')
    .limit(1)
    .get()
    .then(query => {
      let id = 1;
      if (query.size) {
        query.forEach(doc => {
          id = doc.data().id + 1;
        });
      }
      return id;
    });

  ticket.author_authId = uid;

  await firebase
    .firestore()
    .collection('forumTickets')
    .doc(`${ticket.id}`)
    .set({
      ...ticket,
      date_create: firebase.firestore.FieldValue.serverTimestamp(),
      last_comment_date_create: firebase.firestore.FieldValue.serverTimestamp(),
    });

  const notice = {
    date: firebase.database.ServerValue.TIMESTAMP,
    type: 'notice',
    title: `Создан новый тикет (${ticket.title.split(' ')[0]})`,
    text: `<p>Тема: <b>${ticket.title}</b></p><p><a class='vkboss-feedback-notification__body-forum-link' target='_blank' href='https://super-smm.com/forum/tickets/${ticket.id}'>Ссылка</a></p>`,
  };

  firebase.database().ref('/modules/oMpMQuBJnia6qXeC4tjabLl1X2K2/feedback/notices').push(notice);
};

const newLineReplace = text => {
  let formattedText = text.replace(/(\r|\n|\r\n)/g, '</p><p>');
  if (formattedText.indexOf('</p><p>') > -1) {
    formattedText = `<p>${formattedText}</p>`;
  }

  // Совсем пустые строки не имеют высоты
  formattedText = formattedText.replace(/<p><\/p>/g, '<p>&nbsp;</p>');

  return formattedText;
};

const createOrder = async items => {
  const createOrder = firebase.functions().httpsCallable('createOrder');
  const result = await createOrder(items);

  const { data, error } = result;

  if (error) {
    throw error;
  } else if (data) {
    return data;
  } else {
    throw new Error('Неизвестная ошибка');
  }
};

const purchaseFromBalance = async params => {
  params.uid = store.getState().user.uid;

  const vkBossPurchaseFromBalance = firebase.functions().httpsCallable('vkBossPurchaseFromBalance');
  const result = await vkBossPurchaseFromBalance(params);

  const { data } = result;

  if (data.status && data.status === 'success') {
  } else {
    throw new Error(data.error?.message || 'Неизвестная ошибка');
  }
};
