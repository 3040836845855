import styled from '@emotion/styled';
import React from 'react';
import { Layout, Row } from '../layout';
import Icon from '../icon';
import { Text } from '../text';

const HeaderElement = styled(Row)(({ theme }) => ({
  position: 'relative',
  background: theme.colors.primaryLight,
  padding: `${theme.dimensions.s}px ${theme.dimensions.l}px`,
  borderBottom: `1px solid ${theme.colors.secondaryLighter}`,
  borderRadius: '4px 4px 0px 0px',
  minHeight: 54,
  alignItems: 'center',
}));

const CloseBtn = React.memo(() => (
  <Layout
    justify="center"
    align="center"
    css={{
      position: 'absolute',
      height: 36,
      maxHeight: 36,
      top: -36,
      right: 0,
      cursor: 'pointer',
      opacity: 0.9,
      ':hover': {
        opacity: 1,
      },
    }}
  >
    <Text marginRight="m" size="xl" color="primaryLight">
      закрыть
    </Text>
    <Icon
      type="closeCircleFilled"
      size={22}
      css={theme => ({
        color: theme.colors.primaryLight,
      })}
    />
  </Layout>
));

export default { HeaderElement, CloseBtn };
