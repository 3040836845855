import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Layout } from '../layout';
import RadioDot from './dot';
import RadioButton from './button';
import { commonParser } from '../helpers';

const Wrap = styled(Layout)(({ theme, direction = 'horizontal', ...rest }) => {
  const commonProps = {
    'label.radioButton:first-of-type > div': {
      borderRadius: `4px 0 0 4px`,
      borderLeftWidth: 1,
      boxShadow: 'none',
    },
    'label.radioButton:last-of-type > div': { borderRadius: `0 4px 4px 0` },
    'label.radioButton:only-child > div': {
      borderRadius: 4,
    },
  };

  const alignmentProps =
    direction === 'horizontal'
      ? {
          alignItems: 'stretch',
          'label.radioDot:nth-of-type(n+2)': { marginLeft: theme.dimensions.m },
        }
      : {
          flexDirection: 'column',
          'label.radioDot:nth-of-type(n+2)': { marginTop: theme.dimensions.m },
        };

  return {
    ...commonProps,
    ...alignmentProps,
    ...commonParser({ theme, ...rest }),
  };
});

const RadioGroup = React.memo(
  ({ type: typeProp, value, children, onChange, disabled, size = 'm', ...rest }) => {
    const theme = useTheme();
    const type = typeProp || theme.misc.switchType;

    const handleChange = newVal => {
      if (!onChange || newVal === value) return;

      onChange(newVal);
    };

    return (
      <Wrap {...rest}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            onChange: handleChange,
            type: child.props.type || type,
            checked: child.props.value === value,
            disabled: disabled === undefined ? child.props.disabled : disabled,
            size,
          })
        )}
      </Wrap>
    );
  }
);

export default { RadioGroup, RadioDot, RadioButton };
