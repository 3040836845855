import React from 'react';
import styled from '@emotion/styled';
import { Text } from '../text';
import Icon from '../icon';
import { commonParser } from '../helpers';

const buttonSizes = {
  m: {
    padding: 'm',
    iconMargin: 5,
    textSize: 'm',
    iconSize: 's',
    fontWeight: 600,
    minHeight: 50,
  },
};

const ButtonElement = styled('button')(({ theme, size, width, ...rest }) => ({
  width,
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  justifyContent: 'center',
  alignItems: 'center',
  minWidth: buttonSizes[size].minWidth,
  minHeight: buttonSizes[size].minHeight,
  padding: theme.dimensions[buttonSizes[size].padding],
  borderStyle: 'dashed',
  borderWidth: 1,
  borderColor: theme.colors.secondary,
  borderRadius: theme.misc.borderRadiusButton,
  outline: 0,
  transition: '0.2s',
  cursor: 'pointer',
  boxSizing: 'border-box',
  background: theme.colors.primaryLight,
  '> span': {
    color: theme.colors.secondary,
  },
  i: {
    marginRight: buttonSizes[size].iconMargin,
    color: theme.colors.secondary,
  },
  ':hover': {
    borderColor: theme.colors.primary,
    '> span, > i': {
      color: theme.colors.primary,
    },
  },
  ':disabled': {
    borderColor: theme.colors.muted,
    pointerEvents: 'none',
    '> span, > i': {
      color: theme.colors.muted,
    },
  },
  ...commonParser({ theme, ...rest }),
}));

const ButtonDashed = React.memo(
  ({ icon, iconSize, children, size, type, disabled, width, ...rest }) => {
    const content = [];

    if (icon) {
      content.push(<Icon key="icon" type={icon} size={iconSize || buttonSizes[size].iconSize} />);
    }

    content.push(
      <Text key="text" weight={buttonSizes[size].fontWeight}>
        {children}
      </Text>
    );

    return (
      <ButtonElement size={size} disabled={disabled} {...rest}>
        {content}
      </ButtonElement>
    );
  }
);

ButtonDashed.defaultProps = {
  size: 'm',
  width: '100%',
  disabled: false,
};

export default ButtonDashed;
