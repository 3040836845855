import React from 'react';
import Icon from '../icon';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import { Layout, ComponentProps as LayoutProps } from '../layout';

const rotation = keyframes`
100% { transform:rotate(360deg)}
`;

interface ElementProps {
  spinning: boolean;
  size: number;
}

const Wrap = styled(Layout)<ElementProps>(({ spinning, size }) => ({
  position: 'relative',
  minHeight: size + 10,
  '>*': { pointerEvents: spinning ? 'none' : 'all' },
}));

const MaskContainer = styled(Layout)({
  top: 0,
  left: 0,
  position: 'absolute',
  width: '100%',
  height: '100%',
  zIndex: 1100,
});

const Mask = styled(Layout)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.colors.muted,
  opacity: 0.3,
  position: 'absolute',
}));

const Loader = styled(Icon)({
  zIndex: 1101,
  animation: `${rotation} 3s infinite`,
  animationTimingFunction: 'steps(60)',
});

interface Props extends LayoutProps {
  spinning?: boolean;
  children?: React.ReactNode;
  size?: number;
  mask?: boolean;
}

const Spin = React.memo<Props>(({ spinning = true, children, size = 40, mask = true, ...rest }) => (
  <Wrap spinning={spinning} size={size} {...rest}>
    {children}
    {spinning && (
      <>
        <MaskContainer align="center" justify="center">
          {mask && <Mask />}
          <Loader type="loader" set="ssmm" size={size} css={{ zIndex: 1101 }} />
        </MaskContainer>
      </>
    )}
  </Wrap>
));

export default Spin;
