import React from 'react';
import { Text } from '../text';
import styled from '@emotion/styled';
import { Layout, Row, Column } from '../layout';
import MenuSubItems from './menu-sub-items';

const Counter = styled(Layout)(({ theme }) => ({
  height: theme.dimensions.xl,
  minWidth: theme.dimensions.xl,
  padding: theme.dimensions.xs,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.misc.borderRadius,
  background: theme.colors.primary,
  ...theme.fonts.h3,
  color: theme.colors.primaryLight,
  marginLeft: theme.dimensions.s,
}));

const Wrap = styled(Column)(({ theme, active }) => {
  const styleObj = {
    padding: `0 ${theme.dimensions.s}px 0 ${theme.dimensions.xxl}px`,
    borderRightStyle: 'solid',
    borderRightWidth: 5,
    borderRightColor: 'transparent',
    '&: hover': {
      background: theme.colors.primaryLighter,
      borderRightColor: theme.colors.primary,
    },
  };

  if (active) {
    styleObj.borderRightColor = theme.colors.primary;
    styleObj.background = theme.colors.primaryLighter;
  }

  return styleObj;
});

const MenuItemElement = styled(Row)(({ theme, active, disabled }) => {
  const styleObj = {
    minHeight: 54,
    padding: `${theme.dimensions.m}px 0`,
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&: hover': {
      'span,i': { color: theme.colors.primary },
    },
  };

  if (active) {
    styleObj.pointerEvents = 'none';
    styleObj['span,i'] = { color: theme.colors.primary };
  }

  if (disabled) {
    styleObj.pointerEvents = 'none';
    styleObj['span,i'] = { color: theme.colors.secondary };
  }

  return styleObj;
});

const MenuItem = React.memo(({ item, current, onChange }) => {
  const { id, title, childItems, disabled } = item;

  const childrenCount = childItems?.length || 0;
  const activeItem = id === current;
  const activeChild = childrenCount > 0 && childItems.find(childItem => childItem.id === current);
  const active = activeItem || activeChild;

  const handleClick = () => {
    if (activeItem || disabled) return;

    onChange(id);
  };

  const content = typeof title === 'string' ? <Text size="h3">{title}</Text> : title;

  return (
    <Wrap active={active}>
      <MenuItemElement active={activeItem} disabled={disabled} onClick={handleClick}>
        {content}
        {childrenCount > 0 && <Counter>{childrenCount}</Counter>}
      </MenuItemElement>
      {active && childrenCount > 0 && (
        <MenuSubItems items={childItems} onChange={onChange} current={current} />
      )}
    </Wrap>
  );
});

export default MenuItem;
