import { Button, Icon, Info, Layout, Link, Row, Text } from '@ssmm/ui';
import moment from 'moment';
import { bool, func, number } from 'prop-types';
import React from 'react';

const PartnerBusiness = React.memo(
  ({ active, price, onDetailsClick, onPurchaseClick, disabled }) => (
    <Row
      align="center"
      justify="space-between"
      marginBottom="s"
      paddingBottom="s"
      css={theme => ({ borderBottom: `1px solid ${theme.colors.secondaryLighter}` })}
    >
      <Layout align="center" marginRight="l">
        <Icon size={22} type="briefcaseFilled" color="secondaryDark" marginRight="m" />
        <Text size="l" marginRight="s">
          Партнерский бизнес
        </Text>
        <Info content="Партнёрский кабинет Бизнес идеально подходит для построения большой команды с целью заработка денег. Вы сожмите зарабатывать не только на рекомендациях сервиса но и на продажи Бизнес пакетов." />
      </Layout>
      <Layout align="center" css={{ minHeight: 45 }}>
        {active ? (
          <>
            <Text size="l" weight={400} color="success" marginRight="l">
              {`до ${moment(active).format('DD.MM.YYYY')}`}
            </Text>
            <Button onClick={onPurchaseClick} type="success" width={104} disabled={disabled}>
              Продлить
            </Button>
          </>
        ) : (
          <>
            <Layout width={200} marginRight="xxl" align="flex-end">
              <Text size="h1" color="secondaryDark" line={24}>
                {price}
                <Text size="h1" color="secondaryDark" line={24} font="SFUIText-Regular">
                  <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
                </Text>
              </Text>
              <Text size="l" weight={400}>
                &nbsp;/&nbsp;год
              </Text>
            </Layout>
            <Link onClick={onDetailsClick} marginRight="l" disabled={disabled}>
              Подробнее
            </Link>
            <Button onClick={onPurchaseClick} width={104} type="dark" disabled={disabled}>
              Купить
            </Button>
          </>
        )}
      </Layout>
    </Row>
  )
);

PartnerBusiness.propTypes = {
  active: number.isRequired,
  price: number.isRequired,
  onDetailsClick: func.isRequired,
  onPurchaseClick: func.isRequired,
  disabled: bool,
};

export default PartnerBusiness;
