/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { Icon } from '@ssmm/ui';
import { colors, HeadingH3 } from 'components/landing/ui';
import { arrowRight } from 'components/landing/ui/icons';

const Arrow = styled('div')({
  width: 50,
  height: 50,
  borderRadius: '50%',
  display: 'flex',
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  borderStyle: 'solid',
  borderWidth: 1,
  cursor: 'pointer',
});

export const ArrowPrev = styled(Arrow)(({ disabled, isLast }) => ({
  i: { transform: 'rotate(180deg)', color: disabled ? colors.grayLight : isLast ? '#fff' : '#000' },
  borderColor: disabled ? colors.grayLight : '#000',
  backgroundColor: isLast ? '#000' : '#fff',
  pointerEvents: disabled ? 'none' : 'all',
  marginRight: 15,
  ':hover': {
    borderColor: colors.blue,
    backgroundColor: isLast ? colors.blue : '#fff',
    i: { color: isLast ? '#fff' : colors.blue },
  },
}));

const ArrowNext = styled(Arrow)(({ disabled }) => ({
  i: { color: disabled ? colors.grayLight : '#fff' },
  borderColor: disabled ? colors.grayLight : '#000',
  backgroundColor: disabled ? '#fff' : '#000',
  pointerEvents: disabled ? 'none' : 'all',
  ':hover': {
    borderColor: colors.blue,
    backgroundColor: colors.blue,
    i: { color: '#fff' },
  },
}));

const Controls = ({ title, current, total, onNext, onPrev }) => {
  const currentStr = current >= 10 ? current : `0${current}`;
  const totalStr = total >= 10 ? total : `0${total}`;

  return (
    <div
      css={{
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10,
        borderBottom: `2px solid ${colors.grayDark}`,
      }}
    >
      <HeadingH3>
        <span css={{ marginRight: 20 }}>{title}</span>
        <span css={{ whiteSpace: 'nowrap' }}>
          {currentStr}
          <span css={{ color: colors.grayLight }}>{` / ${totalStr}`}</span>
        </span>
      </HeadingH3>

      <div>
        <ArrowPrev onClick={onPrev} disabled={current === 1} isLast={current === total}>
          <Icon svg={arrowRight} />
        </ArrowPrev>
        <ArrowNext onClick={onNext} disabled={current === total}>
          <Icon svg={arrowRight} />
        </ArrowNext>
      </div>
    </div>
  );
};

export default Controls;
