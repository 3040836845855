import styled from '@emotion/styled';
import { commonParser } from '../helpers';
import { CommonStyleProps, Theme, CSSObject } from '../types';

interface ComponentProps extends Omit<CommonStyleProps, 'align'> {
  color?: string;
  weight?: number;
  align?: string;
  decoration?: string;
  size?: string | number;
  line?: string | number;
}

const parseProps = ({ theme, ...styleProps }: { theme: Theme }) => {
  const styleObj: CSSObject = {};

  Object.keys(styleProps).forEach(prop => {
    const value = styleProps[prop];

    switch (prop) {
      case 'color':
        styleObj.color = theme.colors[value] || value;
        break;

      case 'weight':
        styleObj.fontWeight = value;
        break;

      case 'align':
        styleObj.textAlign = value;
        break;

      case 'decoration':
        styleObj.textDecoration = value;
        break;

      case 'line':
        styleObj.lineHeight = typeof value === 'number' ? `${value}px` : value;
        break;

      case 'font':
        styleObj.fontFamily = value;
        break;

      default:
        break;
    }
  });

  return styleObj;
};

const Text = styled('span')<ComponentProps>(({ theme, size = 'm', ...rest }) => {
  let obj: CSSObject = {};

  if (theme.fonts[size]) {
    obj = theme.fonts[size];
  } else {
    obj = { ...theme.fonts.m, fontSize: size || 'initial' };
  }

  return { ...obj, ...commonParser({ theme, ...rest }), ...parseProps({ theme, ...rest }) };
});

export default Text;
