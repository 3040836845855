/** @jsxImportSource @emotion/react */
import Branch from 'components/landing/component/branch';
import { Container } from 'components/landing/ui';
import { bool } from 'prop-types';
import React, { useEffect, useState } from 'react';

import AttentionImg from '../../../assets/images/extinstall/attention.jpg';
import Authorization from '../../authorization/container';
import Header from './header';
import Install from './install';
import Menu from './menu';
import Tariffs from './tariffs';

let timer = null;

const LK = React.memo(
  ({
    onLoad,
    authState,
    logout,
    settings,
    user,
    calclulateIfNewUserOfferAvailable,
    openPdfModal,
    createClickAndDestroyLink,
    chargeUser,
    installPage,
    chargeUserOld,
  }) => {
    const [activeMenu, setActiveMenu] = useState();
    const [newUserOfferAvailable, setNewUserOfferAvailable] = useState(false);

    useEffect(() => {
      window.open('https://vk-boss-light.web.app/guide');
      onLoad();

      if (installPage) setActiveMenu('install');

      return () => {
        if (timer) {
          clearTimeout(timer);
        }
      };
    }, []);

    useEffect(() => {
      if (user && user.hasOwnProperty('firstRunDate')) {
        updateTimer();

        if (!activeMenu) {
          if (!user.firstRunDate) {
            setActiveMenu('install');
          } else {
            setActiveMenu('tariffs');
          }
        }
      }
    }, [user]);

    const updateTimer = () => {
      const available = calclulateIfNewUserOfferAvailable();

      if (available > 0) {
        setNewUserOfferAvailable(available);
        timer = setTimeout(updateTimer, 1000);
      } else {
        setNewUserOfferAvailable(false);
        timer = null;
      }
    };

    // if (authState === 'loggedOut') return <Authorization />;

    return (
      <div
        css={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          paddingTop: 83,
          background: '#615f5f',
          alignItem: 'center',
          justifyContent: 'center',
          '> img': {
            width: '360px',
            height: '320px',
            borderRadius: '8px',
          },
          '> span': {
            fontSize: '24px',
            color: '#FFFFFF',
            fontWeight: 600,
            width: 'max-content',
            marginBottom: '8px',
          },
        }}
      >
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            '> img': {
              width: '420px',
              height: '360px',
              borderRadius: '8px',
              objectFit: 'none',
            },
            '> span': {
              fontSize: '24px',
              color: '#FFFFFF',
              fontWeight: 600,
              width: 'max-content',
              marginBottom: '8px',
            },
          }}
        >
          <span>
            Покупка тарифов и услуг производится из магазина{' '}
            <strong
              onClick={() =>
                window.open(
                  'https://chrome.google.com/webstore/detail/vk-boss-30/dmcinkihbalbalneakclgkkjpceaaglo'
                )
              }
              css={{
                color: '#2787F5',
                cursor: 'pointer',
                textDecoration: 'underline',
                ':hover': {
                  opacity: 0.8,
                  transition: '.2s all',
                },
              }}
            >
              расширения
            </strong>
            .
          </span>
          <img src={AttentionImg} />
        </div>
      </div>
    );
  }
);

LK.propTypes = {
  purchaseOpen: bool.isRequired,
};

export default LK;
