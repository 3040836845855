import React from 'react';
import styled from '@emotion/styled';
import { Text } from '../text';
import { Layout } from '../layout';
import { RadioElement, LabelElement } from './elements';

const textSize = {
  m: { size: 'l', weight: 400 },
  l: { size: 'h3', weight: 600 },
  xl: { size: 'h3', weight: 600 },
};

const RadioButtonElement = styled(Layout)(({ theme, checked, disabled, size }) => {
  const styleObj = {
    width: '100%',
    minHeight: theme.input.sizes[size].height,
    alignItems: 'center',
    justifyContent: 'center',
    padding: `${theme.dimensions.xs}px ${theme.dimensions.m}px`,
    minWidth: 60,
    background: checked ? theme.colors.primaryLighter : null,
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 0,
    borderColor: theme.colors.secondary,
    boxShadow: checked ? `-0.65px 0 0 0 ${theme.colors.primary}` : null,
    ':hover': {
      boxShadow: `-0.65px 0 0 0 ${theme.colors.primary}`,
      borderColor: theme.colors.primary,
    },
  };

  if (disabled) {
    styleObj['i,span'] = { color: theme.colors.muted };
  } else if (checked) {
    styleObj['i,span'] = { color: theme.colors.primary };
  }

  return styleObj;
});

const RadioButton = React.memo(
  ({ type, children, size = 'm', value, disabled, checked, onChange, ...rest }) => {
    return (
      <LabelElement
        disabled={disabled}
        data-checked={checked}
        checked={checked}
        className="radioButton"
      >
        <RadioElement
          type="radio"
          disabled={disabled}
          onClick={() => onChange(value)}
          size={size}
          {...rest}
        />

        <RadioButtonElement checked={checked} size={size} disabled={disabled}>
          {typeof children === 'string' ? (
            <Text size={textSize[size].size} weight={textSize[size].weight}>
              {children}
            </Text>
          ) : (
            children
          )}
        </RadioButtonElement>
      </LabelElement>
    );
  }
);

RadioButton.defaultProps = {
  onChange: () => {},
};

export default RadioButton;
