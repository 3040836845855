import styled from '@emotion/styled';

import youtube from '../images/youtube.svg';
import { colors } from '.';

const YoutubeButtonElement = styled('img')({
  filter: `drop-shadow(-42px 39px 14px rgba(0, 0, 0, 0.4))`,
  cursor: 'pointer',
});

export const YoutubeButton = props => <YoutubeButtonElement src={youtube} {...props} />;

export const Divider = styled('div')({
  width: 'calc(100% + 1px)',
  height: 1,
  background: colors.grayLight,
});

export const BlueLine = styled('div')(({ width = 125, height = 10 }) => ({
  width,
  height,
  background: colors.blue,
  borderRadius: height / 2,
}));
