import 'firebase/functions';

import { message } from '@ssmm/ui';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { sendMessageExt } from 'redux/actions';
import {
  OPEN_REG,
  REG_CLOSE_REF,
  REG_OPEN_REF,
  REG_OWNER_INFO_REQ,
  REG_OWNER_INFO_RES,
  REG_TOGGLE_ACCEPT_NO_PARTNER,
  REG_TOGGLE_ACCEPT_PRIVACY,
  REG_TOGGLE_ACCEPT_REF,
  REG_UPDATE_SEARCH_STR,
} from 'redux/types';

export const registration = source => (dispatch, getState) => {
  const { ref, provider, socId } = getState().reg;

  if (source === 'ext') {
    sendMessageExt({ type: 'auth-register-request', state: { ref } });
  } else {
    let url = `/auth.html?type=reg&provider=${provider}&socId=${socId}`;

    const utm = localStorage.getItem('utm');
    const utmSource = localStorage.getItem('utm_source');

    if (ref) url += `&ref=${ref}`;

    if (utm) url += `&utm=${utm}`;

    if (utmSource) url += `&utm_source=${utmSource}`;

    window.open(url, 'auth-inboss', 'height=432,width=656');
  }
};

export const handleNewUser =
  ({ ref: refParam, provider, socId, avatar }) =>
  async dispatch => {
    const ref = refParam || localStorage.getItem('refId');

    const payload = {
      ref,
      refSearch: ref,
      provider,
      socId,
      avatar,
    };

    if (ref) {
      dispatch(fetchUserByRef(ref));

      payload.refFormOpen = true;
      payload.acceptNoPartner = false;
    }

    dispatch({ type: OPEN_REG, payload });
  };

export const updateSearchString = str => ({
  type: REG_UPDATE_SEARCH_STR,
  str,
});

export const handlePopoverVisibility = () => ({
  type: 'REG_POPOVER_VISIBILITY',
});

export const fetchUserByRef = ref => async (dispatch, getState) => {
  try {
    if (!ref) throw new Error('Код не передан');

    dispatch({ type: REG_OWNER_INFO_REQ });

    const { data } = await axios.get(
      `https://server.super-smm.com/api/vkboss/refferal/link/owner/${ref}`
    );

    if (!data) throw new Error('Пользователь не найден');

    dispatch({
      type: REG_OWNER_INFO_RES,
      res: {
        status: 'success',
        user: {
          name: data.user.PROFILE.name,
          last_name: data.user.PROFILE.surname,
          avatar: data.user.PROFILE.photo,
        },
      },
      ref,
    });
  } catch (e) {
    console.info(e);
    message.warning(e.message);
    dispatch({
      type: REG_OWNER_INFO_RES,
      res: { status: 'error', message: e.message },
      ref,
    });
  }
};

export const toggleAcceptRef = () => ({ type: REG_TOGGLE_ACCEPT_REF });

export const toggleAcceptPrivacy = () => ({ type: REG_TOGGLE_ACCEPT_PRIVACY });

export const openRef = () => ({ type: REG_OPEN_REF });

export const closeRef = () => ({ type: REG_CLOSE_REF });

export const toggleAcceptNoPartner = () => (dispatch, getState) => {
  const { acceptNoPartner, refOpen } = getState().reg;

  if (!acceptNoPartner && refOpen) {
    closeRef();
  }

  dispatch({ type: REG_TOGGLE_ACCEPT_NO_PARTNER });
};
