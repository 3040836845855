import { Info, InputNumber, Layout, Row } from '@ssmm/ui';
import { array, func, string } from 'prop-types';
import React from 'react';

const Settings = React.memo(({ programLevel, params, handleInput }) => (
  <Layout
    direction="column"
    padding="l"
    css={theme => ({
      border: `1px dashed ${theme.colors.secondary}`,
      borderRadius: theme.misc.borderRadius,
      '>div': { marginBottom: theme.dimensions.l, alignItems: 'flex-end' },
      '>div >div:nth-of-type(1)': { flexGrow: 1, flexShrink: 1, marginRight: theme.dimensions.s },
      '>div >div:nth-of-type(2)': { marginBottom: 8 },
    })}
    basis={253}
  >
    <Row>
      <Layout>
        <InputNumber
          value={params[0]}
          max={1000000}
          min={1}
          onChange={val => handleInput(val, 0)}
          suffix=" чел."
        >
          Количество партнеров
        </InputNumber>
      </Layout>
      <Info content="Укажите количество партнеров, которые регистрируются по вашему личному приглашению. Можно указать примерное число ожидаемых партнёров за месяц или сразу за год." />
    </Row>

    <Row>
      <Layout>
        <InputNumber
          value={params[1]}
          max={1000000}
          min={1}
          onChange={val => handleInput(val, 1)}
          suffix=" чел."
        >
          Средние количество приглашённых у каждого партнёра
        </InputNumber>
      </Layout>
      <Info content="Укажите сколько в среднем у всех приглашенных будет регистрастраций. Кто-то пригласит 100, а кто-то ни одного. Статистика показывает, что каждый клиент приводит, как минимум одного друга. Если Вы регистрируете активных партнёров, то смело ставьте число больше 3." />
    </Row>

    {(programLevel === 'business' || programLevel === 'businessVip') && (
      <>
        <Row>
          <Layout>
            <InputNumber
              value={params[2] * 100}
              max={100}
              min={1}
              onChange={val => handleInput(Math.round((val / 100) * 100) / 100, 2)}
              suffix=" %"
            >
              % партнёров кто покупает Бизнес
            </InputNumber>
          </Layout>
          <Info content="Укажите примерный процент, кто из всех людей в вашей структуре купит услугу “Партнерский бизнес”. Вы можете ориентироваться на вашу первую линию, скольким из них Вы успешно предложите строить бизнес вместе с Вами. В среднем мы рассчитываем, что этот процент будет от 5%-30%." />
        </Row>

        {programLevel === 'businessVip' && (
          <Row>
            <Layout>
              <InputNumber
                value={params[3] * 100}
                max={100}
                min={1}
                onChange={val => handleInput(Math.round((val / 100) * 100) / 100, 3)}
                suffix=" %"
              >
                % партнёров кто покупает VIP Бизнес
              </InputNumber>
            </Layout>
            <Info content="Укажите примерный процент, кто из всех людей в вашей структуре купит услугу “Партнерский бизнес VIP”. Вы можете ориентироваться на вашу первую линию, скольким из них Вы успешно предложите строить бизнес вместе с Вами. В среднем мы рассчитываем, что этот процент будет от 1%-20%." />
          </Row>
        )}
      </>
    )}
  </Layout>
));

Settings.propTypes = {
  programLevel: string.isRequired,
  params: array.isRequired,
  handleInput: func.isRequired,
};

export default Settings;
