import { Modal } from '@ssmm/ui';
import { func, string } from 'prop-types';
import React from 'react';

const PdfModal = React.memo(({ closePdfModal, pdfModalTitle, pdfModalUrl, pdfModalOpen }) => {
  if (!pdfModalOpen) return null;

  return (
    <Modal
      title={pdfModalTitle}
      onCancel={closePdfModal}
      getContainer={() => document.getElementById('root')}
      width={1000}
      css={{ iframe: { height: 'calc(90vh - 150px)' } }}
    >
      <iframe src={pdfModalUrl} title={pdfModalTitle} />
    </Modal>
  );
});

PdfModal.propTypes = {
  closePdfModal: func,
  pdfModalTitle: string,
  pdfModalUrl: string,
};

export default PdfModal;
