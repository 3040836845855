import { useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { IconSet, Size } from '@ssmm/theme/dist/types/common';
import React from 'react';

import { commonParser } from '../helpers';
import { CommonStyleProps, CSSObject } from '../types';
import Icons, { BossIcons, SsmmIcons, StorysendIcons } from './icons';
import { IconType } from './types';

export interface ComponentProps extends CommonStyleProps {
  set?: IconSet;
  title?: string;
  color?: string;
  disabled?: boolean;
  size?: Size;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

export interface ComponentPropsWithType {
  type: IconType;
  svg?: never;
}

export interface ComponentPropsWithCustomSvg {
  svg: string;
  type?: never;
}

interface ElementProps {
  size?: Size;
  disabled?: boolean;
  color: string;
}

type ComponentPropsType = ComponentProps & (ComponentPropsWithType | ComponentPropsWithCustomSvg);

const Element = styled('i')<ElementProps>(({ theme, size, color, disabled, ...rest }) => {
  const iconSize = size || theme.misc.iconDefaultSize;

  let styleObj: CSSObject = {
    fontSize: theme.dimensions[iconSize] || iconSize,
    lineHeight: 0,
    display: 'flex',
    alignItems: 'center',
    color: theme.colors[color] || color,
    boxSizing: 'border-box',
    ...commonParser({ theme, ...rest }),
  };

  if (disabled) {
    styleObj = {
      ...styleObj,
      color: theme.colors.muted,
      pointerEvents: 'none',
    };
  }

  return styleObj;
});

const Icon = React.memo<ComponentPropsType>(({ type, set, title, color, svg, ...props }) => {
  const theme = useTheme();

  const iconCollection: BossIcons | SsmmIcons | StorysendIcons = Icons[set || theme.misc.iconSet];
  const icon: string = iconCollection[type];

  return (
    <Element
      title={title}
      color={color || theme.misc.iconColor}
      {...props}
      dangerouslySetInnerHTML={{ __html: svg || icon }}
    />
  );
});

export default Icon;
