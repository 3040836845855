import styled from '@emotion/styled';
import { Global, useTheme } from '@emotion/react';

import React, { useState, useEffect } from 'react';
import { Modal as AntdModal } from 'antd';
import { HeadingH2 } from '../text';
import ssmm from './ssmm';
import boss from './boss';
import { commonParser } from '../helpers';
import { /* Modal, */ ModalProps } from 'antd/lib/modal';
import { CSSObject } from 'types';

type ComponentId = 'ssmm' | 'boss';

const components = {
  ssmm,
  boss,
};

const minOffset = 40;

interface ElementProps extends ModalProps {
  level: number;
}

const Element = styled(AntdModal)<ElementProps>(({ theme, level, ...rest }) => {
  const offset = (level - 1) * 40;

  return {
    top: `calc(3vh + ${minOffset + offset}px)`,
    padding: 0,
    '& .ant-modal-body': {
      maxHeight: `calc(95vh - ${minOffset + offset}px)`,
      overflow: 'hidden',
      background: theme.colors.primaryLight,
      padding: 0,
      borderRadius: '4px',
      display: 'flex',
      flexDirection: 'column',
    },
    '.ant-modal-close-x': { height: 0, width: 0, lineHeight: 0 },
    ...commonParser({ theme, ...rest }),
  };
});

interface HeaderProps {
  title: string | React.ReactNode;
  type: ComponentId;
}

const Header = React.memo<HeaderProps>(({ title, type }) => {
  const { HeaderElement } = components[type];

  return (
    <HeaderElement>
      {typeof title === 'string' ? <HeadingH2>{title}</HeadingH2> : title}
    </HeaderElement>
  );
});

interface Props extends ModalProps {
  type?: ComponentId;
  wrapStyle?: CSSObject;
  wrapClassName?: string;
  level?: number;
  onClose?: ModalProps['onCancel'];
  children?: React.ReactNode;
}

const Modal = React.memo<Props>(
  ({
    type: typeProp,
    title,
    onClose,
    children,
    wrapStyle,
    wrapClassName,
    level = 1,
    zIndex: zIndexProp,
    visible = true,
    destroyOnClose = true,
    footer = null,
    ...rest
  }) => {
    const theme = useTheme();
    const [zIndex, setZIndex] = useState(zIndexProp);

    useEffect(() => {
      if (zIndexProp) return;

      const positions: Array<number> = [...document.querySelectorAll('.ant-modal-wrap')]
        .filter(modal => !!modal.getAttribute('style'))
        .map(modal => +modal.getAttribute('style').replace(/\D/gm, ''));

      const topModalZIndex = positions.reduce((acc, curr) => {
        if (curr > acc) {
          return curr;
        }

        return acc;
      }, 1001);

      setZIndex(topModalZIndex + 1);
    }, []);

    if (!zIndex) return null;

    const type = typeProp || theme.misc.modalType;

    const { CloseBtn } = components[type];

    if (wrapStyle && wrapClassName) {
      return (
        <Element
          title={null}
          onCancel={onClose}
          wrapClassName={wrapClassName}
          closeIcon={<CloseBtn />}
          level={level}
          zIndex={zIndex}
          visible={visible}
          footer={footer}
          destroyOnClose={destroyOnClose}
          {...rest}
        >
          <Global
            styles={{
              [`.${wrapClassName}`]: wrapStyle,
            }}
          />
          {!!title && <Header title={title} type={type} />}
          {children}
        </Element>
      );
    }

    return (
      <Element
        title={null}
        onCancel={onClose}
        zIndex={zIndex}
        closeIcon={<CloseBtn />}
        level={level}
        visible={visible}
        footer={footer}
        destroyOnClose={destroyOnClose}
        {...rest}
      >
        {!!title && <Header title={title} type={type} />}
        {children}
      </Element>
    );
  }
);

export default Modal;
