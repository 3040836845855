import fonts from './fonts';

const sizes = {
  s: {
    minWidth: 0,
    padding: '4.5px 15px 5.5px 15px',
    iconMargin: 6,
    iconSize: '17px',
    minHeight: 32,
    borderRadius: '20px',
    titleCss: { ...fonts.l, lineHeight: '20px', fontWeight: 600 },
  },
  m: {
    minWidth: 0,
    padding: '4.5px 15px 5.5px 15px',
    iconMargin: 6,
    iconSize: '17px',
    minHeight: 32,
    borderRadius: '20px',
    titleCss: { ...fonts.l, lineHeight: '20px', fontWeight: 600 },
  },
  l: {
    minWidth: 0,
    padding: '7.5px 20px 8.5px 20px',
    iconMargin: 6,
    iconSize: '17px',
    minHeight: 40,
    borderRadius: '20px',
    titleCss: { ...fonts.l, lineHeight: '15px', fontWeight: 600 },
  },
};

const colors = {
  default: {
    noState: {
      text: 'primaryLight',
      border: 'primary',
      background: 'primary',
      icon: 'primaryLight',
    },
    hover: {
      text: 'primaryLight',
      border: 'secondaryDark',
      background: 'secondaryDark',
      icon: 'primaryLight',
    },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  light: {
    noState: {
      text: 'secondaryDark',
      border: 'secondary',
      background: 'primaryLight',
      icon: 'secondary',
    },
    hover: { text: 'primary', border: 'primary', background: 'primaryLight', icon: 'primary' },
    disabled: {
      text: 'secondary',
      border: 'secondary',
      background: 'primaryLight',
      icon: 'secondary',
    },
  },
  dark: {
    noState: {
      text: 'primaryLight',
      border: 'secondaryDark',
      background: 'secondaryDark',
      icon: 'primaryLight',
    },
    hover: { text: 'primaryLight', border: 'primary', background: 'primary', icon: 'primaryLight' },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  lightContrast: {
    noState: {
      text: 'secondaryDark',
      border: 'primaryLight',
      background: 'primaryLight',
      icon: 'secondaryDark',
    },
    hover: {
      text: 'primary',
      border: 'primaryLight',
      background: 'primaryLight',
      icon: 'primary',
    },
    disabled: {
      text: 'muted',
      border: 'primaryLight',
      background: 'primaryLight',
      icon: 'muted',
    },
  },
  darkContrast: {
    noState: {
      text: 'primaryLight',
      border: 'secondaryDark',
      background: 'secondaryDark',
      icon: 'primaryLight',
    },
    hover: { text: 'primaryLight', border: 'primary', background: 'primary', icon: 'primaryLight' },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  danger: {
    noState: {
      text: 'primaryLight',
      border: 'danger',
      background: 'danger',
      icon: 'primaryLight',
    },
    hover: { text: 'primaryLight', border: 'primary', background: 'primary', icon: 'primaryLight' },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  warning: {
    noState: {
      text: 'primaryLight',
      border: 'warning',
      background: 'warning',
      icon: 'primaryLight',
    },
    hover: { text: 'primaryLight', border: 'primary', background: 'primary', icon: 'primaryLight' },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
  success: {
    noState: {
      text: 'primaryLight',
      border: 'success',
      background: 'success',
      icon: 'primaryLight',
    },
    hover: { text: 'primaryLight', border: 'primary', background: 'primary', icon: 'primaryLight' },
    disabled: { text: 'primaryLight', border: 'muted', background: 'muted', icon: 'primaryLight' },
  },
};

export default { sizes, colors };
