import React from 'react';
import { YMInitializer } from 'react-yandex-metrika';

const YandexMetrica = React.memo(() => (
  <YMInitializer
    accounts={[61437523]}
    options={{
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true,
    }}
    version="2"
  />
));

export default YandexMetrica;
