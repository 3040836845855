import { CheckboxType } from '../../types/checkbox';

const sizes = {
  m: {
    width: 18,
    checkFontSize: 11,
  },
};

const type: CheckboxType = 'boss';

export default { sizes, type };
