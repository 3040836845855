import { Button, Column, Icon, Layout, Row, Text } from '@ssmm/ui';
import { Avatar } from 'antd';
import { func, object } from 'prop-types';
import React from 'react';

const OwnerInfo = React.memo(({ ownerInfo, onCancel }) => {
  const { status, message, user } = ownerInfo;
  const { name, last_name, avatar, bonus } = user || {};

  return status === 'error' ? (
    <Column align="center" marginBottom="m">
      <Text color="danger" marginBottom="s">{`Ошибка: ${message}`}</Text>{' '}
      <Button type="light" onClick={onCancel}>
        Отмена
      </Button>
    </Column>
  ) : (
    <Column marginBottom="m">
      <Text marginBottom="xs" color="secondary" size={14}>
        Вас пригласил:
      </Text>
      <Row align="flex-end" justify="space-between">
        <Layout align="center">
          <Avatar src={avatar} size={50} icon="user" />
          <Text marginLeft="m" size="l">
            {name} {last_name}
          </Text>
        </Layout>

        <Button type="light" onClick={onCancel}>
          Отмена
        </Button>
      </Row>

      {bonus && bonus.length > 0 && (
        <Row
          marginTop="m"
          padding="s"
          align="center"
          css={theme => ({
            borderRadius: theme.misc.borderRadius,
            border: `1px solid ${theme.colors.primary}`,
            background: theme.colors.primaryLighter,
          })}
        >
          <Icon type="gift" color="primary" marginRight="s" />
          <Layout
            direction="column"
            grow={1}
            shrink={1}
            css={theme => ({ '>span:nth-of-type(n+2)': { marginTop: theme.dimensions.xs } })}
          >
            {bonus.map(text => (
              <Text size="l" color="primary">
                {text}
              </Text>
            ))}
          </Layout>
        </Row>
      )}
    </Column>
  );
});

OwnerInfo.propTypes = {
  onCancel: func.isRequired,
  ownerInfo: object.isRequired,
};

export default OwnerInfo;
