import styled from '@emotion/styled';
import React from 'react';
import { Text } from '../text';
import { Layout, Row } from '../layout';
import Icon from '../icon';
import { commonParser } from '../helpers';

const Element = styled('input')(({ theme, error, ...rest }) => ({
  width: '100%',
  fontFamily: theme.fonts.mainFont,
  fontSize: theme.fonts.m.fontSize,
  lineHeight: `${theme.input.sizes.m.height}px`,
  color: error ? theme.input.colors.error.text : theme.input.colors.noState.text,
  border: `1px solid ${
    error ? theme.input.colors.error.border : theme.input.colors.noState.border
  }`,
  background: error ? theme.input.colors.error.background : theme.input.colors.noState.background,
  borderRadius: theme.misc.borderRadius,
  outline: 0,
  height: theme.input.sizes.m.height,
  padding: `0 25px 0 ${theme.dimensions.m}px`,
  boxSizing: 'border-box',
  ':active, :focus, :hover': {
    borderColor: theme.input.colors.hover.border,
    background: theme.input.colors.hover.background,
    transition: '.3s',
  },
  ':disabled': {
    color: theme.input.colors.disabled.text,
    borderColor: theme.input.colors.disabled.border,
    background: theme.input.colors.disabled.background,
  },
  '::placeholder': {
    color: theme.input.colors.noState.placeholder,
  },
  ...commonParser({ theme, ...rest }),
}));

const Controls = ({ disabled, onUp, onDown, maxReached, minReached }) => (
  <Layout
    direction="column"
    css={theme => ({
      position: 'absolute',
      height: '100%',
      right: 0,
      top: 0,
      borderLeft: `1px solid ${theme.input.colors.noState.border}`,
      '>div': {
        marginLeft: -1,
        width: 20,
        i: {
          color: disabled ? theme.input.colors.disabled.border : theme.input.colors.noState.border,
        },
        pointerEvents: disabled ? 'none' : undefined,
      },
      '>div:nth-of-type(1)': maxReached
        ? {
            cursor: 'not-allowed',
          }
        : {
            cursor: 'pointer',
            '&:hover': { i: { color: theme.input.colors.hover.border } },
          },
      '>div:nth-of-type(2)': minReached
        ? {
            cursor: 'not-allowed',
          }
        : {
            cursor: 'pointer',
            '&:hover': { i: { color: theme.input.colors.hover.border } },
          },
    })}
  >
    <Layout
      css={theme => ({
        borderBottom: `1px solid ${theme.input.colors.noState.border}`,
      })}
      grow={1}
      align="center"
      justify="center"
      onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={e => {
        if (!maxReached) onUp(e);
      }}
    >
      <Icon set="ssmm" type="up" size={12} />
    </Layout>
    <Layout
      grow={1}
      align="center"
      justify="center"
      onMouseDown={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
        if (!minReached) onDown(e);
      }}
    >
      <Icon set="ssmm" type="down" size={12} />
    </Layout>
  </Layout>
);

const InputNumber = React.memo(
  ({
    placeholder,
    label,
    errorText,
    error,
    disabled,
    width,
    value,
    onChange,
    suffix,
    max,
    min,
    onUp,
    onDown,
    children,
    ...rest
  }) => (
    <Layout width={width} direction="column">
      {(label || children) && <Text css={theme => theme.input.label.css}>{label || children}</Text>}

      <Row css={{ position: 'relative' }}>
        <Element
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          spellCheck={false}
          value={value !== undefined ? `${value}${suffix !== undefined ? suffix : ''}` : ''}
          onChange={e => {
            let val = e.target.value;

            if (suffix !== undefined) {
              val = val.replace(suffix, '');
            }

            if (val !== '' && val !== '-') {
              if (Number.isNaN(+val)) {
                return;
              }

              val = +val;

              if (max !== undefined && val > max) {
                val = max;
              }

              if (min !== undefined && val < min) {
                val = min;
              }
            }

            onChange(val, e);
          }}
          {...rest}
        />

        <Controls
          disabled={disabled}
          onUp={onUp || (e => onChange(value + 1, e))}
          onDown={onDown || (e => onChange(value - 1, e))}
          maxReached={max !== undefined && value >= max}
          minReached={min !== undefined && value <= min}
        />
      </Row>

      {errorText && (
        <Row align="center" css={theme => theme.input.errorText.css}>
          <Text color="danger" size="m">
            {errorText}
          </Text>
        </Row>
      )}
    </Layout>
  )
);

InputNumber.defaultProps = {
  placeholder: '',
  error: false,
  errorText: '',
  label: '',
  width: '100%',
};

export default InputNumber;
