import './index.css';

import { Button, Column, Icon, Input, Row, Spin, Text } from '@ssmm/ui';
import { message } from 'antd';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import { func, string } from 'prop-types';
// import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import store from 'store';

import Authorization from '../../authorization/container';
import giftIcon from '../../gift.svg';

const RegSuccess = React.memo(({ authState, logout, user }) => {
  const [isPromocodeActivated, setIsPromocodeActivated] = useState(false);
  const history = useHistory();
  const url = new URL(window.location.href);
  const [step, setStep] = useState(1);
  const params = useParams();
  const [searchRefferalIdValue, setSearchRefferalIdValue] = useState('');
  const [ownerUserData, setOwnerUserData] = useState(undefined);
  const [loadingState, setLoadingState] = useState(false);

  const activatePromocode = async () => {
    try {
      setLoadingState(true);
      await axios.post(
        `https://server.super-smm.com/api/vkboss/promocode/use?uid=${user.uid}&promocode=Activity_15days`
      );
      setIsPromocodeActivated('activated');
      setStep(3);
      setLoadingState(false);
    } catch (e) {
      message.warning(e.response.data.message);
      setStep(3);
      setLoadingState(false);
    }
  };

  const checkPromocodeForActivation = async () => {
    try {
      setLoadingState(true);
      const result = await axios.get(
        `https://server.super-smm.com/api/vkboss/promocode/available?uid=${user.uid}&promocode=Activity_15days`
      );
      if (result.data.activated) {
        message.warning('Вы уже активировали этот промокод');
        setStep(3);
        setLoadingState(false);
        return;
      }
      setIsPromocodeActivated(result.data.activated ? 'activated' : 'deactivated');
      setLoadingState(false);
    } catch (e) {
      message.warning(e.response.data.message);
      setIsPromocodeActivated('expired');
      setStep(3);
      setLoadingState(false);
    }
  };

  const fetchUserByRefId = async refId => {
    try {
      setLoadingState(true);
      const result = await axios.get(
        `https://server.super-smm.com/api/vkboss/refferal/link/owner/${
          refId || searchRefferalIdValue
        }`
      );
      setLoadingState(false);
      return result.data;
    } catch (e) {
      message.warning(e?.response?.data?.message);
      setLoadingState(false);
    }
  };

  const changePartnerByRefId = async () => {
    try {
      setLoadingState(true);
      const fbToken = await firebase.auth().currentUser.getIdToken();
      await axios.patch(
        `https://server.super-smm.com/api/vkboss/refferal/change/?refferalId=${searchRefferalIdValue}`,
        {},
        {
          headers: { 'ssmm-token': fbToken },
        }
      );
      setStep(2);
      setLoadingState(false);
    } catch (e) {
      message.warning(e.response.data.message);
      setLoadingState(false);
    }
  };

  useEffect(() => {
    if (url.searchParams.get('refId') || params?.id) {
      fetchUserByRefId(url.searchParams.get('refId') || params?.id).then(setOwnerUserData);
      setSearchRefferalIdValue(url.searchParams.get('refId') || params?.id || '');
      window.open(
        `https://vk-boss-light.web.app/registration?refId=${
          url.searchParams.get('refId') || params.id
        }`,
        '_self'
      );
    } else {
      window.open(`https://vk-boss-light.web.app/registration`, '_self');
    }
  }, []);

  if (authState === 'loggedOut') return <Authorization />;

  if (authState === 'loggedIn' && user)
    return (
      <div className="main regSuccess">
        {step === 1 && (
          <Column css={{ marginTop: '48px', alignItems: 'center' }}>
            <Spin
              spinning={loadingState}
              css={{
                marginTop: '48px',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '580px',
                borderRadius: '20px',
              }}
            >
              {!ownerUserData && (
                <Column
                  css={{
                    maxWidth: '580px',
                    border: '1px solid #7E8A95',
                    padding: '25px',
                    borderRadius: '20px',
                    background: 'rgba(126, 138, 149, 0.05)',
                  }}
                >
                  <Text
                    marginBottom={16}
                    size={18}
                    weight={600}
                    css={{ textAlign: 'center', color: 'black', width: '100%' }}
                  >
                    Введите код пригласившего вас человека
                  </Text>
                  <Column css={{ alignItems: 'center', justifyContent: 'center' }}>
                    <Input
                      value={searchRefferalIdValue}
                      placeholder="Код"
                      width="90%"
                      onChange={e => setSearchRefferalIdValue(e.target.value)}
                      css={{
                        padding: '18px 12px',
                        color: 'black',
                        border: '1px solid #393939',
                        fontSize: '18px',
                        bordeRadius: '10px',
                        alignSelf: 'center',
                      }}
                    />
                    <Row
                      css={{ alignItems: 'center', justifyContent: 'center', marginTop: '16px' }}
                    >
                      <Button marginRight={4} onClick={() => setStep(2)}>
                        Пропустить
                      </Button>
                      <Button
                        marginLeft={4}
                        onClick={() => fetchUserByRefId().then(setOwnerUserData)}
                      >
                        Найти
                      </Button>
                    </Row>
                  </Column>
                </Column>
              )}
              {ownerUserData && (
                <Column
                  css={{
                    maxWidth: '580px',
                    border: '1px solid #7E8A95',
                    padding: '25px',
                    borderRadius: '20px',
                    background: 'rgba(126, 138, 149, 0.05)',
                  }}
                >
                  <Row css={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Row
                      css={{
                        width: 'auto',
                        alignItems: 'center',
                        flexShrink: 'unset',
                        '& > img': {
                          width: '50px',
                          height: '50px',
                          borderRadius: '100%',
                        },
                      }}
                    >
                      <img src={ownerUserData?.user?.PROFILE?.photo} alt="" />
                      <Column marginLeft={16}>
                        <Text size={20} weight={600}>
                          Вас пригласил
                        </Text>
                        <Text size={20} weight={600} color="black">
                          {ownerUserData?.newUser?.firstName}
                        </Text>
                      </Column>
                    </Row>
                    <Button onClick={() => setOwnerUserData(undefined)}>Отмена</Button>
                  </Row>
                  {ownerUserData?.hasPartnerArea && ownerUserData?.refferal?.bonus?.length > 0 && (
                    <>
                      <Text size={18} marginTop={25}>
                        Бонусы за регистрацию
                      </Text>
                      <Column
                        css={{
                          padding: '12px',
                          background: 'rgba(39, 135, 245, 0.04)',
                          border: '1px solid #2787F5',
                          borderRadius: '4px',
                          alignSelf: 'center',
                          marginTop: '8px',
                        }}
                      >
                        {ownerUserData?.refferal?.bonus.map((el, index) => (
                          <Row
                            key={`bonus${index + 1}`}
                            css={{
                              alignItems: 'center',
                              marginTop: '18px',
                              '&:first-child': {
                                marginTop: 0,
                              },
                            }}
                          >
                            <img src={giftIcon} />
                            <Text
                              css={{ marginLeft: '10px', color: '#2787f5' }}
                              size={14}
                              weight={600}
                            >
                              {el.title}{' '}
                              {el.name !== 'lessonPageEdit' &&
                                el.name !== 'lessonAlgoritms' &&
                                el.value}
                            </Text>
                          </Row>
                        ))}
                      </Column>
                    </>
                  )}
                  <Row css={{ justifyContent: 'center', alignItems: 'center', marginTop: '24px' }}>
                    <Button onClick={changePartnerByRefId}>Зарегистрироваться</Button>
                  </Row>
                </Column>
              )}
            </Spin>
          </Column>
        )}
        {step === 2 && (
          <Column css={{ padding: '50px 50px 0px' }} align="center">
            <h1>Регистрация прошла успешно</h1>
            <div className="regSuccess-links">
              <span className="regSuccess-links-link">
                Если Вы ещё не установили расширение:
                <Link to="/extinstall" title="Инструкция по установке">
                  инструкция по установке
                </Link>
              </span>
              <span className="regSuccess-links-link">
                Если у Вас есть вопросы:
                <a
                  href="https://vk.com/vsemsmm1"
                  title="Группа технической поддержки"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  написать в тех. поддержку
                </a>
              </span>
            </div>
          </Column>
        )}
      </div>
    );

  return null;
});

RegSuccess.propTypes = { authState: string, logout: func.isRequired };

export default RegSuccess;
