import styled from '@emotion/styled';

export const RadioElement = styled('input')({
  position: 'absolute',
  zIndex: -1,
  opacity: 0,
});

export const LabelElement = styled('label')(({ theme, checked }) => ({
  alignItems: 'stretch',
  cursor: 'pointer',
  '>span': { marginLeft: theme.dimensions.s, color: theme.colors.secondaryDark, transition: '.2s' },
  '>div': {
    borderColor: checked ? theme.colors.primary : theme.colors.secondary,
    transition: '.2s',
    i: { color: checked ? theme.colors.primary : theme.colors.secondary, transition: '.2s' },
  },
  '&:hover:not([disabled])': {
    '>span': { color: theme.colors.primary },
    '>div': {
      borderColor: theme.colors.primary,
      i: { color: theme.colors.primary },
    },
  },
  '&[disabled]': {
    pointerEvents: 'none',
    '>span': { color: theme.colors.muted },
    '>div': {
      borderColor: theme.colors.muted,
      boxShadow: 'none',
      i: { color: theme.colors.muted },
    },
  },
  display: 'flex',
}));
