import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Icon from '../icon';
import { useTheme } from '@emotion/react';
import { Layout } from '../layout';
import { Text } from '../text';
import { commonParser } from '../helpers';
import { DatePicker as AntdDatePicker } from 'antd';
import RangePicker from './range-picker';
import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/lib/date-picker/locale/ru_RU';

moment.locale('ru');
moment.updateLocale('ru', {
  week: {
    dow: 1,
  },
});

const StyledAntdDatePicker = styled(AntdDatePicker)(({ theme, disabled }) => ({
  width: '100%',
  minWidth: '150px !important',
  pointerEvents: disabled ? 'none' : 'all',
  '.ant-calendar-picker-container, .ant-calendar-input': theme.fonts.m,
  '.ant-calendar-today .ant-calendar-date': {
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
  },
  '.ant-calendar-selected-day .ant-calendar-date, .ant-calendar-date:hover': {
    background: theme.colors.primaryLighter,
  },
  '>div': {
    i: { color: theme.misc.iconColor },
    '.ant-calendar-picker-input': {
      width: '100%',
      border: `1px solid ${theme.colors.secondary}`,
      borderRadius: 4,
      outline: 0,
      height: theme.input.sizes.m.height,
      padding: `0 ${theme.dimensions.m}px`,
      boxSizing: 'border-box',
      boxShadow: 'none',
      ...theme.fonts.m,
      ':active, :focus': {
        borderColor: theme.colors.primary,
        transition: '.3s',
        '& ~ i': { color: theme.colors.primary },
      },
      ':disabled': {
        borderColor: theme.colors.muted,
      },
      '::placeholder': {
        color: theme.colors.muted,
      },
    },
  },
  '>div:hover': {
    '>i': { color: theme.colors.primary },
    '.ant-calendar-picker-input': { borderColor: theme.colors.primary },
  },
}));

const Container = styled(Layout)(({ theme, ...rest }) => ({
  width: '100%',
  flexDirection: 'column',
  ...commonParser({ theme, ...rest }),
}));

const DatePicker = React.memo(({ value, onChange, time, label, disabled, ...rest }) => {
  const [innerValue, setInnerValue] = useState(value);
  const theme = useTheme();

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const props = time
    ? { format: 'DD-MM-YY, HH:mm', showTime: true }
    : { format: 'DD-MM-YY', showTime: false };

  const handleChange = mom => {
    if (time) {
      setInnerValue(mom.valueOf());
    } else {
      setInnerValue(mom.startOf('day').valueOf());
    }
  };

  const handleOpenChange = state => {
    if (state) return;

    onChange(innerValue);
  };

  return (
    <Container {...rest}>
      {label && (
        <>{typeof label === 'string' ? <Text css={theme.input.label.css}>{label}</Text> : label}</>
      )}

      <StyledAntdDatePicker
        getPopupContainer={trigger => trigger}
        getCalendarContainer={trigger => trigger}
        suffixIcon={<Icon type="calendarOutline" />}
        locale={locale}
        allowClear={false}
        value={moment(innerValue)}
        onChange={handleChange}
        onOpenChange={handleOpenChange}
        disabled={disabled}
        {...rest}
        {...props}
      />
    </Container>
  );
});

DatePicker.defaultProps = {
  time: false,
  onChange: () => {},
};

DatePicker.RangePicker = RangePicker;

export default DatePicker;
