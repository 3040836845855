const initialState = {
  tariffs: null,
  globalSettings: null,
  paymentMethod: null,
  partnerTariffs: null,
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case 'SETTINGS_RES':
      return {
        ...state,
        ...action.settings,
      };

    default:
      return state;
  }
}
