import 'firebase/compat/database';

import {
  Accordion,
  Button,
  Column,
  Icon,
  Layout,
  Link,
  Modal,
  Row,
  Spin,
  Text,
  Video,
} from '@ssmm/ui';
import firebase from 'firebase/compat/app';
import { func } from 'prop-types';
import React, { useEffect, useState } from 'react';

const { AccordionContainer, AccordionPanel } = Accordion;

const iconMapping = {
  chabot: { type: 'moduleChatbot', set: 'ssmm' },
  crm: { type: 'moduleCRM', set: 'ssmm' },
  messages: { type: 'moduleMessages', set: 'ssmm' },
  multiAccount: { type: 'moduleMultiAccount', set: 'ssmm' },
  parsing: { type: 'moduleParsing', set: 'ssmm' },
  post: { type: 'modulePost', set: 'ssmm' },
  teams: { type: 'moduleTeam', set: 'ssmm' },
  vkgram: { type: 'storysend', set: 'boss' },
  default: { type: 'code', set: 'ssmm' },
};

const SsmmInfo = React.memo(({ onClose, createClickAndDestroyLink }) => {
  const [modulesDescription, setModulesDescription] = useState(null);
  const [activePanel, setActivePanel] = useState();
  const [mode, setMode] = useState('functioning');

  useEffect(() => {
    getModulesDescription();
  }, []);

  const getModulesDescription = async () => {
    const desc = await firebase
      .database()
      .ref('SETTINGS/modules')
      .once('value')
      .then(snap => {
        const { primary, development } = snap.val();

        const modulesParams = [
          ...Object.keys(primary).map(mod => ({
            id: mod,
            ...primary[mod],
            type: 'primary',
          })),
          ...Object.keys(development).map(mod => ({
            id: mod,
            ...development[mod],
            type: 'development',
          })),
        ];

        return modulesParams;
      });

    setModulesDescription(desc.sort((a, b) => a.order - b.order));
  };

  const handleInstallClick = () => {
    onClose();

    createClickAndDestroyLink(
      'https://chrome.google.com/webstore/detail/super-smm-vk-fb-inst-ok/lkhdjcjomgifjdihdiecifkigmdemjlh'
    );
  };

  const filtered =
    modulesDescription &&
    modulesDescription.filter(mod =>
      mode === 'functioning' ? mod.type === 'primary' : mod.type === 'development'
    );

  return (
    <Modal
      title={`Список ${
        mode === 'functioning' ? 'доступных функций' : 'функций в разработке'
      } из сервиса Super-SMM`}
      width={673}
      onClose={onClose}
      getContainer={() => document.getElementById('root')}
    >
      <Spin direction="column" spinning={!filtered} shrink={1} overflow="hidden">
        <Column shrink={1} overflow="hidden" padding={['s', 0, 'xl', 'l']}>
          <Column paddingRight="l" scroll>
            <AccordionContainer activeKey={activePanel} multiple={false} onChange={setActivePanel}>
              {filtered &&
                filtered.map(mod => {
                  const { id, title_ru, desc, video } = mod;

                  const isOpen = id === activePanel;

                  return (
                    <AccordionPanel
                      key={id}
                      title={
                        <Layout grow={1} align="center" justify="space-between">
                          <Layout align="center" marginRight="s">
                            <Icon
                              type={iconMapping[id]?.type || iconMapping.default.type}
                              set={iconMapping[id]?.set || iconMapping.default.set}
                              color="primary"
                              size={20}
                              marginRight="s"
                            />
                            <Text size="l" color={isOpen ? 'primary' : undefined}>
                              {title_ru}
                            </Text>
                          </Layout>

                          <Link color="secondary" onClick={() => setActivePanel(id)}>
                            Читать описание
                          </Link>
                        </Layout>
                      }
                    >
                      <Row
                        css={theme => theme.fonts.m}
                        dangerouslySetInnerHTML={{ __html: desc }}
                      />

                      {video && <Video video={video} marginTop="m" />}
                    </AccordionPanel>
                  );
                })}
            </AccordionContainer>
          </Column>
        </Column>

        <Row
          justify="flex-end"
          align="center"
          padding="l"
          css={theme => ({ borderTop: `1px solid ${theme.colors.secondaryLighter}` })}
        >
          <Button
            type="light"
            marginRight="m"
            onClick={() => setMode(mode === 'functioning' ? 'development' : 'functioning')}
          >
            {mode === 'functioning' ? 'Функции в разработке' : 'Доступные функции'}
          </Button>
          <Button type="dark" onClick={handleInstallClick}>
            Установить
          </Button>
        </Row>
      </Spin>
    </Modal>
  );
});

SsmmInfo.propTypes = {
  onClose: func.isRequired,
};

export default SsmmInfo;
