import { Column, HeadingH1, Spin, Text } from '@ssmm/ui';
import axios from 'axios';
import AuthUi from 'components/auth-ui/container';
import { bool, func } from 'prop-types';
// import queryString from 'query-string';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { sendMessageExt } from 'redux/actions';

const Authorization = ({ newUser, authLoading, handleMessage, handleCred }) => {
  const history = useHistory();
  const params = useParams();
  const url = new URL(window.location.href);

  const incrementRefferalFollow = async ref => {
    await axios.get(`https://server.super-smm.com/api/vkboss/refferal/link/follow/${ref}`);
  };

  useEffect(() => {
    if (window.location.href.includes('vk-boss.ru')) {
      let newUrl = window.location.href.replace('vk-boss.ru', 'vk-boss.web.app');

      const ref = localStorage.getItem('ref');
      const utm = localStorage.getItem('utm');
      const utmSource = localStorage.getItem('utm_source');
      const silent = localStorage.getItem('silent');

      if (ref && !newUrl.includes('refId')) {
        newUrl += `${newUrl.includes('?') ? '&' : '?'}refId=${ref}`;
      }

      if (utm && !newUrl.includes('utm')) {
        newUrl += `${newUrl.includes('?') ? '&' : '?'}utm=${utm}`;
      }

      if (utmSource && !newUrl.includes('utm_source')) {
        newUrl += `${newUrl.includes('?') ? '&' : '?'}utm_source=${utmSource}`;
      }

      if (silent && !newUrl.includes('silent')) {
        newUrl += `${newUrl.includes('?') ? '&' : '?'}silent=${silent}`;
      }

      window.location.href = newUrl;
    }

    if (params?.id) {
      localStorage.setItem('refId', params.id);
    }
    if (history.location.search?.refId || params?.id) {
      incrementRefferalFollow(url.searchParams.get('refId') || params?.id);
    }
  }, []);

  return (
    <Spin
      spinning={authLoading}
      grow={1}
      shrink={1}
      direction="column"
      css={{
        position: 'fixed !important',
        left: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
      }}
    >
      <Column
        grow={1}
        align="center"
        justify="center"
        background="radial-gradient(68.5% 175.36% at 27.17% 50%, #272727 0%, #1D1D1D 100%)"
      >
        {!newUser && (
          <>
            <HeadingH1 color="primary" marginBottom="m">
              Авторизация в системе
            </HeadingH1>
            <Text size={14} color="primaryLight" align="center" marginBottom="s">
              Для входа в систему авторизуйтесь через ВКонтакте.
              <br />
              Система автоматически создаст пользователя с привязкой к Вашему аккаунту VK.
            </Text>
          </>
        )}

        <AuthUi onMessage={handleMessage} onCred={handleCred} newUser={newUser} source="web" />
      </Column>
    </Spin>
  );
};

Authorization.propTypes = {
  newUser: bool.isRequired,
  authLoading: bool.isRequired,
  handleMessage: func.isRequired,
  handleCred: func.isRequired,
};

export default Authorization;
