import { Modal } from '@ssmm/ui';
import List from 'components/LK/ui/list';
import { func } from 'prop-types';
import React from 'react';

const Terms = React.memo(({ onClose }) => (
  <Modal
    width={700}
    title="Условия акции"
    onClose={onClose}
    getContainer={() => document.getElementById('root')}
  >
    <List>
      <li>
        Купить тариф по акции может любой зарегистрированный в системе пользователь. При наличии
        активного тарифа у пользователя, тариф по акции добавится в очередь.
      </li>
      <li>Тариф по акции не может быть куплен с внутреннего счёта.</li>
    </List>
  </Modal>
));

Terms.propTypes = {
  onClose: func.isRequired,
};

export default Terms;
