import { SsmmIcon } from '../../types';
import commonIcons from './common';
import editorIcons from './editor';
import moduleIcons from './modules';

export type SsmmIcons = { [key in SsmmIcon]: string };

const ssmmIcons: SsmmIcons = { ...commonIcons, ...editorIcons, ...moduleIcons };

export default ssmmIcons;
