export const getURLParameter = name => {
  const params = new URL(window.location.href).searchParams;
  return params.get(name);
};

export const saveRefCodeToSessionStorage = () => {
  const params = new URL(window.location.href).searchParams;
  const refId = getURLParameter('refId');
  const utm = getURLParameter('utm');
  const utmSource = getURLParameter('utm_source');

  if (refId || params?.id) {
    localStorage.setItem('refId', params?.id || refId);

    const silent = getURLParameter('silent');

    if (silent) {
      localStorage.setItem('silent', true);
    }
  }

  if (utm) {
    localStorage.setItem('utm', utm);
  }

  if (utmSource) {
    localStorage.setItem('utm_source', utmSource);
  }
};
