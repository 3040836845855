import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Icon from '../icon';
import { useTheme } from '@emotion/react';
import { Layout } from '../layout';
import { Text } from '../text';
import { commonParser } from '../helpers';
import { DatePicker as AntdDatePicker } from 'antd';
import moment from 'moment';
import 'moment/locale/ru';
import locale from 'antd/lib/date-picker/locale/ru_RU';

moment.locale('ru');
moment.updateLocale('ru', {
  week: {
    dow: 1,
  },
});

const { RangePicker: AntdRangePicker } = AntdDatePicker;

const StyledAntdRangePicker = styled(AntdRangePicker)(({ theme, disabled }) => ({
  width: '100%',
  minWidth: '150px !important',
  pointerEvents: disabled ? 'none' : 'all',
  borderRadius: 4,
  '.ant-calendar-picker-input': {
    display: 'flex',
    alignItems: 'center',
    border: `1px solid ${disabled ? theme.colors.muted : theme.colors.secondary}`,
    borderRadius: 4,
    ...theme.fonts.m,
  },
  '.ant-calendar-picker-container, .ant-calendar-range-picker-input': theme.fonts.m,
  '.ant-calendar-range-picker-separator': { fontSize: 14 },
  '.ant-calendar-today .ant-calendar-date': {
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
  },
  '.ant-calendar-selected-day .ant-calendar-date, .ant-calendar-date:hover,.ant-calendar-range .ant-calendar-in-range-cell:before, .ant-calendar-month-panel-month:hover, .ant-calendar-year-panel-year:hover, .ant-calendar-decade-panel-decade:hover, .ant-calendar-time-picker-select li:hover':
    {
      color: theme.colors.secondaryDarker,
      background: theme.colors.primaryLighter,
    },
  // для инсты
  '.ant-calendar-date-panel': { display: 'flex', flexDirection: 'row' },
  '.ant-calendar-cell': { verticalAlign: 'middle', boxSizing: 'border-box' },
  '.ant-calendar-input': { boxSizing: 'border-box', ...theme.fonts.m },
  '.ant-calendar-header, .ant-calendar-range-part >div:nth-of-type(2)': { position: 'unset' },
  //
  ':active, :focus': {
    '.ant-calendar-picker-input': {
      border: `1px solid ${theme.colors.secondary}`,
      boxShadow: 'none',
    },
  },
  '&:hover': {
    '.ant-calendar-picker-input': {
      borderColor: theme.colors.primary,
      i: { color: theme.colors.primary },
    },
  },
}));

const Container = styled(Layout)(({ theme, ...rest }) => ({
  width: '100%',
  flexDirection: 'column',
  ...commonParser({ theme, ...rest }),
}));

const RangePicker = React.memo(({ value, onChange, time, label, disabled, ...rest }) => {
  const [innerValue, setInnerValue] = useState(value);
  const theme = useTheme();

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const props = time
    ? { format: 'DD-MM-YY, HH:mm', showTime: true }
    : { format: 'DD-MM-YY', showTime: false };

  const handleChange = momArr => {
    if (time) {
      setInnerValue([momArr[0].valueOf(), momArr[1].valueOf()]);
    } else {
      setInnerValue([momArr[0].startOf('day').valueOf(), momArr[1].endOf('day').valueOf()]);
    }
  };

  const handleOpenChange = state => {
    if (state) return;

    onChange(innerValue);
  };

  return (
    <Container {...rest}>
      {label && (
        <>{typeof label === 'string' ? <Text css={theme.input.label.css}>{label}</Text> : label}</>
      )}

      <StyledAntdRangePicker
        getPopupContainer={trigger => trigger}
        getCalendarContainer={trigger => trigger}
        suffixIcon={<Icon type="calendarOutline" />}
        locale={locale}
        allowClear={false}
        value={[moment(innerValue[0]), moment(innerValue[1])]}
        onChange={handleChange}
        onOpenChange={handleOpenChange}
        disabled={disabled}
        {...rest}
        {...props}
      />
    </Container>
  );
});

RangePicker.defaultProps = {
  time: false,
  onChange: () => {},
};

export default RangePicker;
