import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import React from 'react';
import { Layout, Row } from '../layout';
import Icon from '../icon';
import { IconType } from '../icon/types';

const HeaderElement = styled(Row)(({ theme }) => ({
  position: 'relative',
  background: theme.colors.secondaryDark,
  padding: `${theme.dimensions.m}px ${theme.dimensions.xxl * 2}px ${theme.dimensions.m}px ${
    theme.dimensions.l
  }px`,
  borderBottom: 'none',
  borderRadius: '4px 4px 0px 0px',
  minHeight: theme.dimensions.xxl * 2,
  '& h2': {
    color: theme.colors.primaryLight,
  },
}));

const CloseBtn = React.memo(() => {
  const theme = useTheme();

  return (
    <Layout
      justify="center"
      align="center"
      css={t => ({
        position: 'absolute',
        top: 0,
        right: 0,
        width: t.dimensions.xxl * 2,
        height: t.dimensions.xxl * 2,
        cursor: 'pointer',
        i: {
          opacity: 0.85,
        },
        '&:hover i': {
          opacity: 1,
        },
      })}
    >
      <Icon type={theme.misc.closeIcon as IconType} color="primaryLight" />
    </Layout>
  );
});

export default { HeaderElement, CloseBtn };
