const colors = {
  blue: '#2787F5',
  green: '#63C67A',
  yellow: '#FFCB45',
  black: '#000000',
  grayDark: '#4B4B4B',
  grayLight: '#707D89',
};

export default colors;
