import colors from './colors';

const fonts = {
  mainFont: 'Roboto',
  mainHeadingFont: 'Montserrat',
  linkColor: colors.secondary,
  linkColorHover: colors.primary,
  h1: {},
  h2: {
    fontSize: 18,
    lineHeight: 1.22,
    color: colors.secondaryDarker,
    fontWeight: 700,
    letterSpacing: '2px',
    fontFamily: 'Montserrat',
  },
  h3: {
    fontSize: 16,
    lineHeight: 1.25,
    color: colors.primary,
    fontWeight: 700,
    letterSpacing: '1px',
    fontFamily: 'Montserrat',
  },
  h4: {},
  s: {
    fontSize: 12,
    lineHeight: 1.167,
    color: colors.secondary,
    fontWeight: 400,
    letterSpacing: '0.1em',
    fontFamily: 'Roboto',
  },
  m: {
    fontSize: 16,
    lineHeight: 1.1875,
    color: colors.secondary,
    fontWeight: 400,
    letterSpacing: '0.1em',
    fontFamily: 'Roboto',
  },
  l: {
    fontSize: 12,
    lineHeight: 1.25,
    color: colors.secondaryDarker,
    fontWeight: 700,
    letterSpacing: '2px',
    fontFamily: 'Montserrat',
  },
  xl: {
    fontSize: 12,
    lineHeight: 1.25,
    color: colors.secondaryDarker,
    fontWeight: 700,
    letterSpacing: '2px',
    fontFamily: 'Montserrat',
  },
};

export default fonts;
