import { handleUser } from 'redux/actions/user';

import { AUTH_LOGOUT, UPDATE_USER } from '../types';

const initialState = null;

export default function user(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_USER: {
      const user = handleUser({ ...state, ...payload });
      return user;
    }

    case AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
}
