import React from 'react';
import { Text } from '../text';
import styled from '@emotion/styled';
import { Row } from '../layout';
import { CSSObject } from '@emotion/styled/types';

export type CurrentBlockId = string | number;

export interface BlockProps {
  id: CurrentBlockId;
  title: string | number | React.ReactElement;
  disabled?: boolean;
}

interface ComponentProps {
  block: BlockProps;
  current?: CurrentBlockId;
  onChange: (id: CurrentBlockId) => void;
}

interface ElementProps {
  active: boolean;
  disabled?: boolean;
}

const BlockElement = styled(Row)<ElementProps>(({ theme, active, disabled }) => {
  let styleObj: CSSObject = {
    minHeight: 62,
    padding: `${theme.dimensions.s}px ${theme.dimensions.m}px`,
    borderRadius: 4,
    boxShadow: `0px 2px 16px rgba(47, 59, 100, 0.1)`,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  };

  if (active) {
    styleObj = {
      ...styleObj,
      background: theme.colors.primary,
      pointerEvents: 'none',
      '>span': { color: theme.colors.primaryLight },
    };

    if (disabled) {
      styleObj = {
        ...styleObj,
        background: theme.colors.muted,
      };
    }
  } else {
    styleObj = {
      ...styleObj,
      background: theme.colors.primaryLight,
      cursor: 'pointer',
      borderStyle: 'dashed',
      borderWidth: 1,
      borderColor: theme.colors.primaryLight,
      ':hover': {
        '>span': { color: theme.colors.primary },
        borderColor: theme.colors.primary,
        color: theme.colors.primary,
      },
    };

    if (disabled) {
      styleObj = {
        ...styleObj,
        pointerEvents: 'none',
        '>span': { color: theme.colors.muted },
      };
    }
  }

  return styleObj;
});

const Block = React.memo<ComponentProps>(({ block, current, onChange }) => {
  const { id, title, disabled } = block;

  const active = id === current;
  const content =
    typeof title === 'string' || typeof title === 'number' ? (
      <Text weight={600} color="secondaryDarker">
        {title}
      </Text>
    ) : (
      title
    );

  return (
    <BlockElement active={active} disabled={disabled} onClick={() => onChange(id)}>
      {content}
    </BlockElement>
  );
});

export default Block;
