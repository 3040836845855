import { ThemeProvider as EmotionThemeProvider, useTheme, withTheme } from '@emotion/react';
import { ThemeProviderProps } from '@emotion/react/types';
import { createElement, ReactElement } from 'react';

import {
  defaultTheme,
  inBoss,
  settingsMenuTheme,
  ssmm,
  storysend,
  storysendWeb,
  vkBoss,
} from './themes';
import { Theme } from './types';

interface Props extends ThemeProviderProps {
  theme: Theme;
}

const ThemeProvider = (props: Props): ReactElement => {
  const { theme, ...rest } = props;

  return createElement(EmotionThemeProvider, {
    theme: theme || defaultTheme,
    ...rest,
  });
};

export {
  defaultTheme,
  inBoss,
  settingsMenuTheme,
  ssmm,
  storysend,
  storysendWeb,
  ThemeProvider,
  useTheme,
  vkBoss,
  withTheme,
};
