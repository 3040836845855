// import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css';
import './index.css';

import firebase from 'firebase/compat/app';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import config from './config/firebase';
import Routes from './Routes';
import store from './store';

firebase.initializeApp(config);

ReactDOM.render(
  <Provider store={store}>
    <Routes />
  </Provider>,
  document.getElementById('root')
);

// serviceWorker.unregister();
