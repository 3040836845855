/* const config =
  process.env.NODE_ENV === 'development'
    ? {
        apiKey: 'AIzaSyCtH12hQ5w2MdMPew0nMU-zelEPsxIPHpc',
        authDomain: 'super-smm.firebaseapp.com',
        databaseURL: 'https://test-c67b0.firebaseio.com',
        projectId: 'test-c67b0',
        storageBucket: 'super-smm.appspot.com',
        messagingSenderId: '1048548476320',
      }
    : {
        apiKey: 'AIzaSyAcKSc97DQ6Ov9c-TDmsqGArRbrAlpz_90',
        authDomain: 'super-smm.firebaseapp.com',
        databaseURL: 'https://super-smm.firebaseio.com',
        projectId: 'super-smm',
        storageBucket: 'super-smm.appspot.com',
        messagingSenderId: '1048548476320',
      };
*/

const config = {
  apiKey: 'AIzaSyAcKSc97DQ6Ov9c-TDmsqGArRbrAlpz_90',
  authDomain: 'super-smm.firebaseapp.com',
  databaseURL: 'https://super-smm.firebaseio.com',
  projectId: 'super-smm',
  storageBucket: 'super-smm.appspot.com',
  messagingSenderId: '1048548476320',
};

export default config;
