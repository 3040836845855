/* eslint-disable import/prefer-default-export */
import { CSSObject } from '@emotion/styled/types';
import { Theme } from '@ssmm/theme/dist/types';
import { CommonStyleProps } from './types';

interface CommonParser extends CommonStyleProps {
  theme: Theme;
}

export const commonParser: (props: CommonParser) => CSSObject = ({ theme, ...styleProps }) => {
  let styleObj: CSSObject = {};

  Object.keys(styleProps).forEach(prop => {
    const value = styleProps[prop];

    switch (prop) {
      case 'width':
      case 'height':
        styleObj[prop] = theme.dimensions[value] || value;
        break;

      case 'basis':
        styleObj.flexBasis = theme.dimensions[value] || value;
        break;

      case 'grow':
        styleObj.flexGrow = value;
        break;

      case 'shrink':
        styleObj.flexShrink = value;
        break;

      case 'direction':
        styleObj.flexDirection = value;
        break;

      case 'justify':
        styleObj.justifyContent = value;
        break;

      case 'align':
        styleObj.alignItems = value;
        break;

      case 'padding':
      case 'paddingTop':
      case 'paddingBottom':
      case 'paddingLeft':
      case 'paddingRight':
      case 'margin':
      case 'marginTop':
      case 'marginBottom':
      case 'marginLeft':
      case 'marginRight':
        if (typeof value === 'string' && theme.dimensions[value]) {
          styleObj[prop] = theme.dimensions[value];
        } else if (typeof value === 'object') {
          styleObj[prop] = value.map(side => `${theme.dimensions[side] || side || 0}px`).join(' ');
        } else {
          styleObj[prop] = value;
        }
        break;

      case 'css':
        styleObj = { ...styleObj, ...(typeof value === 'function' ? value(theme) : value) };
        break;

      default:
        break;
    }
  });

  return styleObj;
};
