const getStyleProps = ({ theme, color }) => ({
  height: theme.dimensions.m + 2,
  width: theme.dimensions.xxl + 5,
  minWidth: theme.dimensions.xxl + 5,
  border: 'none',
  backgroundColor: theme.switch.colors[color].noState.background,
  '&.ant-switch-checked': {
    backgroundColor: theme.switch.colors[color].noState.backgroundChecked,
  },
  '&:after': {
    boxShadow: 'none',
    backgroundColor: theme.switch.colors[color].noState.dot,
    width: theme.dimensions.m,
    height: theme.dimensions.m,
    borderRadius: '100%',
    boxSizing: 'border-box',
  },
  '&.ant-switch-checked:after': {
    backgroundColor: theme.switch.colors[color].noState.dotChecked,
  },
});

export default getStyleProps;
