import { logout } from 'components/authorization/actions';
import { connect } from 'react-redux';

import RegSuccess from '../component';

const mapStateToProps = state => ({
  authState: state.auth.state,
  user: state.user,
});

export default connect(mapStateToProps, { logout })(RegSuccess);
