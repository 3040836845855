import boss from './boss';
import ssmm from './ssmm';
import storysend from './storysend';

const icons = { ssmm, boss, storysend };

export { BossIcons } from './boss';
export { SsmmIcons } from './ssmm';
export { StorysendIcons } from './storysend';

export default icons;
