import 'firebase/compat/storage';

import firebase from 'firebase/compat/app';
import { CLOSE_PDF_MODAL, DOCUMENT_URL_RES, OPEN_PDF_MODAL } from 'redux/types';

import config from '../../../config';

export const openPdfModal = documentName => async (dispatch, getState) => {
  const document = getState().documents[documentName] || {};
  let { url } = document;

  if (!url) {
    url = await firebase.storage().ref(config.documents[documentName].ref).getDownloadURL();

    dispatch({ type: DOCUMENT_URL_RES, url, documentName });
  }

  dispatch({
    type: OPEN_PDF_MODAL,
    url,
    title: config.documents[documentName].title,
  });
};

export const closePdfModal = () => ({ type: CLOSE_PDF_MODAL });
