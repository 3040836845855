import React, { useState, useEffect } from 'react';
import { Layout } from '../layout';
import Icon from '../icon';
import styled from '@emotion/styled';

const Wrap = styled(Layout)(({ theme, size }) => ({
  width: size,
  height: size,
  padding: 1,
  background: theme.colors.secondaryLighter,
  borderRadius: '50%',
}));

const Container = styled(Layout)(({ theme, color }) => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: `0 0 0 1px ${theme.colors[color] || color}`,
  borderRadius: '50%',
  overflow: 'hidden',
  img: { height: 'calc(100% + 2px)', width: 'calc(100% + 2px)', objectFit: 'cover' },
}));

const Avatar = React.memo(({ src, size = 45, color = 'primary', ...rest }) => {
  const [isValid, setIsValid] = useState(true);

  useEffect(() => setIsValid(!!src), [src]);

  const content = isValid ? (
    <img src={src} onError={() => setIsValid(false)} />
  ) : (
    <Icon type="user" set="ssmm" size={Math.round(size * 0.5)} />
  );

  return (
    <Wrap size={size} {...rest}>
      <Container color={color}>{content}</Container>
    </Wrap>
  );
});

export default Avatar;
