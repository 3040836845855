import { BossIcon } from '../../types';
import commonIcons from './common';
import editorIcons from './editor';
import socIcons from './soc';

export type BossIcons = { [key in BossIcon]: string };

const bossIcons: BossIcons = { ...commonIcons, ...editorIcons, ...socIcons };

export default bossIcons;
