import React from 'react';
import { Text } from '../../text';
import { RadioElement, LabelElement } from '../elements';
import ssmm from './ssmm';
import boss from './boss';

const components = { ssmm, boss };

const RadioDot = React.memo(({ type, children, value, disabled, checked, onChange, ...rest }) => {
  const { FakeRadio, Checked } = components[type];

  return (
    <LabelElement
      disabled={disabled}
      className="radioDot"
      data-checked={checked}
      checked={checked}
      onClick={() => onChange(value)}
    >
      <RadioElement type="radio" disabled={disabled} {...rest} />
      <FakeRadio checked={checked}>{checked && <Checked />}</FakeRadio>
      {children && <Text>{children}</Text>}
    </LabelElement>
  );
});

RadioDot.defaultProps = {
  onChange: () => {},
};

export default RadioDot;
