import { CSSObject } from '@emotion/styled/types';
import { Theme } from '@ssmm/theme/dist/types';
import { Size } from '@ssmm/theme/dist/types/common';

export interface CommonStyleProps {
  height?: Size;
  width?: CSSObject['width'] | Size;
  basis?: Size;
  grow?: CSSObject['flexGrow'];
  shrink?: CSSObject['flexShrink'];
  direction?: CSSObject['flexDirection'];
  justify?: CSSObject['justifyContent'];
  align?: CSSObject['alignItems'];
  padding?: Size | Size[];
  paddingTop?: Size;
  paddingBottom?: Size;
  paddingLeft?: Size;
  paddingRight?: Size;
  margin?: Size | Size[];
  marginTop?: Size;
  marginBottom?: Size;
  marginLeft?: Size;
  marginRight?: Size;
  css?: CSSObject | ((theme: Theme) => CSSObject);
}

// пздц, почему для этого нет простого автоматического решения?
const CommonStylePropsKeys = [
  'height',
  'width',
  'basis',
  'grow',
  'shrink',
  'direction',
  'justify',
  'align',
  'padding',
  'paddingTop',
  'paddingBottom',
  'paddingLeft',
  'paddingRight',
  'margin',
  'marginTop',
  'marginBottom',
  'marginLeft',
  'marginRight',
  'css',
];

export { Theme, CSSObject, CommonStylePropsKeys };
