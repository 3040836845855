import { Button, Checkbox, Column, HeadingH1, Input, Layout, Link, Row, Text } from '@ssmm/ui';
import { bool, func, object, string } from 'prop-types';
import React from 'react';

import branch from '../images/branch.svg';
import OwnerInfo from './owner-info';

const Registration = ({
  state,
  registration,
  updateSearchString,
  fetchUserByRef,
  authLoading,
  toggleAcceptRef,
  toggleAcceptPrivacy,
  openPdfModal,
  openRef,
  closeRef,
  toggleAcceptNoPartner,
  source,
}) => {
  const { acceptRefTerms, acceptPrivacyTerms, ownerInfo, refSearch, refOpen, acceptNoPartner } =
    state;

  let regButtonDisabled = !acceptPrivacyTerms;

  if (authLoading) {
    // что-то загружается
    regButtonDisabled = true;
  } else if (ownerInfo && ownerInfo.status === 'success' && !acceptRefTerms) {
    // пользователь что-то успешно нашел, но не принял условия
    regButtonDisabled = true;
  } else if (!acceptNoPartner && !acceptRefTerms) {
    // пользователь не ищет партнера, но не согласен на регистрацию без него
    regButtonDisabled = true;
  }

  return (
    <Row justify="center">
      <Layout
        direction="column"
        width={410}
        padding={['xl', 'l', 'xl', 'l']}
        background="primaryLight"
      >
        <Row justify="center" marginBottom="l">
          <HeadingH1 marginRight="s">Регистрация</HeadingH1> <img src={branch} alt="" />
        </Row>

        {ownerInfo && <OwnerInfo ownerInfo={ownerInfo} onCancel={closeRef} />}

        <Checkbox checked={acceptPrivacyTerms} onChange={toggleAcceptPrivacy}>
          <Text>
            Согласен с{' '}
            <Text
              css={theme => theme.effects.hover}
              onClick={e => {
                e.nativeEvent.preventDefault();
                openPdfModal('privacyPolicy');
              }}
            >
              политикой конфиденциальности
            </Text>
          </Text>
        </Checkbox>

        {ownerInfo && ownerInfo.status === 'success' && !acceptNoPartner ? (
          <Checkbox checked={acceptRefTerms} onChange={toggleAcceptRef} marginTop="m">
            <Text>Подтверждаю регистрацию под этим партнером</Text>
          </Checkbox>
        ) : (
          <Checkbox checked={acceptNoPartner} onChange={toggleAcceptNoPartner} marginTop="m">
            <Text>Согласен на регистрацию без кода</Text>
          </Checkbox>
        )}

        {!ownerInfo && (
          <Link onClick={refOpen ? closeRef : openRef} marginTop="l">
            У меня есть реферальный код
          </Link>
        )}

        {refOpen && !ownerInfo && !acceptNoPartner && (
          <Column marginTop="l">
            <Input
              label="Введите реферальный код"
              placeholder="Реферальный код"
              value={refSearch || ''}
              onChange={e => updateSearchString(e.target.value)}
              onEnter={val => fetchUserByRef(val)}
              disabled={authLoading}
              max={40}
              marginBottom="m"
            />

            <Button
              onClick={() => fetchUserByRef(refSearch)}
              disabled={!refSearch}
              icon="search"
              iconSet="ssmm"
              width="100%"
              iconSize={13}
            >
              Поиск
            </Button>
          </Column>
        )}

        <Row background="secondaryLighter" height={1} marginTop="l" marginBottom="l" />

        <Button
          disabled={regButtonDisabled}
          onClick={() => registration(source)}
          width="100%"
          type="dark"
        >
          Регистрация
        </Button>
      </Layout>
    </Row>
  );
};

Registration.propTypes = {
  state: object.isRequired,
  registration: func.isRequired,
  updateSearchString: func.isRequired,
  fetchUserByRef: func.isRequired,
  authLoading: bool.isRequired,
  openPdfModal: func.isRequired,
  source: string.isRequired,
};

export default Registration;
