import {
  CLOSE_PHONE_AUTH,
  OPEN_PHONE_AUTH,
  OPEN_REG,
  RESET_AUTHUI,
  SET_HANDLERS,
  SET_PHONE_AUTH_ERROR,
  SET_PHONE_AUTH_STAGE,
  SET_PHONE_CODE,
  SET_PHONE_NUMBER,
} from 'redux/types';

const initialState = {
  phoneAuthOpen: false,
  phoneAuthError: null,
  phoneAuthStage: 'number',
  phoneNumber: '',
  phoneCode: '',
  handlers: {},
  newUser: false,
};

export default function authUi(state = initialState, action) {
  switch (action.type) {
    case RESET_AUTHUI:
      return { ...initialState };

    case OPEN_PHONE_AUTH:
      return { ...state, phoneAuthOpen: true };

    case CLOSE_PHONE_AUTH:
      return { ...state, phoneAuthOpen: false };

    case SET_PHONE_AUTH_STAGE:
      return { ...state, phoneAuthStage: action.payload, phoneAuthError: null };

    case SET_PHONE_NUMBER:
      return { ...state, phoneNumber: action.payload };

    case SET_PHONE_CODE:
      return { ...state, phoneCode: action.payload };

    case SET_PHONE_AUTH_ERROR:
      return { ...state, phoneAuthError: action.payload };

    case SET_HANDLERS:
      return { ...state, handlers: action.payload };

    case OPEN_REG:
      return { ...state, newUser: true };

    default:
      return state;
  }
}
