/** @jsxImportSource @emotion/react */
import { useTheme } from '@ssmm/theme';
import { Button, Column, Icon, Info, Layout, Radio, Row, Select, Text } from '@ssmm/ui';
import { bool, func, number, object, string } from 'prop-types';
import React from 'react';

import YellowTitle from '../../components/yellow-title';
import BonusList from './bonus-list';

const { RadioGroup, RadioButton } = Radio;

const Regular = React.memo(
  ({
    onPurchase,
    tariffs,
    balance,
    period,
    from,
    setFrom,
    autoContinue,
    setAutoContinue,
    onSsmmClick,
    bonusSum,
  }) => {
    const theme = useTheme();
    const price = tariffs.fullPackage.price[period];
    const discount = tariffs.fullPackage.price.m1 * Number(period.replace('m', '')) - price;

    return (
      <>
        <Column padding={[0, 'm', 'l', 'm']}>
          <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
            <Layout align="center">
              <Icon type="commentIndicatorFilled" color="primary" size={22} marginRight="m" />
              <Text size="l">Секретарь</Text>
            </Layout>
            <Info content="Секретарь" />
          </Row>

          <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
            <Layout align="center">
              <Icon type="giftFilled" color="primary" size={22} marginRight="m" />
              <Text size="l">Поздравления</Text>
            </Layout>
            <Info content="Поздравления" />
          </Row>

          <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
            <Layout align="center">
              <Icon type="likeFilled" color="primary" size={22} marginRight="m" />
              <Text size="l">Привлечение внимания</Text>
            </Layout>
            <Info content="Привлечение внимания" />
          </Row>

          <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
            <Layout align="center">
              <Icon type="userAddedFilled" color="primary" size={22} marginRight="m" />
              <Text size="l">Авто подписка</Text>
            </Layout>
            <Info content="Авто подписка" />
          </Row>

          <Row justify="space-between" align="center" marginBottom="l" css={{ minHeight: 32 }}>
            <Layout align="center">
              <Icon type="likeCommentFilled" color="primary" size={22} marginRight="m" />
              <Text size="l">Сбор ЦА</Text>
            </Layout>
            <Info content="Сбор ЦА" />
          </Row>

          <YellowTitle marginBottom="l">+ бонус</YellowTitle>

          <BonusList period={period} discount={discount} onSsmmClick={onSsmmClick} />

          <Row
            padding="s"
            align="center"
            justify="space-between"
            css={{ border: `1px dashed ${theme.colors.secondary}` }}
          >
            <Text size="l">К оплате:</Text>

            <Select value={period} disabled width={118}>
              <Select.Option key="m3" value="m3">
                3 месяца
              </Select.Option>
              <Select.Option key="m6" value="m6">
                6 месяцев
              </Select.Option>
              <Select.Option key="m12" value="m12">
                Год
              </Select.Option>
            </Select>

            <Layout align="center">
              {discount > 0 && (
                <>
                  <Icon type="rubleReturn" size={22} color="success" marginRight="xs" />
                  <Text size="l" color="success" marginRight="m">
                    Выгода: {discount + (bonusSum[period] || 0)}{' '}
                    <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
                  </Text>
                </>
              )}
              <Text size="h1">
                {price}
                <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
              </Text>
            </Layout>
          </Row>
        </Column>

        <Column
          padding={['m', 'm', 'l', 'm']}
          css={{ borderTop: `1px solid ${theme.colors.secondaryLighter}` }}
        >
          <Row justify="space-between" align="center" marginBottom="m">
            <Layout align="center">
              <Icon type="wallet" marginRight="s" />
              <Text size="l" color="secondary">
                Текущий баланс:
              </Text>
            </Layout>
            <Text size="h2">
              {balance}
              <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
            </Text>
          </Row>

          <RadioGroup
            value={from}
            onChange={setFrom}
            width="100%"
            marginBottom="m"
            css={{
              label: { width: '50%' },
              '>label:nth-of-type(1) > div': { justifyContent: 'flex-start' },
              '>label:nth-of-type(2) > div': { justifyContent: 'flex-end' },
            }}
          >
            <RadioButton value="card">
              <Icon type="card" marginRight="s" />
              <Text size="l" weight={400}>
                Картой
              </Text>
            </RadioButton>
            <RadioButton value="balance">
              <Icon type="wallet" marginRight="s" />
              <Text size="l" weight={400}>
                Со счета
              </Text>
            </RadioButton>
          </RadioGroup>

          {/* <Row align="center" justify="space-between" marginBottom="m">
            <Checkbox checked={autoContinue} onChange={e => setAutoContinue(e.target.checked)}>
              Разрешаю авто продление
            </Checkbox>
            <Info content="Авто продление" />
          </Row> */}

          <Button width="100%" type="dark" onClick={onPurchase}>
            Перейти к оплате
          </Button>
        </Column>
      </>
    );
  }
);

Regular.propTypes = {
  onPurchase: func.isRequired,
  tariffs: object.isRequired,
  balance: number.isRequired,
  period: string.isRequired,
  from: string.isRequired,
  setFrom: func.isRequired,
  autoContinue: bool.isRequired,
  setAutoContinue: func.isRequired,
  onSsmmClick: func.isRequired,
  bonusSum: object.isRequired,
};

export default Regular;
