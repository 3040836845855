/** @jsxImportSource @emotion/react */
import React from 'react';

import branch from '../../images/branch.svg';
import { Container } from '../../ui';

const Branch = () => (
  <Container css={{ position: 'fixed', zIndex: 4 }}>
    <div css={{ position: 'relative' }}>
      <img src={branch} css={{ position: 'absolute', bottom: -55, left: -90 }} className="branch" />
    </div>
  </Container>
);

export default Branch;
