import { Avatar, Layout, Link, Text } from '@ssmm/ui';
import { object } from 'prop-types';
import React from 'react';

const MasterInfo = React.memo(({ master, children, ...rest }) => (
  <Layout align="center" {...rest}>
    <Avatar size={45} src={master?.avatar} marginRight="m" />
    <Layout
      grow={1}
      shrink={1}
      marginRight="s"
      direction="column"
      justify="space-between"
      css={{ minHeight: 45 }}
    >
      <Text marginBottom="xs" color="secondary" size="l" weight={400}>
        {children}
      </Text>
      {master?.link && (
        <Link size="l" href={master.link}>
          {master.name}
        </Link>
      )}
    </Layout>
  </Layout>
));

MasterInfo.propTypes = { master: object };

export default MasterInfo;
