import React from 'react';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Text } from '../text';
import { Layout } from '../layout';
import { commonParser } from '../helpers';
import ssmm from './ssmm';
import boss from './boss';
import { CommonStyleProps } from '../types';
import { CheckboxSize, CheckboxType } from '@ssmm/theme/dist/types/checkbox';

const components = {
  ssmm,
  boss,
};

interface CheckboxElementProps {
  onChange: (e: React.ChangeEvent) => void;
}

const CheckboxElement = styled('input')<CheckboxElementProps>({
  position: 'absolute',
  zIndex: -1,
  opacity: 0,
});

interface WrapProps {
  disabled?: boolean;
}

const Wrap = styled(Layout)<WrapProps>(({ theme, ...rest }) => ({
  ...commonParser({ theme, ...rest }),
}));

interface ComponentProps extends CommonStyleProps {
  type: CheckboxType;
  size?: CheckboxSize | number;
  checkSize?: number;
  disabled?: boolean;
  checked?: boolean;
  onChange: (e: React.ChangeEvent) => void;
}

const Checkbox = React.memo<ComponentProps>(
  ({
    type: typeProp,
    children,
    size = 'm',
    checkSize = 9,
    disabled,
    checked,
    onChange,
    ...rest
  }) => {
    const theme = useTheme();
    const type = typeProp || theme.checkbox.type;

    const { LabelElement, FakeCheckbox, Check } = components[type];

    let boxSize;
    let checkFontSize;

    // если передан размер из стандартных
    if (theme.checkbox.sizes[size]) {
      boxSize =
        theme.dimensions[theme.checkbox.sizes[size].width] || theme.checkbox.sizes[size].width;
      checkFontSize = theme.checkbox.sizes[size].checkFontSize;
      // если передан размер в пикселях
    } else {
      boxSize = size;
      checkFontSize = checkSize;
    }

    return (
      <Wrap disabled={disabled} {...rest}>
        <LabelElement disabled={disabled} checked={checked}>
          <CheckboxElement
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={onChange}
          />
          <FakeCheckbox checked={checked} size={boxSize}>
            {checked && <Check type="check" set="ssmm" size={checkFontSize} />}
          </FakeCheckbox>
          {children && (
            <Text
              css={{
                ...theme.input.label.css,
                marginBottom: 0,
                marginLeft: theme.dimensions.s,
                transition: '.2s',
                fontWeight: 400,
              }}
            >
              {children}
            </Text>
          )}
        </LabelElement>
      </Wrap>
    );
  }
);

export default Checkbox;
