import 'firebase/database';

import { logout } from 'components/authorization/actions';
import firebase from 'firebase/compat/app';

import { UPDATE_USER } from '../types';

export const userSubscribe = uid => async (dispatch, getState) => {
  try {
    const profile = { uid };

    firebase
      .database()
      .ref(`PARTNER_AREA/user/${uid}`)
      .on('value', snap => {
        const partnerSettings = snap.val() || {};

        dispatch({
          type: UPDATE_USER,
          payload: { partnerSettings },
        });
      });

    firebase
      .database()
      .ref(`users/${uid}`)
      .on('value', snap => {
        const { PROFILE, TARIFPLAN } = snap.val();

        profile.dateCreate = TARIFPLAN.date_create;
        profile.partner_owner_id = PROFILE.partner_owner_id;
        profile.name = PROFILE.name;
        profile.avatar = PROFILE.photo;
        profile.partner_code = PROFILE.partner_code;
        profile.tariff = TARIFPLAN.VKBOSS;
        profile.firstRunDate = PROFILE.vkbossFirstRunDate || 0;
        profile.auth_id_owner = PROFILE.auth_id_owner;
        profile.partner_owner_id = PROFILE.partner_owner_id;

        dispatch({
          type: UPDATE_USER,
          payload: profile,
        });
      });

    firebase
      .database()
      .ref(`BALANCE/${uid}/r_balance`)
      .on('value', snapshot => {
        const profile = getState().auth.profile || {};
        const newBalance = profile.balance || {};
        newBalance.balanceR = snapshot.exists() ? Math.floor(+snapshot.val() / 100) : 0;
        newBalance.balanceTotal =
          newBalance.balanceR + (newBalance.balanceP || 0) + (newBalance.balanceB || 0);
        newBalance.balanceAvailable = newBalance.balanceR + (newBalance.balanceP || 0);

        dispatch({
          type: UPDATE_USER,
          payload: { balance: newBalance },
        });
      });
    firebase
      .database()
      .ref(`BALANCE/${uid}/b_balance`)
      .on('value', snapshot => {
        const profile = getState().auth.profile || {};
        const newBalance = profile.balance || {};
        newBalance.balanceB = snapshot.exists() ? Math.floor(+snapshot.val() / 100) : 0;
        newBalance.balanceTotal =
          (newBalance.balanceR || 0) + (newBalance.balanceP || 0) + newBalance.balanceB;

        dispatch({
          type: UPDATE_USER,
          payload: { balance: newBalance },
        });
      });
    firebase
      .database()
      .ref(`BALANCE/${uid}/p_balance`)
      .on('value', snapshot => {
        const profile = getState().auth.profile || {};
        const newBalance = profile.balance || {};
        newBalance.balanceP = snapshot.exists() ? Math.floor(+snapshot.val() / 100) : 0;
        newBalance.balanceTotal =
          (newBalance.balanceR || 0) + newBalance.balanceP + (newBalance.balanceB || 0);
        newBalance.balanceAvailable = (newBalance.balanceR || 0) + newBalance.balanceP;

        dispatch({
          type: UPDATE_USER,
          payload: { balance: newBalance },
        });
      });
  } catch (e) {
    console.info(e.message);

    dispatch(logout());
  }
};

export const userUnsubscribe = () => dispatch => {
  firebase.database().ref(`users`).off();
  firebase.database().ref(`BALANCE`).off();

  dispatch({
    type: UPDATE_USER,
    payload: null,
  });
};

export const handleUser = user => {
  const { partner_code, tariff, partnerSettings } = user;

  if (!partner_code || !partnerSettings) {
    return user;
  }

  const dateNow = Date.now();

  if (!tariff) {
    user.tariff = {
      ai: { active: false },
      autoLike: { active: false },
      partnerArea: { active: false },
    };
  } else {
    const { ai, autoLike, partnerArea } = user.tariff;

    if (ai && ai.date_end) {
      ai.dateStart = ai.date_start;
      ai.dateEnd = ai.date_end;
      ai.active = dateNow < ai.dateEnd;
    } else {
      user.tariff.ai = { active: false };
    }

    if (autoLike && autoLike.date_end) {
      autoLike.dateStart = autoLike.date_start;
      autoLike.dateEnd = autoLike.date_end;
      autoLike.active = dateNow < autoLike.dateEnd;
    } else {
      user.tariff.autoLike = { active: false };
    }

    if (partnerArea && partnerArea.date_end) {
      partnerArea.dateEnd = partnerArea.date_end;
      partnerArea.active = dateNow < partnerArea.dateEnd;
    } else {
      user.tariff.partnerArea = { active: false };
    }
  }

  user.tariff.partnerBusiness = { active: false };
  user.tariff.partnerBusinessVip = { active: false };

  const { tariff_id, date_end } = user.partnerSettings;

  if (tariff_id) {
    switch (tariff_id) {
      case 'first': {
        user.tariff.partnerArea.dateEnd = date_end;
        user.tariff.partnerArea.active = dateNow < date_end;

        break;
      }

      case 'second': {
        user.tariff.partnerBusiness.dateEnd = date_end;
        user.tariff.partnerBusiness.active = dateNow < date_end;

        break;
      }

      case 'third': {
        user.tariff.partnerBusinessVip.dateEnd = date_end;
        user.tariff.partnerBusinessVip.active = dateNow < date_end;

        break;
      }

      default:
        break;
    }
  }

  return user;
};
