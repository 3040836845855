/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';

import colors from './colors';

const HeadingH1 = styled('h1')({
  fontFamily: 'Open Sans',
  fontSize: 30,
  lineHeight: '49px',
  fontWeight: 600,
  color: colors.black,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  textTransform: 'uppercase',
  marginBottom: 0,
  display: 'inline',
});

const HeadingH2 = styled('h2')({
  fontFamily: 'Open Sans',
  fontSize: 26,
  lineHeight: '36px',
  fontWeight: 600,
  color: colors.black,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  textTransform: 'uppercase',
  marginBottom: 0,
  display: 'inline',
});

const HeadingH3 = styled('h3')({
  fontFamily: 'Open Sans',
  fontSize: 20,
  lineHeight: '27px',
  fontWeight: 600,
  color: colors.black,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  marginBottom: 0,
  display: 'inline',
});

const HeadingH4 = styled('h4')({
  fontFamily: 'Open Sans',
  fontSize: 18,
  lineHeight: '30px',
  fontWeight: 600,
  textTransform: 'uppercase',
  color: colors.black,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  marginBottom: 0,
  display: 'inline',
});

const Text = styled('span')({
  fontFamily: 'Open Sans',
  fontSize: 16,
  lineHeight: '22px',
  fontWeight: 400,
  color: colors.black,
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
});

const TextBold = styled(Text)({ fontWeight: 600 });

const TextItalic = styled(Text)({ fontStyle: 'italic' });

const TextSmall = styled(Text)({
  fontSize: 14,
  lineHeight: '23px',
  color: colors.grayLight,
});

const Link = ({ children, href, onClick, ...rest }) =>
  href ? (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      css={{
        fontFamily: 'Open Sans',
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 400,
        color: colors.black,
        whiteSpace: 'pre-line',
        wordBreak: 'break-word',
        transition: '.2s',
        ':hover': { color: colors.blue },
      }}
    >
      {children}
    </a>
  ) : (
    <Text
      onClick={onClick}
      css={{ cursor: 'pointer', transition: '.2s', ':hover': { color: colors.blue } }}
    >
      {children}
    </Text>
  );

export { HeadingH1, HeadingH2, HeadingH3, HeadingH4, Link, Text, TextBold, TextItalic, TextSmall };
