/** @jsxImportSource @emotion/react */
import { useTheme } from '@ssmm/theme';
import {
  Button,
  Column,
  HeadingH2,
  Icon,
  Info,
  Layout,
  Modal,
  Radio,
  Row,
  Select,
  Spin,
  Text,
} from '@ssmm/ui';
import { func, number, object } from 'prop-types';
import React, { useState } from 'react';

const { RadioGroup, RadioButton } = Radio;

const Package2 = React.memo(({ onClose, tariffs, balance, chargeUserOld }) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('m1');
  const [from, setFrom] = useState('card');
  const [autoContinue, setAutoContinue] = useState(true);

  const price = tariffs.autoLike.price[period];
  const discount = tariffs.autoLike.price.m1 * Number(period.replace('m', '')) - price;

  const handlePurchaseClick = async () => {
    setLoading(true);

    await chargeUserOld({
      fromBalance: from === 'balance',
      description: tariffs.autoLike.title,
      amount: price,
      data: {
        rate: 'autoLike',
        period,
        purchase: 'tariff_activation',
      },
      autoContinue,
    });

    setLoading(false);
  };

  return (
    <Modal
      title={
        <Row justify="space-between" align="center">
          <HeadingH2>Покупка пакета №2</HeadingH2>
        </Row>
      }
      width={520}
      onClose={onClose}
      getContainer={() => document.getElementById('root')}
    >
      <Spin spinning={loading} mask={false} direction="column" padding="xs" scroll>
        <Column padding="m" css={{ borderBottom: `1px solid ${theme.colors.secondaryLighter}` }}>
          <RadioGroup
            value={period}
            onChange={setPeriod}
            size="xl"
            width="100%"
            marginBottom="l"
            css={{ label: { width: '25%' } }}
          >
            <RadioButton value="m1">1 месяц</RadioButton>
            <RadioButton value="m3">
              <Column align="center" justify="center">
                <Text size="h3">3 месяца</Text>
                <Text weight={600} css={{ color: `${theme.colors.success} !important` }}>
                  -10%
                </Text>
              </Column>
            </RadioButton>
            <RadioButton value="m6">
              <Column align="center" justify="center">
                <Text size="h3">6 месяцев</Text>
                <Text weight={600} css={{ color: `${theme.colors.success} !important` }}>
                  -20%
                </Text>
              </Column>
            </RadioButton>
            <RadioButton value="m12">
              <Column align="center" justify="center">
                <Text size="h3">На год</Text>
                <Text weight={600} css={{ color: `${theme.colors.success} !important` }}>
                  -30%
                </Text>
              </Column>
            </RadioButton>
          </RadioGroup>

          <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
            <Layout align="center">
              <Icon type="likeFilled" color="primary" size={22} marginRight="m" />
              <Text size="l">Привлечение внимания</Text>
            </Layout>
            <Info content="Привлечение внимания" />
          </Row>

          <Row justify="space-between" align="center" marginBottom="s" css={{ minHeight: 32 }}>
            <Layout align="center">
              <Icon type="userAddedFilled" color="primary" size={22} marginRight="m" />
              <Text size="l">Авто подписка</Text>
            </Layout>
            <Info content="Авто подписка" />
          </Row>

          <Row justify="space-between" align="center" marginBottom="l" css={{ minHeight: 32 }}>
            <Layout align="center">
              <Icon type="likeCommentFilled" color="primary" size={22} marginRight="m" />
              <Text size="l">Сбор ЦА</Text>
            </Layout>
            <Info content="Сбор ЦА" />
          </Row>

          <Row
            padding="s"
            align="center"
            justify="space-between"
            css={{ border: `1px dashed ${theme.colors.secondary}` }}
          >
            <Text size="l">К оплате:</Text>

            <Select value={period} disabled width={105}>
              <Select.Option key="m1" value="m1">
                1 месяц
              </Select.Option>
              <Select.Option key="m3" value="m3">
                3 месяца
              </Select.Option>
              <Select.Option key="m6" value="m6">
                6 месяцев
              </Select.Option>
              <Select.Option key="m12" value="m12">
                Год
              </Select.Option>
            </Select>

            <Layout align="center">
              {discount > 0 && (
                <>
                  <Icon type="rubleReturn" size={22} color="success" marginRight="xs" />
                  <Text size="l" color="success" marginRight="m">
                    Выгода: {discount} <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
                  </Text>
                </>
              )}
              <Text size="h1">
                {price}
                <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
              </Text>
            </Layout>
          </Row>
        </Column>

        <Column padding={['m', 'm', 'l', 'm']}>
          <Row justify="space-between" align="center" marginBottom="m">
            <Layout align="center">
              <Icon type="wallet" marginRight="s" />
              <Text size="l" color="secondary">
                Текущий баланс:
              </Text>
            </Layout>
            <Text size="h2">
              {balance}
              <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
            </Text>
          </Row>

          <RadioGroup
            value={from}
            onChange={setFrom}
            width="100%"
            marginBottom="m"
            css={{
              label: { width: '50%' },
              '>label:nth-of-type(1) > div': { justifyContent: 'flex-start' },
              '>label:nth-of-type(2) > div': { justifyContent: 'flex-end' },
            }}
          >
            <RadioButton value="card">
              <Icon type="card" marginRight="s" />
              <Text size="l" weight={400}>
                Картой
              </Text>
            </RadioButton>
            <RadioButton value="balance">
              <Icon type="wallet" marginRight="s" />
              <Text size="l" weight={400}>
                Со счета
              </Text>
            </RadioButton>
          </RadioGroup>

          {/* <Row align="center" justify="space-between" marginBottom="m">
            <Checkbox checked={autoContinue} onChange={e => setAutoContinue(e.target.checked)}>
              Разрешаю авто продление
            </Checkbox>
            <Info content="Авто продление" />
          </Row> */}

          <Button width="100%" type="dark" onClick={handlePurchaseClick}>
            Перейти к оплате
          </Button>
        </Column>
      </Spin>
    </Modal>
  );
});

Package2.propTypes = {
  onClose: func.isRequired,
  tariffs: object.isRequired,
  chargeUserOld: func.isRequired,
  balance: number.isRequired,
};

export default Package2;
