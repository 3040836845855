import { connect } from 'react-redux';

import * as authUiActions from '../actions';
import Component from '../component';

const mapStateToProps = state => ({
  loading: state.loaders.auth,
  ...state.authUi,
});

export default connect(mapStateToProps, authUiActions)(Component);
