const initialState = {
  purchaseOpen: false,
  specialOfferAvailable: false,
};

export default function purchase(state = initialState, action) {
  switch (action.type) {
    case 'PURCHASE_TOGGLE':
      return { ...state, purchaseOpen: !state.purchaseOpen };

    case 'PURCHASE_SET_SPECIAL_OFFER_AVAILABLE':
      return { ...state, specialOfferAvailable: action.specialOfferAvailable };

    default:
      return state;
  }
}
