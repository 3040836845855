import 'firebase/compat/database';

import firebase from 'firebase/compat/app';

/*
const loadUserNetStructure = netStructure => ({
  type: types.PAGE_STRUCTURE_MOUNT,
  netStructure,
});

const loadUserParentInfo = (name, vkAcc) => ({
  type: types.FETCH_USER_PARENT_NAME,
  name,
  vkAcc,
});

export const loadSettings = () => async dispatch => {
  const settings = {};

  await Promise.all([
    firebase
      .database()
      .ref('SETTINGS/paymentMethod')
      .once('value', res => {
        settings.paymentMethod = res.val() || 'cloudpayment';
      }),
    firebase
      .database()
      .ref('SETTINGS/TARIFPLAN')
      .once('value', res => {
        settings.rates = res.val();
      }),
    firebase
      .database()
      .ref(`SETTINGS/SMTOKEN/rate`)
      .once('value', result => {
        settings.tokenCurrentPrice = result.val() / 100;
      }),
    firebase
      .database()
      .ref(`SETTINGS/GROUPS`)
      .once('value', result => {
        settings.groups = result.val();
      }),
    firebase
      .database()
      .ref(`SETTINGS/LK`)
      .once('value', result => {
        const lkSettings = result.val();

        settings.base_url = lkSettings.domain.main;
        settings.manualInstall = lkSettings.manualInstall;
      }),
  ]);

  dispatch({
    type: types.LOAD_SETTINGS,
    settings,
  });
};

export const loadPartnerSettings = () => async dispatch => {
  const { authId } = store.getState().auth.profile;

  const [partnerSettings, total_spent, hasRecruits] = await Promise.all([
    firebase
      .database()
      .ref(`SETTINGS/PARTNERSETTINGS`)
      .once('value')
      .then(result => result.val()),
    firebase
      .database()
      .ref(`BALANCE/${authId}/total_spent`)
      .once('value')
      .then(result => result.val() || 0),
    firebase
      .database()
      .ref(`BALANCE/${authId}/RECRUITS/line_1`)
      .limitToFirst(1)
      .once('value')
      .then(result => result.exists()),
  ]);

  dispatch({
    type: types.LOAD_SETTINGS,
    settings: { partnerSettings },
  });

  dispatch({
    type: 'UPDATE_PROFILE',
    data: { total_spent, hasRecruits },
  });
};

export const fetchUserParentInfo = authId => {
  return async function(dispatch) {
    dispatch({ type: types.USER_PARENTNAME_REQ });

    const [name, vkAcc] = [
      await dispatch(fetchUserData(authId, 'PROFILE', 'name')),
      await dispatch(fetchUserData(authId, 'PROFILE', 'partner_code')),
    ];

    dispatch({ type: types.USER_PARENTNAME_RES });
    dispatch(loadUserParentInfo(name, vkAcc));
  };
};
*/

export const loadSettings = () => async dispatch => {
  dispatch({
    type: 'SETTINGS_REQ',
  });

  const settings = {};

  await Promise.all([
    firebase
      .database()
      .ref('SETTINGS/PARTNERSETTINGS')
      .once('value')
      .then(res => {
        settings.partnerSettings = res.val();
      }),
    firebase
      .database()
      .ref('VKBOSS/SETTINGS/TARIFPLAN')
      .once('value')
      .then(res => {
        settings.tariffs = res.val();
      }),
    firebase
      .database()
      .ref('VKBOSS/SETTINGS/extension')
      .once('value')
      .then(res => {
        settings.globalSettings = res.val();
      }),
    firebase
      .database()
      .ref('VKBOSS/SETTINGS/paymentMethod')
      .once('value')
      .then(res => {
        settings.paymentMethod = res.val();
      }),
    firebase
      .database()
      .ref('SETTINGS/PARTNER_TARIFF')
      .once('value')
      .then(res => {
        settings.partnerTariffs = res.val();
      }),
  ]);

  dispatch({
    type: 'SETTINGS_RES',
    settings,
  });
};

export const fetchUserData = (authId, path, key) => async () =>
  firebase
    .database()
    .ref(`users/${authId}/${path}/${key}`)
    .once('value')
    .then(result => result.val());
