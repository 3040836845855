const initialState = {
  paymentDetailsOpen: false,
  selectedPurchase: null,
};

export default function paymentDetails(state = initialState, action) {
  switch (action.type) {
    case 'PAYMENT_DETAILS_OPEN':
      return { ...state, paymentDetailsOpen: true, selectedPurchase: action.selectedPurchase };

    case 'PAYMENT_DETAILS_CLOSE':
      return initialState;

    default:
      return state;
  }
}
