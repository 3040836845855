import styled from '@emotion/styled';
import { commonParser } from '../helpers';
import { CommonStyleProps, Theme, CSSObject } from '../types';
import { Color } from '@ssmm/theme/dist/types/colors';
import rfdc from 'rfdc';

const clone = rfdc({ proto: true });

export interface ComponentProps extends CommonStyleProps {
  scroll?: boolean;
  scrollColor?: Color;
  overflow?: string;
  minHeight?: string | number;
  maxHeight?: string | number;
  background?: string;
}

const parseProps = ({ theme, ...styleProps }: { theme: Theme }) => {
  const styleObj: CSSObject = {};

  Object.keys(styleProps).forEach(prop => {
    const value = styleProps[prop];

    switch (prop) {
      case 'overflow':
      case 'minHeight':
        styleObj[prop] = value;
        break;

      case 'minWidth':
        styleObj[prop] = value;
        break;

      case 'maxHeight':
        styleObj[prop] = value;
        break;

      case 'background':
        styleObj.background = theme.colors[value] || value;
        break;

      default:
        break;
    }
  });

  return styleObj;
};

const Layout = styled('div')<ComponentProps>(({ theme, scroll, scrollColor, ...rest }) => {
  let props: CSSObject = {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 0,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    boxSizing: 'border-box',
    whiteSpace: 'pre-line',
  };

  if (scroll) {
    // @ts-expect-error: для скролла используется overflowY: 'overlay'
    props = {
      ...props,
      ...theme.misc.scroll,
      flexShrink: 1,
    };

    if (scrollColor) {
      props = clone(props);

      // @ts-expect-error яхз
      props['::-webkit-scrollbar-thumb'].color = theme.colors[scrollColor];
    }
  }

  props = { ...props, ...commonParser({ theme, ...rest }), ...parseProps({ theme, ...rest }) };

  return props;
});

const Column = styled(Layout)({
  flexDirection: 'column',
  width: '100%',
});

const Row = styled(Layout)({
  width: '100%',
});

export { Column, Row, Layout };
