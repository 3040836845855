import Misc from '../../types/misc';
import colors from './colors';
import dimensions from './dimensions';

const misc: Misc = {
  borderRadius: 4,
  iconSet: 'storysend',
  iconDefaultSize: dimensions.m,
  closeIcon: 'close',
  iconColor: 'secondaryDarker',
  infoIconColor: 'secondaryDarker',
  modalType: 'boss',
  switchType: 'boss',
  radioType: 'boss',
  tabsType: 'boss',
  scroll: {
    overflow: 'hidden auto',
    overflowY: 'overlay',
    '::-webkit-scrollbar': { width: 20 },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 20,
      boxShadow: `inset 0 0 10px 10px`,
      color: colors.primary,
      border: `solid 5px transparent`,
    },
    '::-webkit-scrollbar-track': {
      border: `5px solid transparent`,
      boxShadow: `inset 0 0 10px 10px ${colors.secondaryLighter}`,
      borderRadius: 20,
    },
  },
};

export default misc;
