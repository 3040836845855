import { Icon, Info, Layout, Row, Text } from '@ssmm/ui';
import { number } from 'prop-types';
import React from 'react';

const M12 = React.memo(({ discount }) => (
  <>
    <Row justify="space-between" align="center" css={{ minHeight: 32 }} marginBottom="s">
      <Layout align="center">
        <Icon type="groupFilled" color="success" size={22} marginRight="m" />
        <Text size="l" marginRight="s">
          Партнерский кабинет
        </Text>
        <Info content="Партнерский кабинет" />
      </Layout>
      <Text size="l" color="success">
        На год
      </Text>
    </Row>

    <Row justify="space-between" align="center" css={{ minHeight: 32 }} marginBottom="s">
      <Layout align="center">
        <Icon type="plus" color="success" size={22} marginRight="m" />
        <Text size="l" marginRight="s">
          Дополнительный пакет №1 + пакет №2
        </Text>
        <Info content="Дополнительный пакет №1 + пакет №2" />
      </Layout>
      <Text size="l" color="success">
        2 месяца
      </Text>
    </Row>

    <Row justify="space-between" align="center" css={{ minHeight: 32 }} marginBottom="l">
      <Layout align="center">
        <Icon type="percent" color="success" size={22} marginRight="m" />
        <Text size="l" marginRight="s">
          Скидка
        </Text>
        <Info content="Скидка" />
      </Layout>
      <Text size="l" color="success">
        {discount} <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
      </Text>
    </Row>
  </>
));

M12.propTypes = { discount: number.isRequired };

export default M12;
