import React from 'react';
import Icon from '../icon';
import { Text } from '../text';
import { Column, Row } from '../layout';

export type Key = string | number;

export interface ComponentProps {
  key: Key;
  children: React.ReactNode;
  title: string;
  open?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const Panel = React.memo<ComponentProps>(({ children, title, open = false, onClick }) => (
  <Column
    css={theme => ({
      borderBottom: `1px solid ${theme.colors.secondaryLighter}`,
      paddingBottom: open ? theme.dimensions.m : undefined,
    })}
  >
    <Row
      paddingTop="m"
      paddingBottom="s"
      align="center"
      onClick={onClick}
      css={theme => ({
        cursor: 'pointer',
        '>i, >span,>div >span': {
          color: open ? theme.colors.primary : undefined,
        },
        ':hover': {
          '>i, >span,>div >span': {
            color: !open ? theme.colors.primary : undefined,
            transition: '.2s',
          },
        },
      })}
    >
      <Icon
        type={open ? 'up' : 'down'}
        set="ssmm"
        color="secondaryDarker"
        size={8}
        marginLeft="xs"
        marginRight="m"
      />
      {typeof title === 'string' ? <Text size="l">{title}</Text> : title}
    </Row>

    {open && children}
  </Column>
));

export default Panel;
