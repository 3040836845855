import React from 'react';
import styled from '@emotion/styled';
import { Text } from '../text';
import { commonParser } from '../helpers';
import { colorScheme } from '../notification';

const Container = styled('div')(({ theme, borderColor, backgroundColor, borderless, ...rest }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 0,
  flexShrink: 0,
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'border-box',
  whiteSpace: 'pre-line',
  background: theme.colors[backgroundColor] || backgroundColor,
  borderColor: theme.colors[borderColor] || borderColor,
  borderStyle: 'solid',
  borderWidth: 1,
  borderRadius: theme.misc.borderRadius,
  padding: `${theme.dimensions.xs}px ${theme.dimensions.l}px`,
  ...commonParser({ theme, ...rest }),
}));

const NotificationMini = React.memo(
  ({ type = 'warning', borderColor, textColor, backgroundColor, children, ...rest }) => {
    const colors = colorScheme[type] || colorScheme.warning;

    return (
      <Container
        type={type}
        backgroundColor={backgroundColor || colors.backgroundColor}
        borderColor={borderColor || colors.borderColor}
        {...rest}
      >
        {typeof children === 'string' ? (
          <Text color={textColor || colors.textColor} size="l">
            {children}
          </Text>
        ) : (
          children
        )}
      </Container>
    );
  }
);

export default NotificationMini;
