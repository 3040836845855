import { CheckboxType } from '../../types/checkbox';

const sizes = {
  m: {
    width: 'm',
    checkFontSize: 9,
  },
};

const type: CheckboxType = 'boss';

export default { sizes, type };
