import styled from '@emotion/styled';
import { Row, Layout } from '../../layout';
import { commonParser } from '../../helpers';

const Container = styled(Row)(({ theme, ...rest }) => ({
  borderBottom: `1px solid ${theme.colors.secondaryLighter}`,
  '>div:not(:first-of-type)': { marginLeft: 1 },
  '>div:last-of-type:not(:first-of-type)': {
    paddingRight: 0,
    justifyContent: 'flex-end',
  },
  '>div:first-of-type:not(:last-of-type)': {
    paddingLeft: 0,
    justifyContent: 'flex-start',
  },
  ...commonParser({ theme, ...rest }),
}));

const Tab = styled(Layout)(({ theme, active, disabled }) => {
  const styleObj = {
    minHeight: 40,
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `0 ${theme.dimensions.xl}px ${theme.dimensions.s}px ${theme.dimensions.xl}px`,
    '>span': theme.fonts.h3,
    '>span,>i': { transition: '.2s' },
    ':hover': { '>span,>i': { color: theme.colors.primary } },
    ':after': {
      position: 'absolute',
      content: "''",
      width: '100%',
      height: 5,
      background: theme.colors.primary,
      bottom: -1,
      left: 0,
      transition: '.2s',
      opacity: 0,
    },
  };

  if (active) {
    styleObj[':after'].opacity = 1;
    styleObj.cursor = 'default';
    styleObj['>span,>i'].color = theme.colors.primary;
  } else {
    styleObj[':hover'][':after'] = { opacity: 1 };
    styleObj.cursor = 'pointer';
    styleObj['>span,>i'].color = theme.fonts.m.color;
  }

  if (disabled) {
    styleObj['>span,>i'].color = theme.colors.secondary;
    styleObj.pointerEvents = 'none';
    styleObj[':after'].background = theme.colors.secondary;
  }

  return styleObj;
});

export default { Container, Tab };
