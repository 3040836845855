import React from 'react';
import { Layout } from '../layout';
import styled from '@emotion/styled';
import MenuItem from './menu-item';
import { commonParser } from '../helpers';

const Container = styled(Layout)(({ theme, ...rest }) => ({
  width: '100%',
  flexDirection: 'column',
  ...commonParser({ theme, ...rest }),
}));

const Menu = React.memo(({ items, current, onChange }) => (
  <Container>
    {items.map(item => (
      <MenuItem key={item.id} item={item} current={current} onChange={onChange} />
    ))}
  </Container>
));

Menu.defaultProps = { items: [] };

export default Menu;
