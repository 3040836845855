import styled from '@emotion/styled';
import { commonParser } from '../helpers';
import { CommonStyleProps, Theme, CSSObject } from '../types';

interface ComponentProps extends Omit<CommonStyleProps, 'align'> {
  color?: string;
  align?: string;
}

const parseProps = ({ theme, ...styleProps }: { theme: Theme }) => {
  const styleObj: CSSObject = {};

  Object.keys(styleProps).forEach(prop => {
    const value = styleProps[prop];

    switch (prop) {
      case 'color':
        styleObj.color = theme.colors[value] || value;
        break;

      case 'align':
        styleObj.textAlign = value;
        break;

      default:
        break;
    }
  });

  return styleObj;
};

const HeadingH1 = styled('h1')<ComponentProps>(({ theme, ...rest }) => ({
  margin: 0,
  padding: 0,
  border: 'none',
  ...theme.fonts.h1,
  ...commonParser({ theme, ...rest }),
  ...parseProps({ theme, ...rest }),
}));

const HeadingH2 = styled('h2')<ComponentProps>(({ theme, ...rest }) => ({
  margin: 0,
  padding: 0,
  border: 'none',
  ...theme.fonts.h2,
  ...commonParser({ theme, ...rest }),
  ...parseProps({ theme, ...rest }),
}));

const HeadingH3 = styled('h3')<ComponentProps>(({ theme, ...rest }) => ({
  margin: 0,
  padding: 0,
  border: 'none',
  ...theme.fonts.h3,
  ...commonParser({ theme, ...rest }),
  ...parseProps({ theme, ...rest }),
}));

const HeadingH4 = styled('h4')<ComponentProps>(({ theme, ...rest }) => ({
  margin: 0,
  padding: 0,
  border: 'none',
  ...theme.fonts.h4,
  ...commonParser({ theme, ...rest }),
  ...parseProps({ theme, ...rest }),
}));

export { HeadingH1, HeadingH3, HeadingH2, HeadingH4 };
