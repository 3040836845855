import colors from './colors';

const fonts = {
  mainFont: 'Open Sans, Sans-Serif',
  mainHeadingFont: 'Source Sans Pro, Sans-Serif',
  linkColor: colors.primary,
  linkColorHover: colors.primary,
  h1: {},
  h2: {
    fontSize: 23,
    lineHeight: 1.26,
    color: colors.secondaryDark,
    fontWeight: 700,
    letterSpacing: '0.5px',
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  h3: {
    fontSize: 18,
    lineHeight: 1.28,
    color: colors.secondaryDark,
    fontWeight: 700,
    letterSpacing: '0.5px',
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  h4: {
    fontSize: 16,
    lineHeight: 1.25,
    color: colors.secondaryDark,
    fontWeight: 600,
    letterSpacing: '0.5px',
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
  s: {
    fontSize: 10,
    lineHeight: 1.5,
    color: colors.secondary,
    fontWeight: 400,
    fontFamily: 'Open Sans, Sans-Serif',
  },
  m: {
    fontSize: 12,
    lineHeight: 1.67,
    color: colors.secondary,
    fontWeight: 400,
    fontFamily: 'Open Sans, Sans-Serif',
  },
  l: {
    fontSize: 14,
    lineHeight: 1.67,
    color: colors.secondary,
    fontWeight: 600,
    fontFamily: 'Open Sans, Sans-Serif',
  },
  xxl: {
    fontSize: 18,
    lineHeight: 1.28,
    color: colors.secondaryDark,
    fontWeight: 700,
    letterSpacing: '0.5px',
    fontFamily: 'Source Sans Pro, Sans-Serif',
  },
};

export default fonts;
