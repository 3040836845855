import { logout } from 'components/authorization/actions';
import { connect } from 'react-redux';

import * as actions from '../actions';
import LK from '../component';

const mapStateToProps = state => ({
  authState: state.auth.state,
  purchaseOpen: state.purchase.purchaseOpen,
  user: state.user,
  settings: state.settings,
});

export default connect(mapStateToProps, { logout, ...actions })(LK);
