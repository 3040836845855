import React from 'react';
import styled from '@emotion/styled';
import { Row } from '../layout';
import Icon from '../icon';
import { HeadingH2, Text } from '../text';
import { commonParser } from '../helpers';

export const colorScheme = {
  warning: { borderColor: 'warning', textColor: 'warningDark', backgroundColor: 'warningLight' },
  success: { borderColor: 'success', textColor: 'successDark', backgroundColor: 'successLight' },
  danger: { borderColor: 'danger', textColor: 'dangerDark', backgroundColor: 'dangerLight' },
};

const Container = styled('div')(({ theme, borderColor, backgroundColor, borderless, ...rest }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 0,
  flexShrink: 0,
  maxWidth: '100%',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  boxSizing: 'border-box',
  whiteSpace: 'pre-line',
  background: theme.colors[backgroundColor] || backgroundColor,
  borderColor: theme.colors[borderColor] || borderColor,
  borderStyle: 'solid',
  borderWidth: `1px ${borderless ? 0 : 1}px`,
  padding: theme.dimensions.l,
  ...commonParser({ theme, ...rest }),
}));

const Notification = React.memo(
  ({
    type = 'warning',
    borderColor,
    textColor,
    backgroundColor,
    title,
    content,
    icon = 'warning',
    iconSet = 'boss',
    borderless = false,
    ...rest
  }) => {
    const colors = colorScheme[type] || colorScheme.warning;

    return (
      <Container
        type={type}
        borderless={borderless}
        backgroundColor={backgroundColor || colors.backgroundColor}
        borderColor={borderColor || colors.borderColor}
        {...rest}
      >
        {(icon || title) && (
          <Row align="center" marginBottom={content ? 's' : undefined}>
            {icon && (
              <Icon
                type={icon}
                set={iconSet}
                color={textColor || colors.textColor}
                marginRight="s"
                size={22}
              />
            )}
            {title &&
              (typeof title === 'string' ? (
                <HeadingH2 key="title" color={textColor || colors.textColor}>
                  {title}
                </HeadingH2>
              ) : (
                title
              ))}
          </Row>
        )}
        {typeof content === 'string' ? (
          <Text color={textColor || colors.textColor}>{content}</Text>
        ) : (
          content
        )}
      </Container>
    );
  }
);

export default Notification;
