/** @jsxImportSource @emotion/react */
import { useTheme } from '@ssmm/theme';
import { Column, HeadingH2, Modal, Radio, Row, Spin, Text } from '@ssmm/ui';
import { func, number, object } from 'prop-types';
import React, { useState } from 'react';

import Top from '../../components/top';
import Combo from './combo';
import Regular from './regular';

const { RadioGroup, RadioButton } = Radio;

const bonusSum = {
  m3: 600,
  m6: 1350,
  m12: 3900,
};

const Package1plus2 = React.memo(({ onClose, tariffs, balance, chargeUserOld, onSsmmClick }) => {
  const theme = useTheme();

  const [loading, setLoading] = useState(false);
  const [period, setPeriod] = useState('m3');
  const [from, setFrom] = useState('card');
  const [autoContinue, setAutoContinue] = useState(true);

  const handlePurchaseRegularClick = async () => {
    setLoading(true);

    await chargeUserOld({
      fromBalance: from === 'balance',
      description: tariffs.fullPackage.title,
      amount: tariffs.fullPackage.price[period],
      data: {
        rate: 'fullPackage',
        period,
        purchase: 'tariff_activation',
      },
      autoContinue,
    });

    setLoading(false);
  };

  const handlePurchaseComboClick = async period => {
    setLoading(true);

    await chargeUserOld({
      fromBalance: from === 'balance',
      description: tariffs.combo.title,
      amount: tariffs.combo.price[period],
      data: {
        pack: 'april2020',
        period,
        purchase: 'special_pack',
      },
      autoContinue,
      baseUrl: 'https://super-smm.com/paymentYandexRedirect',
    });

    setLoading(false);
  };

  const discount = {
    m3: tariffs.fullPackage.price.m1 * 3 - tariffs.fullPackage.price.m3,
    m6: tariffs.fullPackage.price.m1 * 6 - tariffs.fullPackage.price.m6,
    m12: tariffs.fullPackage.price.m1 * 12 - tariffs.fullPackage.price.m12,
  };

  return (
    <>
      <Modal
        title={
          <Row justify="space-between" align="center">
            <HeadingH2>
              {period === 'combo' ? 'Комбо пакет 2 сервиса сразу' : 'Полный пакет №1 + №2'}
            </HeadingH2>
          </Row>
        }
        width={600}
        onClose={onClose}
        getContainer={() => document.getElementById('root')}
      >
        <Spin spinning={loading} mask={false} direction="column" padding="xs" scroll>
          <RadioGroup
            value={period}
            onChange={setPeriod}
            size="xl"
            width="100%"
            padding={['m', 'm', 'l', 'm']}
            css={{ label: { width: '25%', position: 'relative' } }}
          >
            <RadioButton value="m3">
              <Column align="center" justify="center">
                <Text size="h3">3 месяца</Text>
                <Text weight={600} css={{ color: `${theme.colors.success} !important` }}>
                  Выгода {discount.m3 + bonusSum.m3}
                  <span
                    css={{
                      fontFamily: 'SFUIText-Regular',
                      color: `${theme.colors.success} !important`,
                    }}
                  >
                    ₽
                  </span>
                </Text>
              </Column>
            </RadioButton>
            <RadioButton value="m6">
              <Column align="center" justify="center">
                <Text size="h3">6 месяцев</Text>
                <Text weight={600} css={{ color: `${theme.colors.success} !important` }}>
                  Выгода {discount.m6 + bonusSum.m6}
                  <span
                    css={{
                      fontFamily: 'SFUIText-Regular',
                      color: `${theme.colors.success} !important`,
                    }}
                  >
                    ₽
                  </span>
                </Text>
              </Column>
            </RadioButton>
            <RadioButton value="m12">
              <Column align="center" justify="center">
                <Text size="h3">На год</Text>
                <Text weight={600} css={{ color: `${theme.colors.success} !important` }}>
                  Выгода {discount.m12 + bonusSum.m12}
                  <span
                    css={{
                      fontFamily: 'SFUIText-Regular',
                      color: `${theme.colors.success} !important`,
                    }}
                  >
                    ₽
                  </span>
                </Text>
              </Column>
            </RadioButton>
            <RadioButton value="combo">
              <Column align="center" justify="center">
                <Text size="h3">Комбо</Text>
                <Text weight={600} css={{ color: `${theme.colors.primary} !important` }}>
                  Бонус
                </Text>
              </Column>

              <Top />
            </RadioButton>
          </RadioGroup>

          {period === 'combo' ? (
            <Combo
              onPurchase={handlePurchaseComboClick}
              tariffs={tariffs}
              onSsmmClick={onSsmmClick}
            />
          ) : (
            <Regular
              onPurchase={handlePurchaseRegularClick}
              tariffs={tariffs}
              balance={balance}
              period={period}
              from={from}
              setFrom={setFrom}
              autoContinue={autoContinue}
              setAutoContinue={setAutoContinue}
              onSsmmClick={onSsmmClick}
              bonusSum={bonusSum}
            />
          )}
        </Spin>
      </Modal>
    </>
  );
});

Package1plus2.propTypes = {
  onClose: func.isRequired,
  tariffs: object.isRequired,
  chargeUserOld: func.isRequired,
  balance: number.isRequired,
  onSsmmClick: func.isRequired,
};

export default Package1plus2;
