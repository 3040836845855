import styled from '@emotion/styled';
import React from 'react';
import { Global, useTheme } from '@emotion/react';
import { CSSObject } from '@emotion/styled/types';
import { Text } from '../text';
import { Select } from 'antd';
import { Column, Row } from '../layout';
import Icon from '../icon';
import { commonParser } from '../helpers';
import { CommonStyleProps, CommonStylePropsKeys } from '../types';
import { Theme } from '@ssmm/theme/dist/types';
import { SelectProps, OptionProps } from 'antd/es/select';

interface WrapStyleProps extends CommonStyleProps {
  theme?: Theme;
}

interface ElementProps {
  error: boolean;
}

interface ComponentProps extends CommonStyleProps, SelectProps {
  label?: string;
  errorText?: string;
  error?: boolean;
  containerClass?: string;
}

const wrapStyle: (props: WrapStyleProps) => CSSObject = ({ theme, ...rest }) => ({
  fontFamily: theme.fonts.mainFont,
  '.ant-select-dropdown-menu': {
    padding: 0,
  },
  '.ant-select-dropdown-menu-item': {
    fontFamily: theme.fonts.mainFont,
    fontSize: theme.fonts.m.fontSize,
    lineHeight: `${theme.dimensions.l}px`,
    minHeight: theme.input.sizes.m.height,
    color: theme.input.colors.noState.text,
    fontWeight: 400,
    backgroundColor: 'unset',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
  },
  '.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled': {
    color: theme.input.colors.disabled.text,
    cursor: 'default',
    'i,span': { color: theme.input.colors.disabled.text },
  },
  '.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled)': {
    backgroundColor: `${theme.colors.primary}0D`,
  },
  '.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-selected': {
    color: theme.colors.primary,
    'i,span': { color: theme.colors.primary },
  },
  ...commonParser({ theme, ...rest }),
});

const Wrap = styled(Column)(wrapStyle);

const Element = styled(Select)<ElementProps>(({ theme, error }) => ({
  fontSize: theme.fonts.m.fontSize,
  width: '100%',
  '.ant-select-selection__placeholder': { color: theme.input.colors.noState.placeholder },
  '.ant-select-selection': {
    fontSize: theme.fonts.m.fontSize,
    lineHeight: `${theme.input.sizes.m.height}px`,
    color: theme.input.colors.noState.text,
    border: `1px solid ${
      error ? theme.input.colors.error.border : theme.input.colors.noState.border
    }`,
    borderRadius: theme.misc.borderRadius,
    padding: `0 ${theme.dimensions.xxl}px 0  ${theme.dimensions.m}px`,
    height: theme.input.sizes.m.height,
    boxShadow: 'none',
  },
  '.ant-select-arrow': {
    color: theme.input.colors.noState.icon,
    marginTop: -theme.dimensions.xs,
  },
  '&.ant-select-open, &:hover': {
    color: theme.input.colors.hover.text,
    '.ant-select-selection': {
      borderColor: theme.input.colors.hover.border,
      background: theme.input.colors.hover.background,
    },
    '.ant-select-arrow': {
      color: theme.input.colors.hover.icon,
    },
    '.ant-select-selection__placeholder': { color: theme.input.colors.hover.placeholder },
  },
  '&.ant-select-disabled': {
    '.ant-select-selection': {
      borderColor: theme.input.colors.disabled.border,
      background: theme.input.colors.disabled.background,
      cursor: 'default',
      '.ant-select-selection-selected-value': {
        color: theme.input.colors.disabled.text,
      },
      '.ant-select-selection-selected-value > div > span': {
        color: theme.input.colors.disabled.text,
      },
    },
    '& .ant-select-arrow': {
      color: theme.input.colors.disabled.icon,
    },
    '.ant-select-selection__placeholder': { color: theme.input.colors.disabled.placeholder },
  },
  '.ant-select-selection__rendered': {
    margin: 0,
    lineHeight: `${theme.input.sizes.m.height - 2}px`,
  },
  '.ant-select-selection-selected-value': {
    height: theme.input.sizes.m.height - 2,
    '>div': { height: '100%' },
  },
}));

const StyledSelect: React.NamedExoticComponent<ComponentProps> & {
  Option?: React.ClassicComponentClass<OptionProps>;
} = React.memo<ComponentProps>(
  ({
    placeholder = 'Выберите вариант',
    label = '',
    errorText = '',
    error = false,
    children,
    notFoundContent = 'Варианты не найдены',
    width = '100%',
    containerClass,
    ...rest
  }) => {
    const theme = useTheme();

    const stylingProps = {};

    Object.keys(rest).forEach(key => {
      if (CommonStylePropsKeys.includes(key)) {
        stylingProps[key] = rest[key];
      }
    });

    return (
      <Wrap width={width} {...stylingProps}>
        {label && <Text css={theme.input.label.css}>{label}</Text>}

        <Element
          placeholder={placeholder}
          error={error}
          notFoundContent={notFoundContent}
          getPopupContainer={trigger =>
            containerClass
              ? document.querySelector(`.${containerClass}`)
              : (trigger.parentNode as HTMLElement)
          }
          suffixIcon={<Icon type="down" size="s" />}
          {...rest}
        >
          {children}
        </Element>

        {errorText && (
          <Row align="center" css={theme.input.errorText.css}>
            <Text color="danger" size="s">
              {errorText}
            </Text>
          </Row>
        )}

        {containerClass && (
          <Global
            styles={{
              [`.${containerClass}`]: wrapStyle({ theme, ...rest }),
            }}
          />
        )}
      </Wrap>
    );
  }
);

StyledSelect.Option = Select.Option;

export default StyledSelect;
