import colors from './colors';

const fonts = {
  mainFont: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  mainHeadingFont: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  linkColor: colors.primary,
  linkColorHover: colors.primary,
  h1: {
    fontSize: 26,
    lineHeight: 1.5,
    fontWeight: 600,
    fontFamily: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  },
  h2: {
    fontSize: 16,
    lineHeight: 1.5,
    color: colors.secondary,
    fontWeight: 600,
    fontFamily: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  },
  h3: {
    fontSize: 16,
    lineHeight: 1.5,
    color: colors.secondary,
    fontWeight: 600,
    fontFamily: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  },
  h4: {
    color: colors.secondaryDarker,
    fontFamily: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  },
  s: {
    fontSize: 10,
    lineHeight: 1.3,
    color: colors.secondary,
    fontFamily: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  },
  m: {
    fontSize: 12,
    lineHeight: 1.3,
    color: colors.secondary,
    fontFamily: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  },
  l: {
    fontSize: 16,
    lineHeight: 1.3,
    color: colors.secondary,
    fontFamily: 'Open Sans, Segoe UI, Tahoma, sans-serif',
  },
};

export default fonts;
