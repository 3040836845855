import { Icon, Info, Layout, Link, Row, Text } from '@ssmm/ui';
import { func, number } from 'prop-types';
import React from 'react';

const M6 = React.memo(({ discount, onSsmmClick }) => (
  <>
    <Row justify="space-between" align="center" css={{ minHeight: 32 }} marginBottom="s">
      <Layout align="center">
        <Icon type="lightningFilled" color="success" size={22} marginRight="m" />
        <Text size="l" marginRight="s">
          Тариф “Раритетный” в сервисе{' '}
          <Link size="l" onClick={onSsmmClick}>
            Super-SMM
          </Link>
        </Text>
        <Info content="Тариф “Раритетный” в сервисе Super-SMM" />
      </Layout>
      <Text size="l" color="success">
        На месяц
      </Text>
    </Row>

    <Row justify="space-between" align="center" css={{ minHeight: 32 }} marginBottom="s">
      <Layout align="center">
        <Icon type="groupFilled" color="success" size={22} marginRight="m" />
        <Text size="l" marginRight="s">
          Партнерский кабинет
        </Text>
        <Info content="Партнерский кабинет" />
      </Layout>
      <Text size="l" color="success">
        На 6 месяцев
      </Text>
    </Row>

    <Row justify="space-between" align="center" css={{ minHeight: 32 }} marginBottom="l">
      <Layout align="center">
        <Icon type="percent" color="success" size={22} marginRight="m" />
        <Text size="l" marginRight="s">
          Скидка
        </Text>
        <Info content="Скидка" />
      </Layout>
      <Text size="l" color="success">
        {discount} <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
      </Text>
    </Row>
  </>
));

M6.propTypes = { discount: number.isRequired, onSsmmClick: func.isRequired };

export default M6;
