import colors from './colors';

const fonts = {
  mainFont: 'inherit',
  mainHeadingFont: 'inherit',
  linkColor: colors.primary,
  linkColorHover: colors.primary,
  h1: {},
  h2: {},
  h3: {},
  h4: {},
  s: {},
  m: {},
  l: {},
};

export default fonts;
