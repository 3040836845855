import { connect } from 'react-redux';

import * as actions from '../actions';
import PdfModal from '../component';

const mapStateToProps = state => ({
  pdfModalTitle: state.pdfModal.pdfModalTitle,
  pdfModalUrl: state.pdfModal.pdfModalUrl,
  pdfModalOpen: state.pdfModal.pdfModalOpen,
});

export default connect(mapStateToProps, actions)(PdfModal);
