import './Success.css';

import React from 'react';
import { Link } from 'react-router-dom';

const Success = () => (
  <div className="vkboss-payment-cont">
    <p>Вы успешно оплатили тариф!</p>
    <div className="regSuccess-links">
      <span className="regSuccess-links-link">
        Если Вы ещё не установили расширение:
        <Link to="/extinstall" title="Инструкция по установке">
          инструкция по установке
        </Link>
      </span>
      <span className="regSuccess-links-link">
        Если у Вас есть вопросы:
        <a
          href="https://vk.com/vsemsmm1"
          title="Группа технической поддержки"
          target="_blank"
          rel="noopener noreferrer"
        >
          написать в тех. поддержку
        </a>
      </span>
    </div>
  </div>
);

export default Success;
