import styled from '@emotion/styled';
import React from 'react';
import { useTheme } from '@emotion/react';
import { Text } from '../text';
import { Layout, Row } from '../layout';
import { commonParser } from '../helpers';

const Element = styled('textarea')(({ theme, error, icon, ...rest }) => ({
  width: '100%',
  fontFamily: theme.fonts.mainFont,
  fontSize: theme.fonts.m.fontSize,
  color: error ? theme.input.colors.error.text : theme.input.colors.noState.text,
  border: `1px solid ${
    error ? theme.input.colors.error.border : theme.input.colors.noState.border
  }`,
  background: error ? theme.input.colors.error.background : theme.input.colors.noState.background,
  borderRadius: theme.misc.borderRadius,
  outline: 0,
  padding: `${theme.dimensions.s}px ${theme.dimensions.m}px`,
  boxSizing: 'border-box',
  ':active, :focus, :hover': {
    borderColor: theme.input.colors.hover.border,
    background: theme.input.colors.hover.background,
    transition: '.3s',
    '&~i': { color: theme.input.colors.hover.icon },
  },
  ':disabled': {
    borderColor: theme.input.colors.disabled.border,
    background: theme.input.colors.disabled.background,
    '&~i': { color: theme.input.colors.disabled.icon },
  },
  '::placeholder': {
    color: theme.input.colors.noState.placeholder,
  },
  ...commonParser({ theme, ...rest }),
}));

const TextArea = React.memo(
  ({ placeholder, label, errorText, error, disabled, width, ...rest }) => {
    const theme = useTheme();

    return (
      <Layout width={width} direction="column">
        {label && <Text css={theme.input.label.css}>{label}</Text>}

        <Row css={{ position: 'relative' }}>
          <Element
            placeholder={placeholder}
            error={error}
            disabled={disabled}
            spellCheck={false}
            {...rest}
          />
        </Row>

        {errorText && (
          <Row align="center" css={theme.input.errorText.css}>
            <Text color="danger" size="s">
              {errorText}
            </Text>
          </Row>
        )}
      </Layout>
    );
  }
);

TextArea.defaultProps = {
  placeholder: 'Введите текст',
  error: false,
  errorText: '',
  label: '',
  width: '100%',
};

export default TextArea;
