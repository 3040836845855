import React from 'react';
import styled from '@emotion/styled';
import Icon from '../../icon';
import { Layout } from '../../layout';

const FakeRadio = styled(Layout)({
  position: 'relative',
  width: 15,
  height: 15,
  borderWidth: 1,
  borderStyle: 'solid',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
});

const Checked = () => <Icon type="check" set="ssmm" size={9} css={{ position: 'absolute' }} />;

export default { FakeRadio, Checked };
