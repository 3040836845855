import styled from '@emotion/styled';
import { commonParser } from '../helpers';

const CodeWrap = styled('pre')(({ theme, ...rest }) => ({
  minHeight: theme.dimensions.xxl,
  padding: theme.dimensions.xl,
  background: theme.colors.secondaryDark,
  color: theme.colors.primaryLight,
  width: `100%`,
  whiteSpace: 'pre-wrap',
  wordBreak: 'break-all',
  position: 'relative',
  justifyContent: 'left',
  alignItems: 'center',
  ...commonParser({ theme, ...rest }),
}));

export default CodeWrap;
