export const SET_AUTH_LOADER = 'SET_AUTH_LOADER';
export const UPDATE_USER = 'UPDATE_USER';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const OPEN_PHONE_AUTH = 'OPEN_PHONE_AUTH';
export const CLOSE_PHONE_AUTH = 'CLOSE_PHONE_AUTH';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_PHONE_CODE = 'SET_PHONE_CODE';
export const SET_PHONE_AUTH_STAGE = 'SET_PHONE_AUTH_STAGE';
export const SET_PHONE_AUTH_ERROR = 'SET_PHONE_AUTH_ERROR';
export const SET_HANDLERS = 'SET_HANDLERS';
export const RESET_AUTHUI = 'RESET_AUTHUI';
export const OPEN_REG = 'OPEN_REG';
export const REG_UPDATE_SEARCH_STR = 'REG_UPDATE_SEARCH_STR';
export const REG_OWNER_INFO_REQ = 'REG_OWNER_INFO_REQ';
export const REG_OWNER_INFO_RES = 'REG_OWNER_INFO_RES';
export const REG_TOGGLE_ACCEPT_REF = 'REG_TOGGLE_ACCEPT_REF';
export const REG_TOGGLE_ACCEPT_PRIVACY = 'REG_TOGGLE_ACCEPT_PRIVACY';
export const DOCUMENT_URL_RES = 'DOCUMENT_URL_RES';
export const OPEN_PDF_MODAL = 'OPEN_PDF_MODAL';
export const CLOSE_PDF_MODAL = 'CLOSE_PDF_MODAL';
export const REG_OPEN_REF = 'REG_OPEN_REF';
export const REG_CLOSE_REF = 'REG_CLOSE_REF';
export const REG_TOGGLE_ACCEPT_NO_PARTNER = 'REG_TOGGLE_ACCEPT_NO_PARTNER';

export const SET_SCREEN_TYPE = 'SET_SCREEN_TYPE';
export const CLOSE_MOBILE_MENU = 'CLOSE_MOBILE_MENU';
export const OPEN_MOBILE_MENU = 'OPEN_MOBILE_MENU';

export const UPDATE_LOADERS = 'UPDATE_LOADERS';
export const APP_STATUS_RES = 'APP_STATUS_RES';
export const APP_SETTINGS_RES = 'APP_SETTINGS_RES';

export const LANDING_OPEN_VIDEO_GALLERY = 'LANDING_OPEN_VIDEO_GALLERY';
export const LANDING_CLOSE_VIDEO_GALLERY = 'LANDING_CLOSE_VIDEO_GALLERY';
export const LANDING_OPEN_VIDEO = 'LANDING_OPEN_VIDEO';
export const LANDING_CLOSE_VIDEO = 'LANDING_CLOSE_VIDEO';
export const LANDING_SET_MODULE_ID = 'LANDING_SET_MODULE_ID';
export const LANDING_SET_REVIEW = 'LANDING_SET_REVIEW';
export const LANDING_OPEN_TARIFFS = 'LANDING_OPEN_TARIFFS';
export const LANDING_CLOSE_TARIFFS = 'LANDING_CLOSE_TARIFFS';
export const LANDING_SET_CASES = 'LANDING_SET_CASES';
export const LANDING_SET_REVIEWS = 'LANDING_SET_REVIEWS';
