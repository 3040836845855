const getStyleProps = ({ theme, color }) => ({
  height: theme.dimensions.s,
  minWidth: theme.dimensions.xl + 5,
  border: 'none',
  backgroundColor: theme.switch.colors[color].noState.background,
  '&.ant-switch-checked': {
    backgroundColor: `${theme.switch.colors[color].noState.backgroundChecked}80`,
    '&:after': {
      marginLeft: `1px`,
      backgroundColor: theme.switch.colors[color].noState.backgroundChecked,
      borderColor: theme.switch.colors[color].noState.backgroundChecked,
    },
  },
  '&:after': {
    boxShadow: 'none',
    border: `1px solid`,
    borderColor: theme.switch.colors[color].noState.background,
    marginLeft: `-1px`,
    backgroundColor: theme.switch.colors[color].noState.dot,
    width: theme.dimensions.m + 1,
    height: theme.dimensions.m + 1,
    borderRadius: '100%',
    marginTop: 'calc(100% - 34px)',
    boxSizing: 'border-box',
  },
});

export default getStyleProps;
