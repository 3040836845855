import { combineReducers } from 'redux';

import authUi from '../../components/auth-ui/reducer';
import auth from '../../components/authorization/reducer';
import landing from '../../components/landing/reducer';
import paymentDetails from '../../components/PaymentDetails/reducer';
import pdfModal from '../../components/pdf-modal/reducer';
import purchase from '../../components/Purchase/reducer';
import reg from '../../components/registration/reducer';
import documents from './documents';
import loaders from './loaders';
import settings from './settings';
import user from './user';

const rootReducer = combineReducers({
  settings,
  auth,
  loaders,
  purchase,
  paymentDetails,
  documents,
  pdfModal,
  user,
  reg,
  authUi,
  landing,
});

export default rootReducer;
