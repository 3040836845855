import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import { Text } from '../text';
import Icon from '../icon';
import { Layout, Row } from '../layout';
import { commonParser } from '../helpers';
import { CSSObject } from '@emotion/styled/types';
import { BossIcon, SsmmIcon, StorysendIcon } from '../icon/types';
import { Size } from '@ssmm/theme/dist/types/common';
import { Theme } from '@ssmm/theme/dist/types';

const getIconTopPosition: (t: Theme) => number = theme => {
  const iconDefaultSize =
    typeof theme.misc.iconDefaultSize === 'string'
      ? theme.dimensions[theme.misc.iconDefaultSize]
      : theme.misc.iconDefaultSize;

  return theme.input.sizes.m.height / 2 - iconDefaultSize / 2;
};

interface Props {
  placeholder?: string;
  label?: string | React.Component;
  errorText?: string;
  error?: boolean;
  disabled?: boolean;
  width?: CSSObject['width'] | Size;
  icon?: BossIcon | SsmmIcon | StorysendIcon;
  onEnter?: (val: string, e: KeyboardEvent) => void;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ElementProps {
  error: boolean;
  icon: boolean;
}

const Element = styled('input')<ElementProps>(({ theme, error, icon, ...rest }) => ({
  width: '100%',
  fontFamily: theme.fonts.mainFont,
  fontSize: theme.fonts.m.fontSize,
  lineHeight: `${theme.input.sizes.m.height}px`,
  color: error ? theme.input.colors.error.text : theme.input.colors.noState.text,
  border: `1px solid ${
    error ? theme.input.colors.error.border : theme.input.colors.noState.border
  }`,
  background: error ? theme.input.colors.error.background : theme.input.colors.noState.background,
  borderRadius: theme.misc.borderRadius,
  outline: 0,
  height: theme.input.sizes.m.height,
  padding: icon
    ? `0 ${theme.dimensions.xxl}px 0 ${theme.dimensions.m}px`
    : `0 ${theme.dimensions.m}px`,
  boxSizing: 'border-box',
  ':active, :focus, :hover': {
    borderColor: theme.input.colors.hover.border,
    background: theme.input.colors.hover.background,
    transition: '.3s',
    '&~i': { color: theme.input.colors.hover.icon },
  },
  ':disabled': {
    borderColor: theme.input.colors.disabled.border,
    background: theme.input.colors.disabled.background,
    color: theme.input.colors.disabled.text,
    '&~i': { color: theme.input.colors.disabled.icon },
  },
  '::placeholder': {
    color: theme.input.colors.noState.placeholder,
  },
  ...commonParser({ theme, ...rest }),
}));

const Input = React.memo<Props>(
  ({
    placeholder = 'Введите текст',
    label = '',
    errorText,
    error = false,
    disabled = false,
    width = '100%',
    icon,
    onEnter,
    value = '',
    onChange,
    ...rest
  }) => {
    const inputRef = useRef<HTMLInputElement>();

    useEffect(() => {
      if (onEnter && inputRef.current) {
        const handler = (event: KeyboardEvent) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            onEnter(value, event);
          }
        };

        inputRef.current.addEventListener('keyup', handler);

        return () => {
          if (inputRef?.current) inputRef.current.removeEventListener('keyup', handler);
        };
      }

      return null;
    }, [onEnter, inputRef.current]);

    return (
      <Layout width={width} direction="column">
        {label && (
          <>
            {typeof label === 'string' ? (
              <Text css={theme => theme.input.label.css}>{label}</Text>
            ) : (
              label
            )}
          </>
        )}

        <Row css={{ position: 'relative' }}>
          <Element
            ref={inputRef}
            placeholder={placeholder}
            value={value}
            error={error}
            disabled={disabled}
            spellCheck={false}
            icon={!!icon}
            onChange={onChange}
            {...rest}
          />

          {icon && (
            <Icon
              type={icon}
              css={theme => ({
                position: 'absolute',
                top: getIconTopPosition(theme),
                right: theme.dimensions.m,
              })}
            />
          )}
        </Row>

        {errorText && (
          <Row align="center" css={theme => theme.input.errorText.css}>
            <Text color="danger" size="m">
              {errorText}
            </Text>
          </Row>
        )}
      </Layout>
    );
  }
);

export default Input;
