import React from 'react';
import styled from '@emotion/styled';
import { commonParser } from '../helpers';

const parseProps = ({ theme, ...styleProps }) => {
  const styleObj = {};

  Object.keys(styleProps).forEach(prop => {
    const value = styleProps[prop];

    switch (prop) {
      case 'color':
        styleObj.color = theme.colors[value] || value;
        break;

      case 'weight':
        styleObj.fontWeight = value;
        break;

      case 'align':
        styleObj.textAlign = value;
        break;

      case 'decoration':
        styleObj.textDecoration = value;
        break;

      default:
        break;
    }
  });

  return styleObj;
};

const getProps = ({ theme, size, disabled, ...rest }) => {
  let obj = { boxSizing: 'border-box', textDecoration: 'underline', cursor: 'pointer' };

  if (theme.fonts[size]) {
    obj = { ...obj, ...theme.fonts[size] };
  } else {
    obj = { ...obj, ...theme.fonts.m, fontSize: size || theme.fonts.m.size };
  }

  if (disabled) {
    obj.color = theme.colors.secondary;
    obj.pointerEvents = 'none';
  } else {
    obj.color = theme.fonts.linkColor;
    obj[':hover'] = { color: theme.fonts.linkColorHover };
    obj.transition = '0.3s';
  }

  return { ...obj, ...commonParser({ theme, ...rest }), ...parseProps({ theme, ...rest }) };
};

const StyledSpan = styled('span')(getProps);

const StyledA = styled('a')(getProps);

const Link = React.memo(({ href, target, rel, disabled, ...rest }) => {
  return href ? (
    <StyledA href={href} target={target} rel={rel} disabled={disabled} {...rest} />
  ) : (
    <StyledSpan disabled={disabled} {...rest} />
  );
});

Link.defaultProps = {
  size: 'm',
  target: '_blank',
  rel: 'noreferrer nofollow',
};

export default Link;
