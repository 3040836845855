import commonColors from '../../common/colors';

const colors = {
  danger: '#DC5858',
  success: '#63C67A',
  warning: '#F7B500',
  dangerLight: '#FDF6F6',
  successLight: '#F7FCF8',
  warningLight: '#FEFBF2',
  dangerDark: '#721C24',
  successDark: '#155724',
  warningDark: '#856404',

  primary: '#9707E3',
  primaryLighter: '#DFB4F6',
  primaryLight: '#ffffff',
  secondaryDark: '#000000',
  secondaryDarker: '#262626',
  secondary: '#9EB1C3',
  secondaryLighter: '#DAE2EA',
  secondaryLight: '#F5F6F8',
  muted: '#DAE2EA',
  accent: '#F6F9FD',

  ...commonColors,
};

export default colors;
