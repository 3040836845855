import React from 'react';
import { Text } from '../text';
import styled from '@emotion/styled';
import { Column, Layout, Row } from '../layout';

const MenuSubItemElement = styled(Row)(({ theme, active, disabled }) => {
  const styleObj = {
    minHeight: 30,
    alignItems: 'center',
    padding: `${theme.dimensions.xs}px 0`,
    cursor: 'pointer',
    '.menuDot': { background: theme.colors.secondaryDarker },
    '&: hover': {
      'span,i': { color: theme.colors.primary },
      '.menuDot': { background: theme.colors.primary },
    },
  };

  if (active) {
    styleObj.pointerEvents = 'none';
    styleObj['.menuDot'] = { background: theme.colors.primary };
    styleObj['span,i'] = { color: theme.colors.primary };
  }

  if (disabled) {
    styleObj.pointerEvents = 'none';
    styleObj['span,i'] = { color: theme.colors.secondary };
    styleObj['.menuDot'] = { background: theme.colors.secondary };
  }

  return styleObj;
});

const MenuSubItems = React.memo(({ items, current, onChange }) => (
  <Column marginBottom="s" paddingLeft="xl">
    {items.map(item => {
      const { id, title, disabled } = item;

      const active = id === current;
      const content =
        typeof title === 'string' ? (
          <Text size="l" weight={400}>
            {title}
          </Text>
        ) : (
          title
        );

      return (
        <MenuSubItemElement
          active={active}
          disabled={disabled}
          key={id}
          onClick={() => {
            onChange(id);
          }}
        >
          <Layout
            className="menuDot"
            marginLeft="s"
            marginRight="s"
            css={{ width: 5, height: 5, borderRadius: '50%' }}
          />
          {content}
        </MenuSubItemElement>
      );
    })}
  </Column>
));

export default MenuSubItems;
