import styled from '@emotion/styled';

const CloseButton = styled.div(({ theme, size }) => ({
  position: 'relative',
  marginLeft: `${theme.dimensions[size]}px`,
  width: `${theme.dimensions[size]}px`,
  height: `${theme.dimensions[size]}px`,
  '&:after': {
    position: 'absolute',
    cursor: 'pointer',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: `${theme.dimensions[size]}px`,
    content: '"\\2716"',
    color: theme.colors.primaryLight,
    transition: `0.2s`,
  },
  '&:hover': {
    '&:after': {
      opacity: 0.7,
    },
  },
}));

CloseButton.defaultProps = {
  size: 'm',
};

export default CloseButton;
