const effects = {
  hover: {
    cursor: 'pointer',
    opacity: 0.85,
    transition: '0.3s',
    ':hover': { opacity: 1 },
  },
  truncate: {
    display: 'table',
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
    '>*': {
      display: 'table-cell',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  truncateInline: {
    display: 'inline-table',
    tableLayout: 'fixed',
    width: '100%',
    whiteSpace: 'nowrap',
    '>*': {
      display: 'table-cell',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};

export default effects;
