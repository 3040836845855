import React, { useEffect, useRef } from 'react';
import { Text } from '../text';
import { CommonStyleProps } from '../types';
import { Layout } from '../layout';

interface ComponentProps extends React.HTMLAttributes<HTMLDivElement>, CommonStyleProps {
  video?: string;
  autoplay?: boolean;
  title?: string;
  controls?: boolean;
}

const handleContent: (
  content: string,
  autoplay: boolean,
  title: string,
  controls: boolean
) => string | null = (content, autoplay, title, controls) => {
  // Кастомный контент со своими параметрами
  if (content.match(/iframe/)) {
    return content;
  }

  // Кастомное изображение со своими параметрами
  if (content.match(/<img/)) {
    return content;
  }
  if (content.match(/(.jpg|.png)/)) {
    return `<img src=${content} alt="" />`;
  }

  // Youtube ID
  if (content.match(/youtu/)) {
    const id = content.slice(-11);

    return handleContent(id, autoplay, title, controls);
  }
  if (content.length === 11) {
    const iframeEl = document.createElement('iframe');

    iframeEl.setAttribute('title', title || 'Video');
    iframeEl.setAttribute('frameborder', '0');
    iframeEl.setAttribute('allowfullscreen', '1');

    let src = `https://www.youtube.com/embed/${content}`;

    if (autoplay) {
      src += `?autoplay=1`;
      iframeEl.setAttribute('allow', 'autoplay');
    }

    iframeEl.setAttribute('src', src);

    return iframeEl.outerHTML;
  }

  // Video
  const videoEl = document.createElement('video');
  const sourceEl = document.createElement('source');

  videoEl.setAttribute('title', title || 'Video');
  sourceEl.setAttribute('src', content);
  sourceEl.setAttribute('type', 'video/mp4');

  if (controls) videoEl.setAttribute('controls', '1');

  videoEl.append(sourceEl);

  return videoEl.outerHTML;
};

const Video = React.memo<ComponentProps>(({ video, autoplay, title, controls = true, ...rest }) => {
  const content = video && handleContent(video, autoplay, title, controls);
  const containerRef: any = useRef();

  useEffect(() => {
    if (!autoplay || !containerRef?.current || !content?.includes('<video')) return null;

    const videoEl = containerRef.current.querySelector('video');

    const play = () => videoEl.play();

    videoEl.addEventListener('loadeddata', play);

    return () => {
      videoEl.removeEventListener('loadeddata', play);
    };
  }, [containerRef, content]);

  return (
    <Layout
      css={theme => ({
        position: 'relative',
        paddingBottom: '56.25%',
        width: '100%',
        overflow: 'hidden',
        height: 0,
        backgroundColor: '#5D6F88',
        borderRadius: theme.misc.borderRadius,
      })}
      {...rest}
    >
      {content ? (
        <Layout
          ref={containerRef}
          css={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            iframe: { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' },
            img: { width: '100%' },
            video: { width: '100%', height: '100%' },
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      ) : (
        <Text
          css={{
            position: 'absolute',
            top: '50%',
            bottom: '50%',
            width: '100%',
            textAlign: 'center',
          }}
          color="primaryLight"
        >
          Видео скоро появится
        </Text>
      )}
    </Layout>
  );
});

export default Video;
