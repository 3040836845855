import { Button, Icon, Layout, Row, Text } from '@ssmm/ui';
import moment from 'moment';
import { func, number } from 'prop-types';
import React from 'react';

import { Block } from './ai';

const AutoLike = React.memo(({ price, active, onPurchaseClick }) => (
  <Block background={active ? 'primaryLight' : undefined}>
    <Row marginBottom="s" justify="space-between">
      <Layout direction="column" marginRight="s" marginBottom="xs">
        <Row marginBottom="s" align="center" shrink={1}>
          <Icon type="likeFilled" color="primary" size={22} marginRight="m" />
          <Text size="l">Привлечение внимания</Text>
        </Row>
        <Row marginBottom="s" align="center" shrink={1}>
          <Icon type="userAddedFilled" color="primary" size={22} marginRight="m" />
          <Text size="l">Авто подписка</Text>
        </Row>
        <Row align="center">
          <Icon type="likeCommentFilled" color="primary" size={22} marginRight="m" />
          <Text size="l">Сбор ЦА</Text>
        </Row>
      </Layout>

      <Layout align="flex-end">
        <Text size="h1" color="primary" line={24}>
          {price}
          <Text size="h1" color="primary" line={24} font="SFUIText-Regular">
            <span css={{ fontFamily: 'SFUIText-Regular' }}>₽</span>
          </Text>
        </Text>
        <Text color="primary">&nbsp;/&nbsp;месяц</Text>
      </Layout>
    </Row>
    <Row align="center" justify="space-between">
      <Text size="h2" css={{ textTransform: 'uppercase' }} color="secondary">
        Пакет №2
      </Text>

      {active ? (
        <Layout justify="flex-end" align="center" shrink={1}>
          <Text weight={400} color="success" marginRight="m" size={14} font="Open Sans">
            {`до ${moment(active).format('DD.MM.YYYY')}`}
          </Text>
          <Button onClick={onPurchaseClick} type="success">
            Продлить
          </Button>
        </Layout>
      ) : (
        <Button onClick={onPurchaseClick} type="dark">
          Купить
        </Button>
      )}
    </Row>
  </Block>
));

AutoLike.propTypes = {
  onPurchaseClick: func.isRequired,
  price: number.isRequired,
  active: number.isRequired,
};

export default AutoLike;
