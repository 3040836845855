import { Layout, Row, Text } from '@ssmm/ui';
import React from 'react';

const YellowTitle = ({ children, ...rest }) => (
  <Row justify="center" css={{ position: 'relative' }} {...rest}>
    <Layout paddingLeft="m" paddingRight="m" background="primaryLight" css={{ zIndex: 123 }}>
      <Text size="h2" color="warning" line={28} css={{ textTransform: 'uppercase' }}>
        {children}
      </Text>
    </Layout>
    <Row height={1} background="secondaryLighter" css={{ position: 'absolute', top: '50%' }} />
  </Row>
);

export default YellowTitle;
