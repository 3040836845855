import React, { useState } from 'react';
import { Text } from '../text';
import Popover from '../popover';
import { Column, Row } from '../layout';
import Button, { ComponentProps as ButtonProps } from '../button';
import Icon from '../icon';
import { PopoverProps } from 'antd/es/popover';
import { CommonStyleProps } from '../types';

interface ComponentProps extends PopoverProps, Omit<CommonStyleProps, 'align'> {
  content: string | React.ReactNode;
  okButtonText?: string | React.ReactNode;
  cancelButtonText?: string | React.ReactNode;
  onOk: () => void;
  onCancel?: () => void;
  okButtonProps?: Partial<ButtonProps>;
  cancelButtonProps?: Partial<ButtonProps>;
}

const Popconfirm = React.memo<ComponentProps>(
  ({
    content: contentProp,
    okButtonText = 'Да',
    cancelButtonText = 'Нет',
    onOk,
    onCancel = () => {},
    okButtonProps,
    cancelButtonProps,
    width = 275,
    ...rest
  }) => {
    const [visible, setVisible] = useState(false);

    const content = (
      <Column
        padding="m"
        css={{ minWidth: width }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <Row marginBottom="s">
          <Icon type="infoCircle" set="ssmm" marginTop={2} marginRight="s" />
          {typeof contentProp === 'string' ? <Text size={14}>{contentProp}</Text> : contentProp}
        </Row>

        <Row>
          <Button
            marginRight="s"
            type="light"
            onClick={e => {
              e.stopPropagation();
              onCancel();
              setVisible(false);
            }}
            grow={1}
            shrink={1}
            basis="50%"
            {...cancelButtonProps}
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={e => {
              e.stopPropagation();
              onOk();
              setVisible(false);
            }}
            grow={1}
            shrink={1}
            basis="50%"
            {...okButtonProps}
          >
            {okButtonText}
          </Button>
        </Row>
      </Column>
    );

    return (
      <Popover
        content={content}
        trigger="click"
        width={5555}
        visible={visible}
        onVisibleChange={setVisible}
        {...rest}
      />
    );
  }
);

export default Popconfirm;
